import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { DsButtonGroup, DsButton } from '../../../Components';
export function DefaultActionBar(props) {
    const { onAccept, onClear, ownerState, actions } = props;
    const { value } = ownerState || {};
    if (actions == null || actions?.length === 0) {
        return null;
    }
    const translations = useLocaleText();
    const isClearVisible = actions.includes('clear');
    const isConfirmVisible = actions.includes('accept');
    return (_jsxs(DsButtonGroup, { sx: {
            gridArea: '3 / 1 / auto / 4',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            px: 'var(--ds-spacing-mild)',
            borderTop: '1px solid var(--ds-colour-strokeDefault)',
            borderRadius: '0px 0px 16px 16px '
        }, justifyContent: "space-between", size: "medium", children: [isClearVisible && (_jsx(DsButton, { sx: {
                    py: 'var(--ds-spacing-glacial)',
                    px: 'var(--ds-spacing-pleasant)'
                }, variant: "text", size: "medium", color: "secondary", onClick: onClear, disabled: !value, children: translations.clearButtonLabel })), isConfirmVisible && (_jsx(DsButton, { sx: {
                    py: 'var(--ds-spacing-glacial)',
                    px: 'var(--ds-spacing-pleasant)'
                }, variant: "text", size: "medium", color: "secondary", onClick: onAccept, disabled: !value, children: translations.okButtonLabel }))] }));
}
DefaultActionBar.displayName = 'DefaultActionBar';
