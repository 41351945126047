import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const getMultiplePortfolioServiceName = 'portfolio/getMultiplePortfolio'
export const getMultiplePortfolioActions = traceActionsCreator(
  getMultiplePortfolioServiceName
)

export const getMutualFundPortfolioServiceName =
  'portfolio/getMutualFundPortfolio'
export const getMutualFundPortfolioActions = traceActionsCreator(
  getMutualFundPortfolioServiceName
)

export const getMutualFundSchemesHoldingServiceName =
  'portfolio/getMutualFundSchemesHolding'
export const getMutualFundSchemesHoldingActions = traceActionsCreator(
  getMutualFundSchemesHoldingServiceName
)

export const getDematListServiceName = 'portfolio/getDematList'
export const getDematListActions = traceActionsCreator(getDematListServiceName)

export const updateFormattedDematListAction = createAction<any, string>(
  'portfolio/updateFormattedDematListAction'
)
export const updateSelectedDematScriptAction = createAction<any, string>(
  'portfolio/updateSelectedDematScriptAction'
)
export const updateDematListFilterAction = createAction<any, string>(
  'portfolio/updateDematListFilterAction'
)

export const updateFormattedSecurityLimitListAction = createAction<any, string>(
  'portfolio/updateFormattedSecurityLimitListAction'
)
export const updateSelectedSecurityLimitScriptAction = createAction<
  any,
  string
>('portfolio/updateSelectedSecurityLimitScriptAction')

export const updateHoldingsServiceName = 'portfolio/updateHoldings'
export const updateHoldingsActions = traceActionsCreator(
  updateHoldingsServiceName
)

export const updateReleasesServiceName = 'portfolio/updateReleases'
export const updateReleasesActions = traceActionsCreator(
  updateReleasesServiceName
)
export const getSecurityLimitName = 'portfolio/getSecurityLimit'
export const getSecurityLimitActions = traceActionsCreator(getSecurityLimitName)
