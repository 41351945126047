// DONE - CLEANUP: @vasi
// 1. define typesimport React, { Component } from 'react'
import {
  DsBox,
  DsButton,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import SipDiscardConfirmation from './SipDiscardConfirmation'

import { updateOrderSipPageModalIndicator } from '~/src/Redux/Indicators/Reducer'
import { getOrderPageSipModalIndicator } from '~/src/Redux/Indicators/Selectors'

import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { clearSipInfo } from '~/src/Redux/Orders/reducer'
import { getSipInfo } from '~/src/Redux/Orders/selectors'
import { SIPClevertap } from '~/src/Pages/Orderbook/SIP.ClevertapEvents'
import React, { Component } from 'react'

type ActionTypes = {
  updateSipOpenState: (data: boolean) => void
  updateSipInfo: () => void
}

export interface ISipModalProps {
  actions: ActionTypes
  orderPageSipModalIndicator: boolean
  type?: string
  handleModifySip?: () => Promise<any>
  sipInfo: any
}

interface ISipModalState {
  open: boolean
}

export class SipHeader extends Component<ISipModalProps, ISipModalState> {
  state: ISipModalState = {
    open: false
  }

  setOpen = (e: boolean) => {
    new SIPClevertap().onClickBackBtn('No')
    this.setState({ open: e })
  }

  handleDiscard = () => {
    const { actions } = this.props
    new SIPClevertap().onClickBackBtn('Yes, Discard')
    this.setState({ open: false })
    actions.updateSipInfo()
    actions.updateSipOpenState(false)
  }

  handleCloseSipForm = () => {
    this.setOpen(true)
  }

  handleClick = () => {
    const { type = '', handleModifySip, sipInfo } = this.props
    if (type === 'Add Stock') {
      new SIPClevertap().onClickAddStock('Back', { SIP_Name: sipInfo })
      this.setOpen(true)
    } else if (type === 'Review') {
      handleModifySip?.()
    } else {
      this.handleCloseSipForm()
      new SIPClevertap().getStartSIPInfo(sipInfo, 'Back')
    }
  }

  render() {
    const { type = '', handleModifySip } = this.props
    const { open } = this.state

    const headerHeading = type || 'SIP Order'
    const formattedIcon = type ? 'ri-arrow-left-line' : 'ri-close-fill'

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding:
              'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold) var(--ds-spacing-mild) var(--ds-spacing-bitterCold)',
            width: '100%'
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              gap: 'var(--ds-spacing-frostbite)',
              alignItems: 'center'
            }}
          >
            <DsRemixIcon
              onClick={this.handleClick}
              className={formattedIcon}
              fontSize='mild'
              sx={{
                cursor: 'pointer'
              }}
            />
            <DsTypography variant='headingBoldSmall'>
              {headerHeading}
            </DsTypography>
          </DsBox>
          {type === 'Review' && (
            <DsButton
              onClick={handleModifySip}
              startIcon={
                <DsRemixIcon
                  className='ri-pencil-line'
                  color='secondary'
                  fontSize='small'
                />
              }
              variant='text'
              color='secondary'
            >
              Modify
            </DsButton>
          )}
        </DsStack>
        <SipDiscardConfirmation
          open={open}
          setOpen={this.setOpen}
          handleDiscard={this.handleDiscard}
        />
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const sipInfo = getSipInfo(state)
  const orderPageSipModalIndicator = getOrderPageSipModalIndicator(state)
  return {
    sipInfo,
    orderPageSipModalIndicator
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateSipOpenState: (requestData: boolean) =>
      dispatch(updateOrderSipPageModalIndicator(requestData)),
    updateSipInfo: () => dispatch(clearSipInfo())
  }
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(SipHeader)
