import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsButton } from '@am92/react-design-system'
import ScriptSurveillance from './ScriptSurveillance'
import {
  modifyOrderServiceName,
  placeOrderServiceName
} from '~/src/Redux/Orders/actions'
import { isServiceLoading } from '~/src/Redux/ServiceTracker/Selectors'
import DPRPopUp from './DPRPopUp'
import FundsAndMarginDetails from './FundsAndMarginDetails'
import { FormValues } from './OrderFormSection'
import { isModifyOrderRequest } from '~/src/Redux/Orders/selectors'

type ActionTypes = {}

export interface IOrderFormBottomSectionProps extends IWithRouterProps {
  orderBuyOrSell: string
  isSurveillanceAlertNeeded: string | null
  handlePlaceOrModifyOrder: (values: FormValues) => void
  handleModifyOrder: (values: FormValues) => void
  isPlaceOrderAPILoading: boolean
  priceBoxFocused: boolean
  scriptId: string
  stockDetails: any
  values: FormValues
  isModifyOrder: boolean
  isModifyOrderAPILoading: boolean
  openSurveillanceAlert: boolean
  handleSurveillanceAlert: () => void
  handleCloseSurveillanceAlert: () => void
  segment: string
  isBmpl: any
}

class OrderFormBottomSection extends Component<IOrderFormBottomSectionProps> {
  componentDidUpdate(prevState: any): void {}

  render() {
    const {
      orderBuyOrSell,
      isSurveillanceAlertNeeded,
      handlePlaceOrModifyOrder,
      isPlaceOrderAPILoading,
      priceBoxFocused,
      scriptId,
      values,
      isModifyOrder,
      isModifyOrderAPILoading,
      openSurveillanceAlert,
      handleSurveillanceAlert,
      handleCloseSurveillanceAlert,
      segment,
      stockDetails,
      isBmpl
    } = this.props
    const isBUY = orderBuyOrSell === 'BUY'

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'column',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            boxShadow: '1px 0px 24px 0px rgba(0, 0, 0, 0.08)',
            zIndex: 2
          }}
        >
          {/* DPR Section */}
          {(values.sectionType === 0 && (
            <DPRPopUp priceBoxFocused={priceBoxFocused} scriptId={scriptId} />
          )) ||
            null}

          <DsStack
            sx={{
              flexDirection: 'column',
              p: 'var(--ds-spacing-frostbite) var(--ds-spacing-mild)',
              backgroundColor: 'var(--ds-colour-surfaceBackground)',
              boxShadow: '1px 0px 24px 0px rgba(0, 0, 0, 0.08)'
            }}
          >
            <FundsAndMarginDetails
              orderBuyOrSell={orderBuyOrSell}
              scriptId={scriptId}
              segment={segment}
              values={values}
              stockDetails={stockDetails}
              isBmpl={isBmpl}
            />
            <DsButton
              color={isBUY ? 'success' : 'error'}
              sx={{
                m: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero)'
              }}
              onClick={
                isSurveillanceAlertNeeded
                  ? () => handleSurveillanceAlert()
                  : () => handlePlaceOrModifyOrder(values)
              }
              disabled={
                isModifyOrder ? isModifyOrderAPILoading : isPlaceOrderAPILoading
              }
            >
              {isModifyOrder ? 'Modify Order' : isBUY ? 'Buy' : 'Sell'}
            </DsButton>
          </DsStack>
        </DsStack>
        {openSurveillanceAlert && (
          <ScriptSurveillance
            primaryButtonOnClick={() => handlePlaceOrModifyOrder(values)}
            secondaryButtonOnClick={handleCloseSurveillanceAlert}
            openSurveillanceAlert={openSurveillanceAlert}
            surveillanceFlagName={isSurveillanceAlertNeeded}
            stockDetails={stockDetails}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isPlaceOrderAPILoading = isServiceLoading(state, [
    placeOrderServiceName
  ])
  const isModifyOrder = isModifyOrderRequest(state)
  const isModifyOrderAPILoading = isServiceLoading(state, [
    modifyOrderServiceName
  ])
  return {
    isPlaceOrderAPILoading,
    isModifyOrder,
    isModifyOrderAPILoading
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(OrderFormBottomSection)
