import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsStack,
  DsTypography,
  DsSkeleton,
  DsRemixIcon
} from '@am92/react-design-system'
import {
  getOrderScriptId,
  isModifyOrderRequest,
  isRepeatOrderRequest
} from '~/src/Redux/Orders/selectors'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import {
  checkIfHoldingsAvailable,
  getEntireHoldingObjectData
} from '~/src/Redux/Dashboard/Selectors'
import {
  resetOrderData,
  setCommonIsinCodeScriptArray,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'
import getHoldingsSummaryAction, {
  getHoldingsSummaryPayload
} from '~/src/Redux/Dashboard/Services/getHoldingsSummary.Service'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Pages/Orderbook/Orderbook.Page'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

type ActionTypes = {
  getHoldingsSummary: (requestData: getHoldingsSummaryPayload) => any
  setCommonIsinCodeScriptArray: (requestData: string[]) => Promise<any>
  resetOrderData: (requestData: string) => Promise<any>
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
}

export interface IOrderHeaderMiddleProps extends IWithRouterProps {
  actions: ActionTypes
  orderScriptId: string
  stockDetails: T_SCRIPTDATA
  handleError: (res: any) => void
  holdingObject: {
    [scriptId: string]: { holdings: string; avgPrice: string }
  }
  handleShowHoldings: () => void
  isModifyOrder: boolean
  isRepeatOrder: boolean
  areHoldingsAvailable: boolean
  subscribeLtpData: (scriptIdArray: any[]) => void
  unSubscribeLtpData: (scriptIdArray: any[]) => void
}

class OrderHeaderMiddle extends Component<IOrderHeaderMiddleProps> {
  state = {
    apiError: false,
    holdingQty: ''
  }

  componentDidMount(): void {
    this.setCommonIsinCode()
  }

  async componentDidUpdate(
    prevProps: Readonly<IOrderHeaderMiddleProps>
  ): Promise<void> {
    const {
      orderScriptId,
      stockDetails,
      actions,
      holdingObject,
      isModifyOrder,
      isRepeatOrder,
      areHoldingsAvailable,
      subscribeLtpData,
      unSubscribeLtpData
    } = this.props
    const {
      orderScriptId: PREV_ORDERSCRIPTID,
      stockDetails: PREV_STOCKDETAILS,
      holdingObject: PREV_HOLDINGS_OBJECT
    } = prevProps

    if (PREV_ORDERSCRIPTID !== orderScriptId) {
      this.setState({ holdingQty: null })
      PREV_ORDERSCRIPTID &&
        unSubscribeLtpData([{ scriptId: PREV_ORDERSCRIPTID }])
      orderScriptId && subscribeLtpData([{ scriptId: orderScriptId }])
      this.setState({
        apiError: false
      })
      if (!isModifyOrder && !isRepeatOrder) {
        actions.resetOrderData('resetOrderData')
      }
      //NOTE - when scripts in holding not available make api call
      if (!areHoldingsAvailable) this.getHoldingsSummaryDetails()
    }

    if (orderScriptId && PREV_STOCKDETAILS !== stockDetails) {
      this.setCommonIsinCode()
    }

    if (orderScriptId && PREV_HOLDINGS_OBJECT !== holdingObject) {
      if (holdingObject[orderScriptId]) {
        const { holdings } = holdingObject[orderScriptId]
        this.setState({ holdingQty: holdings })
      } else this.setState({ holdingQty: null })
    }
  }

  setCommonIsinCode = () => {
    const { orderScriptId, stockDetails, actions, holdingObject } = this.props
    const { isinCode } = stockDetails || {}
    const commonIsinCodeScriptIdArray = (window as any).__ISIN_CODE_INDEX__[
      isinCode
    ]

    actions.setCommonIsinCodeScriptArray(
      commonIsinCodeScriptIdArray?.length
        ? commonIsinCodeScriptIdArray
        : [orderScriptId]
    )

    if (Object.values(holdingObject).length) {
      // find holding qty based on isincode
      if (holdingObject[orderScriptId]) {
        const { holdings } = holdingObject[orderScriptId]
        this.setState({ holdingQty: holdings })
      }
      return
    }
  }
  getHoldingsSummaryDetails = async () => {
    const { actions } = this.props
    const requestData: getHoldingsSummaryPayload = {
      segment: 'EQ'
    }
    const getHoldingsSummaryResponse =
      await actions.getHoldingsSummary(requestData)
    if (getHoldingsSummaryResponse._isCustomError) {
      console.log('failed API - getHoldingsSummary')
      return
    }
  }

  renderHoldingBox = () => {
    const { handleShowHoldings } = this.props
    const { apiError, holdingQty } = this.state

    if (!apiError && holdingQty) {
      return (
        <>
          <DsStack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'var(--ds-colour-neutral1)',
              p: 'var(--ds-spacing-quickFreeze)',
              border: '1px solid var(--ds-colour-strokeDefault)',
              gap: 'var(--ds-spacing-quickFreeze)',
              borderRadius: 'var(--ds-radius-quickFreeze)',
              cursor: 'pointer'
            }}
            onClick={handleShowHoldings}
          >
            <DsRemixIcon className='ri-suitcase-line' fontSize='mild' />
            <DsTypography variant='bodyRegularSmall'>{holdingQty}</DsTypography>
          </DsStack>
        </>
      )
    }

    return null
  }

  render() {
    const { stockDetails = {} } = this.props
    const { label = '' } = (stockDetails as T_SCRIPTDATA) || {}

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <DsStack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <DsTypography
              variant='bodyRegularLarge'
              sx={{ mr: 'var(--ds-spacing-quickFreeze)' }}
            >
              {!label ? (
                <DsSkeleton
                  variant='text'
                  width={60}
                  sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
                />
              ) : (
                label
              )}
            </DsTypography>
          </DsStack>
          {this.renderHoldingBox()}
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderScriptId = getOrderScriptId(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)
  const areHoldingsAvailable = checkIfHoldingsAvailable(state)
  const holdingObject = getEntireHoldingObjectData(state)
  const isModifyOrder = isModifyOrderRequest(state)
  const isRepeatOrder = isRepeatOrderRequest(state)
  return {
    orderScriptId,
    stockDetails,
    holdingObject,
    isModifyOrder,
    isRepeatOrder,
    areHoldingsAvailable
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getHoldingsSummary: (requestData: getHoldingsSummaryPayload) =>
      dispatch(getHoldingsSummaryAction(requestData)),
    setCommonIsinCodeScriptArray: (requestData: string[]) =>
      dispatch(setCommonIsinCodeScriptArray(requestData)),
    resetOrderData: (requestData: any) => dispatch(resetOrderData(requestData)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(OrderHeaderMiddle)
)
