import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack } from '@am92/react-design-system'
import OrderHeaderTop from './OrderHeaderTop'
import OrderHeaderBottom from './OrderHeaderBottom'
import OrderHeaderMiddle from './OrderHeaderMiddle'
import HoldingsPopUp from './HoldingsPopUp'
import {
  getOrderBuyOrSell,
  getOrderScriptId
} from '~/src/Redux/Orders/selectors'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import { checkIfHoldingsAvailable } from '~/src/Redux/Dashboard/Selectors'
import getHoldingsSummaryAction, {
  getHoldingsSummaryPayload
} from '~/src/Redux/Dashboard/Services/getHoldingsSummary.Service'

type ActionTypes = {
  getHoldingsSummary: (requestData: getHoldingsSummaryPayload) => Promise<any>
}

export interface IOrderHeaderProps extends IWithRouterProps {
  actions: ActionTypes
  orderScriptId: string
  orderBuyOrSell: string
  areHoldingsAvailable: boolean
}

class OrderHeader extends Component<IOrderHeaderProps> {
  state = {
    openHoldings: false
  }
  async componentDidMount(): Promise<void> {
    const { areHoldingsAvailable } = this.props
    if (!areHoldingsAvailable) this.getHoldingsSummaryDetails()
  }

  async componentDidUpdate(
    prevProps: Readonly<IOrderHeaderProps>
  ): Promise<void> {
    const { orderScriptId, areHoldingsAvailable } = this.props
    const { orderScriptId: PREV_ORDERSCRIPTID = '' } = prevProps
    if (orderScriptId && PREV_ORDERSCRIPTID !== orderScriptId) {
      this.setState({ openHoldings: false })
      if (!areHoldingsAvailable) this.getHoldingsSummaryDetails()
    }
  }

  handleShowHoldings = async () => {
    const { openHoldings } = this.state
    const { orderBuyOrSell } = this.props
    const customAttributes = {
      Event_Status: 'Pass',
      Screen_Name: 'Transaction_Holdings',
      Action: orderBuyOrSell
    }
    triggerCleverTapEvent('Order', customAttributes)
    this.setState({ openHoldings: !openHoldings })
  }

  getHoldingsSummaryDetails = async () => {
    const { actions } = this.props
    const requestData: getHoldingsSummaryPayload = {
      segment: 'EQ'
    }
    const getHoldingsSummaryResponse =
      await actions.getHoldingsSummary(requestData)
    if (getHoldingsSummaryResponse._isCustomError) {
      console.log('failed API - getHoldingsSummary')
      return
    }
  }

  render() {
    const { openHoldings } = this.state
    const { orderScriptId } = this.props
    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            zIndex: 2,
            padding: {
              xs: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite) var(--ds-spacing-mild)'
            }
          }}
          spacing={4}
        >
          <OrderHeaderTop />
          <OrderHeaderMiddle
            handleShowHoldings={this.handleShowHoldings}
            orderScriptId={orderScriptId}
          />
          <OrderHeaderBottom />
        </DsStack>
        <HoldingsPopUp
          openHoldings={openHoldings}
          handleShowHoldings={this.handleShowHoldings}
          scriptId={orderScriptId}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderScriptId = getOrderScriptId(state)
  const orderBuyOrSell = getOrderBuyOrSell(state)
  const areHoldingsAvailable = checkIfHoldingsAvailable(state)
  return {
    orderScriptId,
    orderBuyOrSell,
    areHoldingsAvailable
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getHoldingsSummary: (requestData: getHoldingsSummaryPayload) =>
      dispatch(getHoldingsSummaryAction(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHeader)
