import React, { Component } from 'react'
import { DsBackdrop, DsCircularProgress } from '@am92/react-design-system'

export default class Loader extends Component {
  render() {
    return (
      <DsBackdrop sx={{ zIndex: '99999' }} open>
        <DsCircularProgress />
      </DsBackdrop>
    )
  }
}
