import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBox,
  DsImage,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import { WARNINGS } from '~/src/Constants/ASSET_MAP'
import getSurveillanceScriptsErrorsAction from '~/src/Redux/Indicators/Services/getSurveillanceScriptsErrors'
import { getSurScript } from '~/src/Redux/StockMaster/Selectors'
import { getSurScriptError } from '~/src/Redux/Indicators/Selectors'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import { StockMasterStore } from '~/src/Redux/StockMaster/Reducer'

type ActionTypes = {
  getSurveillanceScriptsErrors: () => {}
}

export interface IScriptSurveillanceProps extends IWithRouterProps {
  actions: ActionTypes
  breakpoints: any
  primaryButtonOnClick: () => void
  secondaryButtonOnClick: () => void
  openSurveillanceAlert: boolean
  surveillanceFlagName: string
  stockDetails: StockMasterStore
  surScriptErrors: any
  surScriptCodes: any
}

class ScriptSurveillance extends Component<IScriptSurveillanceProps> {
  componentDidMount() {
    const { actions } = this.props
    actions.getSurveillanceScriptsErrors()
  }
  render() {
    const {
      primaryButtonOnClick,
      secondaryButtonOnClick,
      breakpoints,
      surScriptCodes,
      surScriptErrors
    } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const show = surScriptCodes.length
    return (
      <>
        <DsPopup
          open={show}
          onClose={secondaryButtonOnClick}
          sx={{
            '.MuiDialogContent-root': {
              marginTop: 'var(--ds-spacing-zero)'
            }
          }}
          // DsDialogProps={{
          //   open: openSurveillanceAlert,
          //   onClose: secondaryButtonOnClick,
          //   sx: {
          //     '.MuiDialogContent-root': {
          //       marginTop: 'var(--ds-spacing-zero)'
          //     }
          //   }
          // }}
          primaryButtonText='Order Anyway'
          secondaryButtonText='Cancel'
          showClose={isDesktop ? false : true}
          primaryButtonProps={{
            onClick: primaryButtonOnClick
          }}
          secondaryButtonProps={{
            onClick: secondaryButtonOnClick
          }}
        >
          <DsStack
            spacing={'var(--ds-spacing-frostbite)'}
            textAlign={{ md: 'center', xs: 'unset' }}
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <DsBox sx={{ display: { md: 'flex', xs: 'none' } }}>
              <DsImage srcSet={WARNINGS} />
            </DsBox>
            <DsStack
              spacing={4}
              sx={{ alignItems: { md: 'center', xs: 'unset' } }}
            >
              <DsStack
                sx={{
                  flexDirection: { md: 'unset', xs: 'row' },
                  alignItems: { md: 'unset', xs: 'center' }
                }}
                gap={'var(--ds-spacing-frostbite)'}
              >
                <DsRemixIcon
                  className='ri-alert-fill'
                  sx={{
                    display: { md: 'none', xs: 'block' },
                    color: 'var(--ds-colour-supportWarning)'
                  }}
                />
                <DsTypography
                  variant={isDesktop ? 'headingBoldLarge' : 'headingBoldMedium'}
                >
                  Scrip under surveillance
                </DsTypography>
              </DsStack>
              <DsBox
                sx={{
                  background: {
                    md: 'unset',
                    xs: 'var(--ds-colour-surfaceSecondary)'
                  },
                  padding: {
                    md: 'unset',
                    xs: 'var(--ds-spacing-glacial) var(--ds-spacing-frostbite)'
                  },
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <ul>
                  {surScriptCodes.map((item: string) => {
                    const { desc = '', value = '' } =
                      surScriptErrors?.[item] || {}
                    return <li key={item}>{`${value} - ${desc}`}</li>
                  })}
                </ul>
              </DsBox>
            </DsStack>
          </DsStack>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { stockDetails } = ownProps
  const surScriptCodes = getSurScript(stockDetails)
  const surScriptErrors = getSurScriptError(state)

  return { surScriptCodes, surScriptErrors }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getSurveillanceScriptsErrors: () =>
      dispatch(getSurveillanceScriptsErrorsAction())
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(ScriptSurveillance)
)
