import {
  I_SEC_MASTER_ARRAY,
  I_INDEX_STRUCTURE,
  T_SEARCH_STRING_CHUNK,
  I_DERIVATIVE_INDEX,
  I_ISIN_CODE_INDEX
} from '~/src/Constants/SEC_MASTER'

export type T_SEC_MATER_EVENT_TYPES = 'INITIATE' | 'PROCESSING' | 'DONE'

export interface I_INDEX_DATA {
  SCRIPT_ID_INDEX: I_INDEX_STRUCTURE
  SEARCH_STRING_CHUNK: any
  DERIVATIVE_INDEX: I_DERIVATIVE_INDEX
  ISIN_CODE_INDEX: I_ISIN_CODE_INDEX
}

export interface I_SUCCESS_POST_MESSAGE_DATA
  extends Omit<I_INDEX_DATA, 'SEARCH_STRING_CHUNK'> {
  MASTER_DATA: I_SEC_MASTER_ARRAY
  SEARCH_STRING_CHUNK: T_SEARCH_STRING_CHUNK
}

export interface I_SUCCESS_POST_MESSAGE_STRUCTURE {
  message: T_SEC_MATER_EVENT_TYPES
  data: I_SUCCESS_POST_MESSAGE_DATA
}

export const GLOBAL_SUCCESS_EVENT_NAME = '__SEC_MASTER_LOADING_DONE__'
