import { combineReducers, ReducersMapObject } from 'redux'

import StockTickerReducer from './StockTicker/Reducer'
import { SLICE_NAME as StockTickerSliceName } from './StockTicker/Selectors'

import HoldingTickerReducer from './HoldingTicker/Reducer'
import { SLICE_NAME as HoldingTickerSliceName } from './HoldingTicker/Selectors'

import DpHoldTickerReducer from './DPHoldTicker/Reducer'
import { SLICE_NAME as DpHoldTickerSliceName } from './DPHoldTicker/Selectors'

import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'

const reducers: ReducersMapObject = {
  [StockTickerSliceName]: StockTickerReducer,
  [HoldingTickerSliceName]: HoldingTickerReducer,
  [DpHoldTickerSliceName]: DpHoldTickerReducer,
  [ErrorSliceName]: ErrorReducer
}

const persistedReducers: string[] = []

export default combineReducers(reducers)
export { persistedReducers }
