// TODO: CLEANUP: @pratik
// 1. move the function separatePriceRange outside the class
import React, { Component } from 'react'
import { DsStack, DsTypography } from '@am92/react-design-system'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { appContext, TAppStore } from '~/src/Configurations/AppStore'
import { getDprFromStockMasterData } from '~/src/Redux/StockMaster/Selectors'

export interface IDPRPopUpProps extends IWithRouterProps {
  priceBoxFocused: boolean
  scriptId: string
  DPRLOW: number
  DPRHIGH: number
}

interface IDPRPopUpState {}

class DPRPopUp extends Component<IDPRPopUpProps, IDPRPopUpState> {
  constructor(props: IDPRPopUpProps) {
    super(props)
  }
  componentDidUpdate(prevState: any): void {}
  render() {
    const { priceBoxFocused, DPRLOW, DPRHIGH } = this.props

    return (
      <>
        {priceBoxFocused && (
          <DsStack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              background: 'var(--ds-colour-surfaceSecondary)',
              p: 'var(--ds-spacing-frostbite) var(--ds-spacing-mild)'
            }}
            color='var(--ds-colour-typoSecondary)'
          >
            <DsTypography variant='supportRegularInfo'>
              Daily Price Range
            </DsTypography>
            <DsStack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 'var(--ds-spacing-mild)'
              }}
            >
              <DsTypography variant='supportRegularInfo'>
                Min {DPRLOW}
              </DsTypography>
              <DsTypography variant='supportRegularInfo'>
                Max {DPRHIGH}
              </DsTypography>
            </DsStack>
          </DsStack>
        )}
      </>
    )
  }
}
const mapStateToProps = (state: TAppStore, ownProps: any) => {
  const { scriptId } = ownProps
  const { DPRLOW, DPRHIGH } = getDprFromStockMasterData(scriptId)(state) || {}
  return {
    DPRLOW,
    DPRHIGH
  }
}
const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})(DPRPopUp)
