import { jsx as _jsx } from "react/jsx-runtime";
import { useThemeProps } from '@mui/system';
import { DsTextField } from '../../../Components';
const DatePickerTextField = (props) => {
    const mergedProps = useThemeProps({
        props: props,
        name: 'MuiPickersInput'
    });
    const { setOpen, ref, InputProps, focused, ownerState, ...other } = mergedProps;
    const { readOnly } = InputProps || {};
    return _jsx(DsTextField, { readOnly: readOnly, ...other });
};
export default DatePickerTextField;
