import { DsSkeleton, DsTableCell, DsTableRow } from '@am92/react-design-system'
import React from 'react'

const MarketDepthSkeleton = () => {
  return (
    <>
      <DsTableRow>
        <DsTableCell
          sx={{
            borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            pr: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          align='left'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          align='center'
          sx={{
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: 0,
            pl: 0
          }}
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          sx={{
            borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pl: 0
          }}
          align='right'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
      </DsTableRow>
      <DsTableRow>
        <DsTableCell
          sx={{
            borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            pr: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          align='left'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          align='center'
          sx={{
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: 0,
            pl: 0
          }}
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          sx={{
            borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pl: 0
          }}
          align='right'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
      </DsTableRow>
      <DsTableRow>
        <DsTableCell
          sx={{
            borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            pr: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          align='left'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          align='center'
          sx={{
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: 0,
            pl: 0
          }}
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          sx={{
            borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pl: 0
          }}
          align='right'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
      </DsTableRow>
      <DsTableRow>
        <DsTableCell
          sx={{
            borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomLeftRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            pr: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          align='left'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          align='center'
          sx={{
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: 0,
            pl: 0
          }}
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
        <DsTableCell
          sx={{
            borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
            borderBottomRightRadius: 'var(--ds-spacing-quickFreeze)',
            pt: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pl: 0
          }}
          align='right'
        >
          <DsSkeleton variant='rounded' sx={{ height: '22px' }} />
        </DsTableCell>
      </DsTableRow>
    </>
  )
}

export default MarketDepthSkeleton
