import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './selectors'
import {
  getDematListActions,
  getMultiplePortfolioActions,
  getMutualFundPortfolioActions,
  getMutualFundSchemesHoldingActions,
  getSecurityLimitActions,
  updateDematListFilterAction,
  updateFormattedDematListAction,
  updateSelectedDematScriptAction,
  updateSelectedSecurityLimitScriptAction
} from './actions'
import localforage from 'localforage'
import { DEFAULT_VALUES } from '../Dashboard/Reducer'
import {
  TSecurityLimitData,
  TSecurityLimitFormattedData
} from '../DPHoldTicker/Reducer'
import { _groupBy } from '~/src/Utils/lodash'
import {
  I_PORTFOLIO_REDUCER,
  IDematList,
  INITIAL_STATE,
  ISingleMutualFundPortfolioData
} from './TYPES'
import TickerStore from '~/src/Configurations/TickerStore'
import { updateDpHoldDataInTickerAction } from '../DPHoldTicker/Actions'

export const multiPortfolioMapper: {
  [key: string]: string
} = {
  'Direct Transactions-Equity': 'stocks',
  'SmartEdge-Equity': 'smartedgeplus',
  'Managed Account-Beta-Equity': 'cat3',
  'Direct Transactions-MutualFund': 'MutualFund'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedPortfolio: (state, action) => {
      state.selectedPortfolio = action?.payload
    },
    toggleMutualFundDisclaimer: (state, action) => {
      state.showMutualFundDisclaimer = !state.showMutualFundDisclaimer
    },
    toggleMutualFundSortAndFilter: (state, action) => {
      state.showMutualFundSortAndFilter = !state.showMutualFundSortAndFilter
    },
    mutualFundSortAndFilter: (state, action) => {
      state.mutualFundSortAndFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    changeSelectedSortAndFilterOption: (state, action) => {
      state.selectedSortAndFilterOption = action?.payload
    },
    resetOriginalMutualFundData: state => {
      if (!state.isDefaultFiltersEnabled) {
        state.mutualFundSortedAndFilteredData =
          state.mutualFundPortfolioData.portfolio.sort(
            (
              ITEM_A: ISingleMutualFundPortfolioData,
              ITEM_B: ISingleMutualFundPortfolioData
            ) => {
              const { investment: investedValueA } = ITEM_A
              const { investment: investedValueB } = ITEM_B
              return investedValueB - investedValueA
            }
          )
        state.mutualFundSortAndFilter = INITIAL_STATE.mutualFundSortAndFilter
        state.isDefaultFiltersEnabled = true
      }
      state.selectedSortAndFilterOption =
        INITIAL_STATE.selectedSortAndFilterOption
    },
    setSortedAndFilteredMutualFundData: (state, action) => {
      state.mutualFundSortedAndFilteredData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(
      getMultiplePortfolioActions.success,
      (state, { payload }) => {
        const { porfolio: portfolio, portfolioSummary } = payload
        const multiplePortFolioSections = []
        let stockPortFolioCount = 0
        let mutualFundPortFolioCount = 0
        for (let i = 0; i < portfolio?.length; i++) {
          const { holdingType } = portfolio[i]
          if (holdingType === 'Equity') {
            stockPortFolioCount++
            continue
          }
          mutualFundPortFolioCount++
        }

        // create portfolio sections
        stockPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Stocks Portfolios',
            filter: 'MutualFund',
            filterType: false,
            gridTemplateColumns: '49% 49%'
          })

        mutualFundPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Mutual Funds',
            filter: 'MutualFund',
            filterType: true,
            gridTemplateColumns: '49%'
          })

        state.multiplePortFolioSections = multiplePortFolioSections
        state.multiplePortfolioData = {
          portfolioSummary,
          portfolio
        }
      }
    )
    builder.addCase(
      getMutualFundPortfolioActions.success,
      (state, { payload }) => {
        const { portfolio } = payload
        state.mutualFundPortfolioData = payload
        const mutualFundSortedAndFilteredData = portfolio.sort(
          (
            ITEM_A: ISingleMutualFundPortfolioData,
            ITEM_B: ISingleMutualFundPortfolioData
          ) => {
            const { investment: investedValueA } = ITEM_A
            const { investment: investedValueB } = ITEM_B
            return investedValueB - investedValueA
          }
        )
        state.mutualFundSortedAndFilteredData = mutualFundSortedAndFilteredData

        // initially setting the original data in this temperory source used for sorting and filtering
      }
    )
    builder.addCase(
      getMutualFundSchemesHoldingActions.success,
      (state, { payload }) => {
        state.mutualFundQuotesDetails = payload
      }
    )

    builder.addCase(getDematListActions.success, (state, { payload }) => {
      const dematScriptList: {
        scriptId: string
        freeQuantity: number
        onHoldQuantity: number
        exchangeSymbol?: string
        coCode?: string
      }[] = []

      const { __ISIN_CODE_INDEX__ } = window as any
      const dematList = payload

      // Loop through each item in the demat list
      dematList.forEach((item: IDematList) => {
        const { instrumentId, freeQuantity, onHoldQuantity } = item

        const commonIsinCodeScriptIdArray = __ISIN_CODE_INDEX__[instrumentId]

        let selectedScriptId = null
        let exchangeSymbol = null
        let coCode = null

        // if Only one scriptId exists for the given ISIN code
        if (commonIsinCodeScriptIdArray?.length === 1) {
          selectedScriptId = commonIsinCodeScriptIdArray[0]

          const scriptDetails = (window as any).secMaster.getByScriptId(
            selectedScriptId
          )

          // Extract script details like exchangeSymbol and coCode
          exchangeSymbol = scriptDetails?.exchangeSymbol
          coCode = scriptDetails?.CMOTS_COCODE

          // if Multiple scriptIds exist, pick the one from NSE exchange
        } else if (commonIsinCodeScriptIdArray?.length > 1) {
          for (const scriptId of commonIsinCodeScriptIdArray) {
            const scriptDetails = (window as any).secMaster.getByScriptId(
              scriptId
            )

            if (scriptDetails?.exchange === 'NSE') {
              selectedScriptId = scriptId
              exchangeSymbol = scriptDetails?.exchangeSymbol
              coCode = scriptDetails?.CMOTS_COCODE
              break
            }
          }
        }

        // If a valid scriptId is selected, push the details into dematScriptList
        if (selectedScriptId) {
          dematScriptList.push({
            scriptId: selectedScriptId,
            freeQuantity,
            onHoldQuantity,
            exchangeSymbol,
            coCode
          })
        }
      })

      // Mapping script details to formatted demat data for the ticker store and app store
      const dematickerData = dematScriptList.map(item => {
        const {
          scriptId,
          freeQuantity,
          onHoldQuantity,
          exchangeSymbol,
          coCode
        } = item

        const {
          closeprice,
          assetClass,
          label
        }: { closeprice: number; assetClass: string; label: string } =
          (window as any).secMaster.getByScriptId(scriptId) || {}

        return {
          closeprice,
          scriptId,
          label,
          holdingQty: freeQuantity,
          assetClass,
          onHoldQuantity,
          exchangeSymbol,
          coCode
        }
      })

      state.formattedDematList = dematickerData
      state.filteredDematList = dematickerData

      TickerStore.dispatch(updateDpHoldDataInTickerAction(dematickerData))
    })

    builder.addCase(updateFormattedDematListAction, (state, { payload }) => {
      state.formattedDematList = payload
      state.filteredDematList = payload
    })

    builder.addCase(updateSelectedDematScriptAction, (state, { payload }) => {
      state.selectedDematScript = payload
    })
    builder.addCase(getSecurityLimitActions.success, (state, { payload }) => {
      const { __ISIN_CODE_INDEX__ } = window as any
      let data: TSecurityLimitFormattedData[] = []
      let totalCollateralAmount = 0

      // Iterate item in the payload
      payload?.forEach((item: TSecurityLimitData) => {
        const {
          instrumentIdentity,
          totalQuantity,
          collateralQuantity,
          collateralAmount,
          interSettlementQuantity
        } = item

        const { isinCode, instrumentId } = instrumentIdentity
        const commonIsinCodeScriptIdArray = __ISIN_CODE_INDEX__[isinCode]

        let selectedScriptId = null
        let exchangeSymbol = null
        let coCode = null
        let closeprice = null
        let segment = null

        //if Only one scriptId exists for the given ISIN code
        if (commonIsinCodeScriptIdArray?.length === 1) {
          selectedScriptId = commonIsinCodeScriptIdArray[0]
          const scriptDetails = (window as any).secMaster.getByScriptId(
            selectedScriptId
          )

          // Extract script details such as exchange symbol, company
          exchangeSymbol = scriptDetails?.exchangeSymbol
          coCode = scriptDetails?.CMOTS_COCODE
          closeprice = scriptDetails?.closeprice
          segment = scriptDetails?.segment

          //if Multiple scriptIds exist, pick the one from NSE exchange
        } else if (commonIsinCodeScriptIdArray?.length > 1) {
          for (const scriptId of commonIsinCodeScriptIdArray) {
            const scriptDetails = (window as any).secMaster.getByScriptId(
              scriptId
            )

            if (scriptDetails?.exchange === 'NSE') {
              selectedScriptId = scriptId

              // Extract details for the selected NSE script
              exchangeSymbol = scriptDetails?.exchangeSymbol
              coCode = scriptDetails?.CMOTS_COCODE
              closeprice = scriptDetails?.closeprice
              segment = scriptDetails?.segment
              break
            }
          }
        }

        // Add the script details to the data array if a  scriptId is found
        if (selectedScriptId) {
          data.push({
            segment,
            closeprice,
            instrumentId,
            totalQuantity,
            collateralQuantity,
            collateralAmount,
            interSettlementQuantity,
            scriptId: selectedScriptId,
            exchangeSymbol,
            coCode
          })

          // calculate total collateral amount
          totalCollateralAmount += parseInt(collateralAmount)
        }
      })

      state.securityLimitList = data
      state.totalCollateralAmount = totalCollateralAmount
    })

    builder.addCase(
      updateSelectedSecurityLimitScriptAction,
      (state, { payload }) => {
        state.selectedSecurityLimitScript = payload
      }
    )
  }
}

function checkIfDefaultFiltersAreSet(state: I_PORTFOLIO_REDUCER) {
  const { mutualFundSortAndFilter } = state
  const { mutualFundFilter, sortBy } = mutualFundSortAndFilter
  const noFilters = mutualFundFilter.length === 0 ? true : false
  if (sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)

export const {
  setSelectedPortfolio,
  toggleMutualFundDisclaimer,
  toggleMutualFundSortAndFilter,
  mutualFundSortAndFilter,
  changeSelectedSortAndFilterOption,
  resetOriginalMutualFundData,
  setSortedAndFilteredMutualFundData
} = slice.actions

export const portfolioPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: ['selectedPortfolio']
}

export default slice.reducer
