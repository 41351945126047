import React, { Component } from 'react'
import {
  DsBox,
  DsButton,
  DsImage,
  DsListItemButton,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTextField,
  DsTypography,
  enqueueNotistack,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import { selectWatchlistTab, watchlistObj } from '~/src/Redux/WatchList/Reducer'
import {
  getPreKeptAvailableNames,
  getWatchListData,
  getWatchListLength
} from '~/src/Redux/WatchList/Selectors'
import createWatchlistAction, {
  createWatchlistPayload
} from '~/src/Redux/WatchList/Services/CreateWatchlist.Service'

import { ADD_WATCHLISTM } from '~/src/Constants/ASSET_MAP'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

type ActionTypes = {
  createWatchlist: (reqData: createWatchlistPayload) => Promise<any>
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
}

export interface ICreateWatchlistProps
  extends IWithRouterProps,
    IwithBreakpoints {
  actions: ActionTypes
  watchListData: watchlistObj[]
  preKeptAvailableNamesObj: {
    [key: string]: number
  }
  watchListLength: number
  componentType?: string
  handleTabScrollingOnNewWatchlistCreation?: (
    isWatchlistCreated: boolean
  ) => void
}

class CreateWatchlist extends Component<ICreateWatchlistProps> {
  state = {
    watchlistName: '',
    watchlistNameError: false,
    watchlistNameHelperText: '',
    shouldChangeTab: false,
    openSetWatchListNameModal: false
  }

  handleOpenSetWatchlistNameModal = (shouldChangeTab: boolean) => {
    const { watchListLength } = this.props
    if (watchListLength < 12) {
      this.setState({
        watchlistName: this.getPreKeptNameForNewWatchlist(),
        openSetWatchListNameModal: true,
        shouldChangeTab
      })
      return
    }
    enqueueNotistack({
      message: 'You can only add upto 10 watchlists'
    })
  }

  getPreKeptNameForNewWatchlist = () => {
    const { preKeptAvailableNamesObj } = this.props
    const preKeptAvailableNamesArray = Object.keys(preKeptAvailableNamesObj)

    const sortedArray = [...preKeptAvailableNamesArray]?.sort(
      function (preKeptName1, preKeptName2) {
        const preKeptNameNumber1 = Number(preKeptName1.split(' ')[1])
        const preKeptNameNumber2 = Number(preKeptName2.split(' ')[1])

        if (preKeptNameNumber1 < preKeptNameNumber2) {
          return -1
        }
        if (preKeptNameNumber1 > preKeptNameNumber2) {
          return 1
        }
        return 0
      }
    )
    const preKeptName = sortedArray[0]
    return preKeptName
  }

  handleCloseSetWatchlistNameModal = () => {
    this.setState({
      openSetWatchListNameModal: false,
      watchlistNameError: false,
      watchlistNameHelperText: ''
    })
  }

  handleCreateWatchlist = async () => {
    const { watchListData, handleTabScrollingOnNewWatchlistCreation, actions } =
      this.props
    const { watchlistName, shouldChangeTab } = this.state
    handleTabScrollingOnNewWatchlistCreation &&
      handleTabScrollingOnNewWatchlistCreation(false)
    const duplicateFound = watchListData.some(
      item => item?.watchlistName === watchlistName.trim()
    )
    if (duplicateFound) {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Watchlist name should be unique'
      })
      return
    }
    if (watchListData.length < 12) {
      const requestData: createWatchlistPayload = {
        watchlistName: watchlistName.trim(),
        watchlistType: 'CUSTOM',
        watchlistSecurities: null
      }

      const createWatchlistResponse = await actions.createWatchlist(requestData)
      if (createWatchlistResponse?._isCustomError) {
        console.log('failed API - createWatchlist')

        enqueueNotistack({
          message: 'Request failed!'
        })
        return
      }

      enqueueNotistack({
        message: 'Watchlist created!'
      })

      shouldChangeTab && actions.setSelectedWatchlistTab(watchListData.length)
    }
    this.handleCloseSetWatchlistNameModal()
    window.scrollTo(0, 0)
    handleTabScrollingOnNewWatchlistCreation &&
      handleTabScrollingOnNewWatchlistCreation(true)
  }

  handleWatchlistName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const watchlistName = e.target.value
    this.setState({ watchlistName })
    this.validateWatchlistName(watchlistName.trim())
  }

  validateWatchlistName = (name: string) => {
    if (name === '') {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Name can’t be empty'
      })
    } else if (name.length >= 18) {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Watchlist name should be 1-18 characters long'
      })
    } else {
      this.setState({
        watchlistNameError: false,
        watchlistNameHelperText: ''
      })
    }
  }

  render() {
    const { breakpoints = {}, componentType } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const {
      watchlistName,
      watchlistNameError,
      watchlistNameHelperText,
      openSetWatchListNameModal
    } = this.state
    return (
      <DsBox className='createWatchlist'>
        {componentType == 'sidebar' ? (
          <>
            <DsListItemButton
              sx={{
                display: { md: 'block', xs: 'none' },
                p: 'var(--ds-spacing-frostbite) var(--ds-spacing-zero)'
              }}
              onClick={() => this.handleOpenSetWatchlistNameModal(true)}
              className='createWatchlist__btnAddNew'
            >
              <DsBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textAlign: 'center',
                  justifyContent: 'center',
                  gap: 'var(--ds-spacing-frostbite)',
                  borderRight: '1px solid var(--ds-colour-strokeDefault)'
                }}
              >
                <DsRemixIcon className='ri-add-line' fontSize='mild' />
                <DsTypography variant='bodyRegularLarge'>
                  {WATCHLIST_LABEL.BOTTOM_BUTTONS.ADD_NEW_WATCHLIST}
                </DsTypography>
              </DsBox>
            </DsListItemButton>

            <DsListItemButton
              sx={{ display: { md: 'none', xs: 'block' }, p: 0 }}
              onClick={() => this.handleOpenSetWatchlistNameModal(true)}
            >
              <DsStack
                sx={{
                  flexDirection: 'row',
                  p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
                  gap: 'var(--ds-spacing-frostbite)',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <DsBox>
                  <DsImage
                    srcSet={ADD_WATCHLISTM}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'var(--ds-spacing-mild)',
                      height: 'var(--ds-spacing-mild)'
                    }}
                  />
                </DsBox>
                <DsTypography
                  variant='bodyBoldSmall'
                  color={'var(--ds-colour-iconActionTertiary)'}
                  textTransform={'uppercase'}
                  className='createWatchlist__btnNewWatchlist'
                >
                  {WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.NEW_WATCHLIST}
                </DsTypography>
              </DsStack>
            </DsListItemButton>
          </>
        ) : null}

        {componentType === 'search' ? (
          <DsButton
            variant='text'
            color='secondary'
            onClick={() => this.handleOpenSetWatchlistNameModal(false)}
          >
            CREATE NEW
          </DsButton>
        ) : null}

        {componentType === 'manageWatchlist' ? (
          <DsButton
            variant='text'
            color='secondary'
            onClick={() => this.handleOpenSetWatchlistNameModal(false)}
            className='createWatchlist__btnNewWatchlistMaganeTab'
          >
            + {WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.NEW_WATCHLIST}
          </DsButton>
        ) : null}

        <DsPopup
          open={openSetWatchListNameModal}
          onClose={this.handleCloseSetWatchlistNameModal}
          // DsDialogProps={{
          //   open: openSetWatchListNameModal,
          //   onClose: this.handleCloseSetWatchlistNameModal
          // }}
          primaryButtonText='Save'
          secondaryButtonText='Cancel'
          showClose={!isDesktop}
          primaryButtonProps={{
            onClick: this.handleCreateWatchlist,
            disabled: watchlistName.length === 0 || watchlistNameError,
            className: 'setWatchlistName__btnSave'
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseSetWatchlistNameModal,
            className: 'setWatchlistName__btnCancel'
          }}
          title={WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.NAME_YOUR_WATCHLIST}
        >
          <DsTextField
            autoFocus
            fullWidth
            type='text'
            value={watchlistName}
            error={watchlistNameError}
            helperText={watchlistNameHelperText}
            onChange={this.handleWatchlistName}
          />
        </DsPopup>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const watchListData = getWatchListData(state)
  const preKeptAvailableNamesObj = getPreKeptAvailableNames(state)
  const watchListLength = getWatchListLength(state)

  return {
    watchListData,
    preKeptAvailableNamesObj,
    watchListLength
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    createWatchlist: (requestData: createWatchlistPayload) =>
      dispatch(createWatchlistAction(requestData)),
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      )
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(CreateWatchlist)
)
