import { SEC_MASTER_S3_URL } from '../Configurations/env'

export type T_SEC_SEGMENT_TYPES =
  | 'NSE_EQ_EQUITY'
  | 'BSE_EQ_EQUITY'
  | 'NSE_FO_FUTSTK'
  | 'NSE_FO_FUTIDX'
  | 'NSE_FO_OPTIDX'
  | 'NSE_FO_OPTSTK'
  | 'NSE_CURR_FUTCUR'
  | 'NSE_CURR_OPTCUR'
  | 'MCX_COMM_OPTFUT'
  | 'MCX_COMM_FUTCOM'
  | 'NCDEX_COMM_OPTFUT'
  | 'NCDEX_COMM_FUTCOM'
  | 'NSE_CURR_UNDERLYING'
  | 'MCX_COMM_UNDERLYING'
  | 'NCDEX_COMM_UNDERLYING'
  | 'NSE_EQ_UNDERLYING'
  | 'BSE_EQ_UNDERLYING'

export type T_EXCHANGE_TYPES = 'NSE' | 'BSE' | 'MCX' | 'NCDEX'

type SEC_SEGMENT_STRUCTURE_TYPE = 'FLAT_EQUITY' | 'FLAT_UNDERLYING' | 'NESTED'

export type SEC_SEGMENT_STRUCTURE_MAP_TYPE = {
  name: T_SEC_SEGMENT_TYPES
  type: SEC_SEGMENT_STRUCTURE_TYPE
}

export const SEC_SEGMENTS: SEC_SEGMENT_STRUCTURE_MAP_TYPE[] = [
  { name: 'NSE_EQ_EQUITY', type: 'FLAT_EQUITY' },
  { name: 'BSE_EQ_EQUITY', type: 'FLAT_EQUITY' },
  { name: 'NSE_FO_FUTSTK', type: 'NESTED' },
  { name: 'NSE_FO_FUTIDX', type: 'NESTED' },
  { name: 'NSE_FO_OPTIDX', type: 'NESTED' },
  { name: 'NSE_FO_OPTSTK', type: 'NESTED' },
  { name: 'NSE_CURR_FUTCUR', type: 'NESTED' },
  { name: 'NSE_CURR_OPTCUR', type: 'NESTED' },
  { name: 'MCX_COMM_OPTFUT', type: 'NESTED' },
  { name: 'MCX_COMM_FUTCOM', type: 'NESTED' },
  { name: 'NCDEX_COMM_OPTFUT', type: 'NESTED' },
  { name: 'NCDEX_COMM_FUTCOM', type: 'NESTED' },
  { name: 'NSE_CURR_UNDERLYING', type: 'FLAT_UNDERLYING' },
  { name: 'MCX_COMM_UNDERLYING', type: 'FLAT_UNDERLYING' },
  { name: 'NCDEX_COMM_UNDERLYING', type: 'FLAT_UNDERLYING' },
  { name: 'NSE_EQ_UNDERLYING', type: 'FLAT_UNDERLYING' },
  { name: 'BSE_EQ_UNDERLYING', type: 'FLAT_UNDERLYING' }
]

export type T_FLATTEN_EQUITY_ARRAY = [
  string,
  number,
  number,
  string,
  string,
  string,
  string,
  string,
  number | null,
  number | null,
  string,
  number | null,
  string,
  string,
  number | null,
  number | null,
  number | null,
  string | number | null,
  string | null,
  number,
  number,
  number,
  number,
  number,
  number
]

export type T_FLATTEN_UNDERLYING_ARRAY = [
  string,
  number,
  number,
  string,
  string | null,
  string,
  number | null,
  number | null,
  string | null,
  number | null,
  string | null,
  string,
  number | null,
  number | null,
  string | number | null,
  string | null,
  number,
  number,
  number,
  number,
  number,
  number
]

export type T_FLATTEN_DERIVATIVES_INNER_ARRAY = [
  string,
  number,
  number,
  string,
  string,
  string,
  number,
  string,
  number,
  number,
  string,
  number,
  string,
  number,
  number,
  number,
  string | number | null,
  string | null,
  number,
  number,
  number,
  number,
  number,
  number
]

export type T_FLATTEN_DERIVATIVES_ARRAY = [
  string,
  number,
  string,
  T_FLATTEN_DERIVATIVES_INNER_ARRAY[]
]

export interface I_SEC_MASTER_ARRAY
  extends Record<
    T_SEC_SEGMENT_TYPES,
    | T_FLATTEN_EQUITY_ARRAY[]
    | T_FLATTEN_UNDERLYING_ARRAY[]
    | T_FLATTEN_DERIVATIVES_ARRAY[]
  > {}

export type I_INDEX_STRUCTURE_VALUE =
  | [T_SEC_SEGMENT_TYPES, number]
  | [T_SEC_SEGMENT_TYPES, number, number, number]

export interface I_INDEX_STRUCTURE
  extends Record<string | number, I_INDEX_STRUCTURE_VALUE> {}

export interface I_SEARCH_STRING_CHUNK_VALUE {
  searchSring: string
  data: I_INDEX_STRUCTURE_VALUE
}

export type T_SEARCH_STRING_CHUNK = I_SEARCH_STRING_CHUNK_VALUE[][]

type T_SEGMENT_DFAULT_MAPPING_OBJECT = {
  exchange: string
  segment: string
  instrumentType: string
}

export type T_SEGMENT_DFAULT_MAPPING = Record<
  T_SEC_SEGMENT_TYPES,
  T_SEGMENT_DFAULT_MAPPING_OBJECT
>

export const SEGMENT_DFAULT_MAPPING: T_SEGMENT_DFAULT_MAPPING = {
  NSE_EQ_EQUITY: {
    exchange: 'NSE',
    segment: 'EQ',
    instrumentType: 'EQUITY'
  },
  BSE_EQ_EQUITY: {
    exchange: 'BSE',
    segment: 'EQ',
    instrumentType: 'EQUITY'
  },
  NSE_FO_FUTSTK: {
    exchange: 'NSE',
    segment: 'FO',
    instrumentType: 'FUTSTK'
  },
  NSE_FO_FUTIDX: {
    exchange: 'NSE',
    segment: 'FO',
    instrumentType: 'FUTIDX'
  },
  NSE_FO_OPTIDX: {
    exchange: 'NSE',
    segment: 'FO',
    instrumentType: 'OPTIDX'
  },
  NSE_FO_OPTSTK: {
    exchange: 'NSE',
    segment: 'FO',
    instrumentType: 'OPTSTK'
  },
  NSE_CURR_FUTCUR: {
    exchange: 'NSE',
    segment: 'CURR',
    instrumentType: 'FUTCUR'
  },
  NSE_CURR_OPTCUR: {
    exchange: 'NSE',
    segment: 'CURR',
    instrumentType: 'OPTCUR'
  },
  MCX_COMM_OPTFUT: {
    exchange: 'MCX',
    segment: 'COMM',
    instrumentType: 'OPTFUT'
  },
  MCX_COMM_FUTCOM: {
    exchange: 'MCX',
    segment: 'COMM',
    instrumentType: 'FUTCOM'
  },
  NCDEX_COMM_OPTFUT: {
    exchange: 'NCDEX',
    segment: 'COMM',
    instrumentType: 'OPTFUT'
  },
  NCDEX_COMM_FUTCOM: {
    exchange: 'NCDEX',
    segment: 'COMM',
    instrumentType: 'FUTCOM'
  },
  NSE_CURR_UNDERLYING: {
    exchange: 'NSE',
    segment: 'CURR',
    instrumentType: 'UNDERLYING'
  },
  MCX_COMM_UNDERLYING: {
    exchange: 'MCX',
    segment: 'COMM',
    instrumentType: 'UNDERLYING'
  },
  NCDEX_COMM_UNDERLYING: {
    exchange: 'NCDEX',
    segment: 'COMM',
    instrumentType: 'UNDERLYING'
  },
  NSE_EQ_UNDERLYING: {
    exchange: 'NSE',
    segment: 'EQ',
    instrumentType: 'UNDERLYING'
  },
  BSE_EQ_UNDERLYING: {
    exchange: 'BSE',
    segment: 'EQ',
    instrumentType: 'UNDERLYING'
  }
}

export interface I_SEARCH_EQUITY_SCRIPT_STRUCTURE {
  scriptId: string
  odinTokenId: number
  aslAllowed: string
  coname: string
  nriAllowed: string | null
  exchangeSymbol: string | null
  assetClass: string | null
  searchPriority: number | null
}

export interface I_EQUITY_SCRIPT_STRUCTURE
  extends I_SEARCH_EQUITY_SCRIPT_STRUCTURE {
  exchangeSecurityId: number
  exchangeSeries: string
  isinCode: string | null
  lotsize: number | null
  ticksize: number | null
  closeprice: number | null
  searchable: string
  yesterdayOpenInt: number | null
  maxSingleOrderQty: number | null
  asm_flag: string | null
  ODIN_LLFC_SEGMENTID: number
  CMOTS_COCODE: number
  DPRLOW: number
  DPRHIGH: number
  FIFTY_TWO_WEEK_LOW: number
  FIFTY_TWO_WEEK_HIGH: number
}

export interface I_UNDERLYING_SCRIPT_STRUCTURE
  extends I_SEARCH_EQUITY_SCRIPT_STRUCTURE {
  exchangeSecurityId: number
  lotsize: number | null
  ticksize: number | null
  closeprice: number | null
  searchable: string
  yesterdayOpenInt: number | null
  asm_flag: string | null
  ODIN_LLFC_SEGMENTID: number
  CMOTS_COCODE: number
  DPRLOW: number
  DPRHIGH: number
  FIFTY_TWO_WEEK_LOW: number
  FIFTY_TWO_WEEK_HIGH: number
}

export interface I_SEARCH_DERIVATIVES_SCRIPT_STRUCTURE {
  scriptId: string
  odinTokenId: number
  aslAllowed: string
  coname: string
  nriAllowed: string | null
  searchPriority: number | null
}
export interface I_DERIVATIVES_SCRIPT_STRUCTURE
  extends I_SEARCH_DERIVATIVES_SCRIPT_STRUCTURE {
  exchangeSecurityId: number
  expiryDate: string
  strikePrice: number
  optionType: string
  lotsize: number | null
  ticksize: number | null
  closeprice: number | null
  searchable: string
  yesterdayOpenInt: number | null
  maxSingleOrderQty: number | null
  asm_flag: string | null
  ODIN_LLFC_SEGMENTID: number
  CMOTS_COCODE: number
  DPRLOW: number
  DPRHIGH: number
  FIFTY_TWO_WEEK_LOW: number
  FIFTY_TWO_WEEK_HIGH: number
}

export interface I_SCRIPT_STRUCTURE
  extends I_EQUITY_SCRIPT_STRUCTURE,
    I_DERIVATIVES_SCRIPT_STRUCTURE {
  segment: string
  exchange: string
  instrumentType: string
}

export const MASTER_DATA_S3_URL = SEC_MASTER_S3_URL

type T_SEGMENT_MAPPER = {
  [key: string]: number
}

// export const SEGMENT_MAPPER: T_SEGMENT_MAPPER = {
//   NSE_EQ: 1,
//   NSE_FO: 2,
//   BSE_EQ: 3,
//   MCX_COMM: 5,
//   NCDEX_COMM: 7,
//   NSE_CURR: 13
// }
export interface I_SCRIPT_SEARCH_STRUCTURE
  extends I_SEARCH_EQUITY_SCRIPT_STRUCTURE,
    I_DERIVATIVES_SCRIPT_STRUCTURE {}

export type T_DERIVATIVE_KEY = 'FUTURES' | 'OPTIONS'

export type T_DERIVATIVE_INDEX_VALUE = { [key in T_DERIVATIVE_KEY]: number[] }

export interface I_DERIVATIVE_INDEX {
  [key: number]: T_DERIVATIVE_INDEX_VALUE
}

export interface I_ISIN_CODE_INDEX {
  [key: string]: string[]
}
