import { createSelector } from '@reduxjs/toolkit'
import { IAuthenticationResponse } from './Reducer'
export const SLICE_NAME = 'sso'
const select = (state: any) => state[SLICE_NAME]
const ssoAuthenticationSelect = (state: any): IAuthenticationResponse =>
  state[SLICE_NAME].ssoAuthentication

export const getSSOAuthenticateData = createSelector(
  select,
  sso => sso.ssoAuthentication
)

export const getSSOValidateData = createSelector(
  select,
  sso => sso.ssoValidation
)

export const getCustomerName = createSelector(select, sso => sso.customerName)

export const getSSOAccessTokenSubAccountIdSelector = createSelector(
  ssoAuthenticationSelect,
  ssoAuthentication => {
    const { authToken, metadata } = ssoAuthentication
    const { token } = authToken
    const { subAccountId = '' } = metadata || {}
    return { token, subAccountId }
  }
)

export const getSSORefreshTokenSelector = createSelector(
  ssoAuthenticationSelect,
  ssoAuthentication => ssoAuthentication.refreshToken.token
)

export const getSubAccountIdSelector = createSelector(
  select,
  sso => sso.subAccountId
)

export const isNRICustomer = createSelector(select, sso => sso?.isNRI)

export const getNRIType = createSelector(select, sso => sso?.settlementType)

export const getRequiredCustomerDetailForNRI = createSelector(
  select,
  sso => sso
)

export const getAccounts = createSelector(
  select,
  sso => sso.ssoAuthentication?.accounts || []
)

export const getSelectedAccount = (subAccountId: string) =>
  createSelector(
    select,
    sso =>
      sso.ssoAuthentication?.accounts?.find(
        (account: { subAccountId: string }) =>
          account?.subAccountId === subAccountId
      ) || {}
  )
