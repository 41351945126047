import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const updateOdinConnectionIndicatorAction = createAction<
  boolean,
  string
>('indicators/updateOdinConnectionIndicator')

export const updateOrderSipPageModalIndicatorServiceName =
  'appstate/openAppStateSipDialog'
export const updateOrderSipPageModalIndicatorActions = createAction<any>(
  updateOrderSipPageModalIndicatorServiceName
)

export const updateModifySipIndicatorServiceName =
  'appstate/updateModifySipPopup'
export const updateModifySipIndicatorActions = createAction<any>(
  updateModifySipIndicatorServiceName
)

export const getSurveillanceScriptsErrorsServiceName =
  'indicators/getSurveillanceScriptsErrors'
export const getSurveillanceScriptsErrorsActions = traceActionsCreator(
  getSurveillanceScriptsErrorsServiceName
)
