import React, { Component, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WEB_HTTP_CONTEXT, WEB_HTTP_REQ_HEADERS } from '@am92/web-http'
import Bowser from 'bowser'

import Loader from './Components/Loader'

import AppRouter from './AppRouter'

import performHandshake from './Services/performHandshake'

import { appContext, TAppDispatch, TAppStore } from './Configurations/AppStore'
import { asHttp } from './Configurations/WebHttp'
import { createOdinWorker, setDeviceDetails } from './Redux/Indicators/Reducer'
import {
  getCustomerName,
  getSSOAccessTokenSubAccountIdSelector,
  getSSORefreshTokenSelector
} from './Redux/SSO/Selectors'
import {
  isSecurityMasterLoadedIndicator,
  isOdinWorkerCreated
} from './Redux/Indicators/Selectors'
import { socketWorkerManager } from './Workers/tcs/SocketWorkerManager'
import {
  modifyOrderServiceName,
  placeOrderServiceName
} from './Redux/Orders/actions'
import { getServiceSelector } from './Redux/ServiceTracker/Selectors'
import { DsFab, DsRemixIcon } from '@am92/react-design-system'
import { CONNECT_ODIN_SOCKET } from './Configurations/env'
import { odinWorkerManager } from './Workers/Odin/OdinWorkerManager'

export interface IAppInitializerProps extends PropsFromRedux {}

export interface IAppInitializerState {
  hasError: boolean
  isLoading: boolean
  handshakeComplete: boolean
}

const DEFAULT_STATE: IAppInitializerState = {
  hasError: false,
  isLoading: false,
  handshakeComplete: false
}

class AppInitializer extends Component<
  IAppInitializerProps,
  IAppInitializerState
> {
  state = DEFAULT_STATE
  socketWorkerManager = null as any
  odinWorkerManager = null as any

  async componentDidMount() {
    this.setTokensIfExist()
    this.setDeviceDetails()
    await this.initialize()
  }

  async componentDidUpdate(prevProps: Readonly<IAppInitializerProps>) {
    const { isSecurityMasterLoaded, accessToken } = this.props
    const {
      isSecurityMasterLoaded: prevIsSecurityMasterLoaded,
      accessToken: prevAccessToken
    } = prevProps
    if (
      prevIsSecurityMasterLoaded !== isSecurityMasterLoaded ||
      accessToken !== prevAccessToken
    ) {
      const { handshakeComplete } = this.state
      if (isSecurityMasterLoaded && handshakeComplete) {
        this.setState({
          isLoading: false
        })
        if (accessToken) {
          this.initiateSocketWorker()
        }
      }
    }
  }

  initialize = async () => {
    this.setState({ isLoading: true })
    try {
      await performHandshake()
      this.setState(
        {
          handshakeComplete: true
        },
        () => {
          const { isSecurityMasterLoaded, accessToken } = this.props
          if (isSecurityMasterLoaded) {
            this.setState({
              isLoading: false
            })
            if (accessToken) {
              this.initiateSocketWorker()
            }
          }
        }
      )
    } catch (error) {
      this.setState({ isLoading: false, hasError: true })
    }
  }

  initiateSocketWorker = async () => {
    const { isOdinWorkerCreatedAlready, accessToken } = this.props
    if (!isOdinWorkerCreatedAlready) {
      await this.createWorker()
    } else {
      !CONNECT_ODIN_SOCKET &&
        this.socketWorkerManager.socketReconnect(accessToken)
    }
  }

  setTokensIfExist = () => {
    const { accessToken, refreshToken } = this.props
    if (accessToken && refreshToken) {
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken)
      asHttp.context.set(
        WEB_HTTP_CONTEXT.AUTHENTICATION_TOKEN_KEY,
        WEB_HTTP_REQ_HEADERS.AUTH_TOKEN
      )
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken)
    }
  }

  setDeviceDetails = () => {
    const { actions } = this.props
    actions.setDeviceDetails({
      deviceName:
        Bowser.parse(window.navigator.userAgent).browser.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).browser.version ||
        'Not Fetched',
      osType:
        Bowser.parse(window.navigator.userAgent).os.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).os.version || 'Not Fetched'
    })
  }

  createWorker = async () => {
    const { actions, accessToken, subAccountId } = this.props

    //NOTE - kept old socket connection as requested by Suhas Kharade from ASL
    if (CONNECT_ODIN_SOCKET) {
      this.odinWorkerManager = odinWorkerManager
      await this.odinWorkerManager.socketInitiate({
        USER_ID: 'API TEST',
        TOKEN:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklkIjoiMTQwNCIsImV4cCI6MTY4OTA0NDg4MCwiaWF0IjoxNjU3NTA4OTIxfQ.46QVfspRCeq9cz-lLai6panvyD8vOr6dxXCDCril7MM'
      })
      actions.createOdinWorkerIndicator(true)
      return
    }

    //NOTE - New Socket
    this.socketWorkerManager = socketWorkerManager
    await this.socketWorkerManager.socketInitiate({
      token: accessToken,
      ent_id: subAccountId
    })
    actions.createOdinWorkerIndicator(true)
  }

  render() {
    const { handshakeComplete, hasError, isLoading } = this.state
    const { isModifyOrderLoading, isPlaceOrderLoading } = this.props
    return (
      <>
        {(isModifyOrderLoading || isPlaceOrderLoading) && <Loader />}
        <Suspense fallback={<Loader />}>
          {(!hasError && !isLoading && handshakeComplete && <AppRouter />) || (
            <Loader />
          )}
        </Suspense>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const { token: accessToken, subAccountId } =
    getSSOAccessTokenSubAccountIdSelector(state)
  const refreshToken = getSSORefreshTokenSelector(state)
  const isSecurityMasterLoaded = isSecurityMasterLoadedIndicator(state)
  const isOdinWorkerCreatedAlready = isOdinWorkerCreated(state)
  const isPlaceOrderLoading =
    getServiceSelector(state, placeOrderServiceName) === 'LOADING'
  const isModifyOrderLoading =
    getServiceSelector(state, modifyOrderServiceName) === 'LOADING'
  const customerName = getCustomerName(state)

  return {
    accessToken,
    refreshToken,
    isSecurityMasterLoaded,
    isOdinWorkerCreatedAlready,
    isPlaceOrderLoading,
    isModifyOrderLoading,
    customerName,
    subAccountId
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setDeviceDetails: (data: any) => dispatch(setDeviceDetails(data)),
    createOdinWorkerIndicator: (requestData: boolean) =>
      dispatch(createOdinWorker(requestData))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AppInitializer)
