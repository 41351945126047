// TODO - CLEANUP: @javed
// 1. split the component into smaller components
// 2. define types for toggleAlertsForm, selectedScripStockMaster, alertFormDetails, SCRIPT_ALERT_OPTIONS
// 3. use colour token sx={{ color: '#12877F' }}
// 4. use colour token - background: '#EBF9F8',
// 5. use spacing token - pb: '3rem',
// 6.  await actions.alertsForm( is used twice - generate the request payload and use  await actions.alertsForm only once

import React, { Component } from 'react'
import {
  DsBottomSheet,
  DsBox,
  DsButton,
  DsCard,
  DsDivider,
  DsFormControl,
  DsFormControlLabel,
  DsRadio,
  DsRadioGroup,
  DsRemixIcon,
  DsSelect,
  DsTextArea,
  DsTextField,
  DsTypography,
  enqueueNotistack,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

// components
import NumberFormatter from '../NumberFormatter'

import { toggleAlertsFormActions } from '~/src/Redux/Alerts/Actions'
import alertsFormService, {
  reqBody
} from '~/src/Redux/Alerts/Services/alertsForm.Service'
import getAlertsService from '~/src/Redux/Alerts/Services/getAlerts.Service'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'

import { SCRIPT_ALERT } from '~/src/Constants/SCRIPT_ALERT_CONSTANTS'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import QuotesStockData from '~/src/Pages/Quotes/Components/QuotesStockData'

type ActionTypes = {
  toggleAlertsForm: (data: any) => any
  alertsForm: (data: reqBody) => any
  getAlerts: () => any
}

interface IAlertsFormProps extends IWithRouterProps, IwithBreakpoints {
  closeForm: () => void
  selectedScripStockMaster: any
  alertFormDetails: any
  actions: ActionTypes
  handleError: (res: any) => any
}
interface IAlertsFormState {
  selectedComparison: string
  price: number | null
  descriptionInput: string
  conditionStaggerOpen: boolean
}

const SCRIPT_ALERT_OPTIONS: any = Object.values(SCRIPT_ALERT)

export class AlertsForm extends Component<IAlertsFormProps, IAlertsFormState> {
  constructor(props: IAlertsFormProps) {
    super(props)
    this.state = {
      selectedComparison: SCRIPT_ALERT.GT.value,
      price: 0,
      descriptionInput: '',
      conditionStaggerOpen: false
    }
  }

  handleComparision = (e: any) => {
    e.preventDefault()
    this.setState({ selectedComparison: e.target.value })
    this.onClose()
  }

  handleValueChange = (event: any) => {
    this.setState({ price: event.target.value })
  }

  handleClose = async () => {
    const { actions } = this.props
    this.setState({
      price: null,
      descriptionInput: '',
      selectedComparison: SCRIPT_ALERT.GT.value
    })
    await actions.toggleAlertsForm({
      toggle: false,
      isModify: false,
      data: null,
      scriptId: ''
    })
  }

  handleReason = (e: any) => {
    this.setState({ descriptionInput: e.target.value })
  }

  handleSubmit = async (e: any) => {
    e.preventDefault()
    const { actions, alertFormDetails, handleError } = this.props
    const { price, selectedComparison, descriptionInput } = this.state
    const { scriptId = '', isModify = false, data = {} } = alertFormDetails
    if (isModify) {
      const { alertId = '' } = data || {}
      const response = await actions.alertsForm({
        alertId,
        scriptId,
        value: Number(price),
        condition: selectedComparison,
        description: descriptionInput
      })
      if (response._isCustomError) {
        handleError(response)
        return false
      }
      enqueueNotistack({
        message: 'Your alert has been set',
        autoHideDuration: 5000,
        icon: <DsRemixIcon className='ri-checkbox-circle-fill' />
      })
    } else {
      const response = await actions.alertsForm({
        scriptId,
        value: Number(price),
        condition: selectedComparison,
        description: descriptionInput
      })
      if (response._isCustomError) {
        handleError(response)
        return false
      }
      enqueueNotistack({
        message: 'Your alert has been set',
        autoHideDuration: 5000,
        icon: <DsRemixIcon className='ri-checkbox-circle-fill' />
      })
    }
    await actions.getAlerts()
    this.handleClose()
  }

  componentDidUpdate(
    prevProps: Readonly<IAlertsFormProps>,
    prevState: Readonly<IAlertsFormState>
  ): void {
    const { alertFormDetails } = this.props
    const { alertFormDetails: prevAlertFormDetails } = prevProps
    const { scriptId: prevScriptId } = prevAlertFormDetails
    const { isModify, data, scriptId } = alertFormDetails || {}
    const { value, description, condition } = data || {}
    if (scriptId !== prevScriptId) {
      this.setState(() => {
        return {
          ...this.state,
          price: isModify ? value : 0,
          descriptionInput: isModify ? description : '',
          selectedComparison: isModify
            ? SCRIPT_ALERT[condition].value
            : SCRIPT_ALERT.GT.value
        }
      })
    }
  }
  componentWillUnmount(): void {
    this.setState({
      price: 0,
      descriptionInput: '',
      selectedComparison: SCRIPT_ALERT.GT.value
    })
  }

  staggerOpen = () => {
    this.setState({ conditionStaggerOpen: true })
  }

  onClose = () => {
    this.setState({ conditionStaggerOpen: false })
  }

  render() {
    const {
      selectedComparison,
      price,
      descriptionInput,
      conditionStaggerOpen
    } = this.state
    const { selectedScripStockMaster, alertFormDetails, breakpoints } =
      this.props
    const { scriptId = '', isModify, data = {} } = alertFormDetails || {}
    const { condition = '', value = '', description = '' } = data || {}
    const { segment, exchangeSymbol, coname, exchange } =
      selectedScripStockMaster || {}
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <DsBox sx={{ width: { md: '24.75rem', sm: '100%' }, height: '100%' }}>
        <DsBox display='flex' flexDirection='column' p='1rem' gap='0.5rem'>
          <DsBox display='flex' flexDirection='row' gap='0.5rem'>
            <DsRemixIcon
              className='ri-close-line'
              sx={{ cursor: 'pointer', ml: '-0.3rem' }}
              onClick={this.handleClose}
            />
            <DsTypography variant='headingBoldExtraSmall'>
              Price Alert
            </DsTypography>
          </DsBox>
          <DsBox
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <DsBox display='flex' flexDirection='column'>
              <DsTypography variant='bodyRegularLarge'>
                {segment === 'EQ' ? exchangeSymbol : coname}
              </DsTypography>
              <DsTypography variant='bodyRegularSmall'>{exchange}</DsTypography>
            </DsBox>
            <DsBox display='flex' flexDirection='column' alignItems='center'>
              <QuotesStockData scriptId={scriptId} type='changePer' />
            </DsBox>
          </DsBox>
        </DsBox>
        <DsDivider />
        <DsBox
          sx={{
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
            pb: '3rem',
            height: { md: '63vh', xs: '70vh' }
          }}
        >
          <DsBox display='flex' flexDirection='column' p='1rem' gap='1rem'>
            <DsBox display='flex' flexDirection='column' gap='0.5rem'>
              <DsTypography variant='bodyRegularMedium'>
                Set Alert for
              </DsTypography>
              <DsSelect
                fullWidth
                value={selectedComparison}
                onChange={this.handleComparision}
                options={SCRIPT_ALERT_OPTIONS}
                onClick={this.staggerOpen}
                disabled={!isDesktop}
              ></DsSelect>
              <DsTypography variant='supportRegularInfo'>
                You’ll get an alert when price will be greater than ₹{price}
              </DsTypography>
            </DsBox>
            <DsTextField
              label='Target Price (₹)'
              inputComponent={NumberFormatter}
              fullWidth
              value={isModify ? value : price}
              inputProps={{
                inputMode: 'numeric'
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.handleValueChange(event)
              }}
            />
            <DsTextArea
              label='Alert Reason (optional)'
              maxLength={320}
              onBlur={function Ga() {}}
              onChange={this.handleReason}
              placeholder='For eg. Looking to sell my Holdings for better profit.'
              defaultValue={isModify ? description || '' : descriptionInput}
            ></DsTextArea>

            <DsCard
              sx={{
                border: '1px solid #E2E2E2',
                boxShadow: 'none',
                borderRadius: '0.8rem'
              }}
            >
              <DsBox display='flex' gap='1rem' p='1rem'>
                <DsBox
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'var(--ds-colour-neutral2)',
                    borderRadius: '4px',
                    width: '2rem',
                    height: '2rem',
                    p: '0.5rem'
                  }}
                >
                  <DsRemixIcon
                    className='ri-lightbulb-flash-fill'
                    sx={{ color: 'var(--ds-colour-iconActionTertiary)' }}
                  />
                </DsBox>
                <DsTypography variant='bodyRegularSmall' color='darkgrey'>
                  The Alert will be active for maximum 365 days or until
                  deleted.
                </DsTypography>
              </DsBox>
            </DsCard>
          </DsBox>
        </DsBox>
        <DsBox
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          p='1rem'
          sx={{
            bottom: 0,
            position: 'absolute',
            boxShadow: '1px 0px 10px 0px rgba(0, 0, 0, 0.3)',
            width: '100%',
            background: 'var(--ds-colour-surfacePrimary)',
            zIndex: 1
          }}
        >
          <DsTypography variant='supportRegularInfo'>
            Price alert will be triggered at {price}
          </DsTypography>
          <DsButton fullWidth onClick={this.handleSubmit} disabled={!price}>
            {isModify ? 'Save Changes' : 'Set Price Alert'}
          </DsButton>
        </DsBox>

        {!isDesktop && (
          <DsBottomSheet
            open={conditionStaggerOpen}
            onClose={this.onClose}
            showClose
            title='Add Alert for'
          >
            <DsFormControl>
              <DsRadioGroup
                value={
                  isModify ? SCRIPT_ALERT[condition].value : selectedComparison
                }
              >
                {SCRIPT_ALERT_OPTIONS.map((item: any) => {
                  return (
                    <DsFormControlLabel
                      sx={{ ml: '0' }}
                      key={item}
                      control={
                        <DsRadio
                          {...item}
                          onChange={this.handleComparision}
                          componentsProps={{
                            typography: {
                              sx: {
                                fontWeight:
                                  selectedComparison === item.value
                                    ? '700 !important'
                                    : 'inherit'
                              }
                            }
                          }}
                        />
                      }
                      label={
                        <DsTypography variant='bodyRegularMedium' color='black'>
                          {SCRIPT_ALERT[condition]?.label}
                        </DsTypography>
                      }
                    />
                  )
                })}
              </DsRadioGroup>
            </DsFormControl>
          </DsBottomSheet>
        )}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TAppStore, ownProps: any) => {
  const { alertFormDetails } = ownProps
  const { scriptId = '' } = alertFormDetails
  const selectedScripStockMaster = getStockMasterData(scriptId)(state)
  return {
    selectedScripStockMaster
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    toggleAlertsForm: (data: any) => dispatch(toggleAlertsFormActions(data)),
    alertsForm: (data: reqBody) => dispatch(alertsFormService(data)),
    getAlerts: () => dispatch(getAlertsService())
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(AlertsForm)
)
