import {
  DsStack,
  DsTypography,
  DsSkeleton,
  DsRemixIcon
} from '@am92/react-design-system'
import React from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getAvailableEquityLimitsSelector } from '~/src/Redux/Inquiry/Selectors'
import getAvailableEquityLimitsAction, {
  getAvailableEquityLimitsServiceName
} from '~/src/Redux/Inquiry/Services/getAvailableEquityLimits'
import { getRequiredMarginData } from '~/src/Redux/Orders/selectors'
import { getServiceSelector } from '~/src/Redux/ServiceTracker/Selectors'
import getRequiredMarginAction from '~/src/Redux/Orders/Services/getRequiredMargin.Service'
import {
  getCurrencyFormat,
  getProductType,
  tickerValue,
  triggerCleverTapEvent
} from '~/src/Utils/global'
import { FormValues } from './OrderFormSection'
import { getRequiredMarginServiceName } from '~/src/Redux/Orders/actions'
import { To } from 'react-router-dom'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Pages/Orderbook/Orderbook.Page'
import { resetEmarginData } from '~/src/Redux/Customer/Reducer'
import {
  updateOrderPageModalIndicator,
  resetOrderData,
  updateExchangeSelectorIndicator
} from '~/src/Redux/Orders/reducer'
import getAvailableCommodityLimitsAction from '~/src/Redux/Inquiry/Services/getAvailableCommodityLimits'

type ActionTypes = {
  getAvailableEquityLimits: (data: any) => any
  getRequiredMargin: (data: any) => any
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  resetOrderData: (requestData: string) => Promise<any>
  resetEmarginData: (requestData: string) => Promise<any>
  updateExchangeSelectorIndicator: (data: any) => void
  getAvailableCommodityLimits: (data: any) => any
}

export interface IFundsAndMarginDetailsProps extends IWithRouterProps {
  actions: ActionTypes
  requiredMargin: any
  availableEquitryLimitsSelector: any
  breakpoints: any
  isGetLimitsLoading: boolean
  orderBuyOrSell: string
  scriptId: string
  segment: string
  values: FormValues
  stockDetails: any
  isGetMarginApiLoding: boolean
  hasGetMarginApiFailed: boolean
  isBmpl: any
}

class FundsAndMarginDetails extends React.Component<IFundsAndMarginDetailsProps> {
  componentDidMount(): void {
    const { scriptId } = this.props
    scriptId && this.fetchRequiredMargin()
    this.handleGetEquityLimits()
  }

  componentDidUpdate(prevProps: Readonly<IFundsAndMarginDetailsProps>): void {
    const { values: PREV_VALUES, scriptId: PREV_SCRIPTID } = prevProps
    const { values, scriptId } = this.props
    const {
      quantity: PREV_QUANTITY,
      triggerPrice: PREV_TRIGGER_PRICE,
      orderType: PREV_ORDER_TYPE,
      price: PREV_PRICE,
      bnpl: PREV_BNPL
    } = PREV_VALUES
    const { quantity, triggerPrice, orderType, price, bnpl } = values

    if (
      scriptId !== PREV_SCRIPTID ||
      quantity !== PREV_QUANTITY ||
      triggerPrice !== PREV_TRIGGER_PRICE ||
      orderType !== PREV_ORDER_TYPE ||
      price !== PREV_PRICE ||
      bnpl !== PREV_BNPL
    ) {
      scriptId && quantity && this.fetchRequiredMargin()
    }
    if (scriptId !== PREV_SCRIPTID) {
      scriptId && this.handleGetEquityLimits()
    }
  }

  handleGetEquityLimits = async () => {
    const { actions, availableEquitryLimitsSelector } = this.props
    const requestPayload = { segment: 'EQ' }

    if (availableEquitryLimitsSelector.availableEquityLimits.deposit) {
      return
    } else {
      await actions.getAvailableEquityLimits(requestPayload)
      this.setState({ isLoading: false })
    }
  }
  handleGetCommodityLimits = async () => {
    const { actions } = this.props
    const requestPayload = { segment: 'COMM' }
    const response = await actions.getAvailableCommodityLimits(requestPayload)
    if (response._isCustomError) {
      // handleError(response)
      console.log('failed API - getAvailableCommodityLimits')
      return
    }
  }

  handleOnRefresh = async () => {
    this.handleGetEquityLimits()
  }

  fetchRequiredMargin = async () => {
    const {
      orderBuyOrSell,
      actions,
      scriptId,
      segment,
      values,
      stockDetails,
      isBmpl
    } = this.props
    const { quantity, orderType, triggerPrice, price, marketDepth } = values
    const isBUY = orderBuyOrSell === 'BUY'
    const productType = isBUY
      ? isBmpl
        ? 'MTF'
        : orderType
      : getProductType(values)
    const { lotsize } = stockDetails || {}
    const data = {
      scriptId: scriptId.toString(),
      segment,
      orderPrice:
        marketDepth === 'market'
          ? Number(tickerValue(scriptId))
          : Number(price),
      orderQuantity:
        segment === 'EQ'
          ? Number(quantity)
          : Number(Number(lotsize) * Number(quantity)),
      transactionType: orderBuyOrSell,
      productType,
      triggerPrice
    }

    const response = await actions.getRequiredMargin(data)
    if (response._isCustomError) {
      console.log('failed API - getRequiredMargin')
    }
  }

  renderLoaderErrorOrNoDataForRequiredMargin = () => {
    const { isGetMarginApiLoding, hasGetMarginApiFailed, requiredMargin } =
      this.props
    const { margin } = requiredMargin

    if (isGetMarginApiLoding) {
      return (
        <DsSkeleton
          variant='text'
          width={40}
          sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
        />
      )
    }

    if (!isGetMarginApiLoding && hasGetMarginApiFailed) {
      return (
        <DsRemixIcon
          sx={{
            '.MuiTypography-root': { fontWeight: 400 },
            alignItems: 'flex-start'
          }}
          className='ri-error-warning-line'
          fontSize='bitterCold'
        />
      )
    }

    if (!isGetMarginApiLoding && !hasGetMarginApiFailed) {
      return (
        <DsTypography variant='bodyRegularSmall'>
          {getCurrencyFormat(margin)}
        </DsTypography>
      )
    }

    return null
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleCloseOrderForm = async () => {
    const { actions } = this.props
    await actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: false,
      orderScriptId: '',
      orderBuyOrSell: '',
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
    await actions.resetOrderData('resetOrderData')
    await actions.resetEmarginData('reset')
    actions.updateExchangeSelectorIndicator({
      exchangeSelectorIndicator: false,
      orderScriptId: ''
    })
  }

  navigateToFundsDashboard = () => {
    const customAttributes = {
      Event_Status: 'Pass',
      Action: 'Funds',
      Screen_Name: 'Transaction_Initiated'
    }
    triggerCleverTapEvent('Order', customAttributes)
    this.handleCloseOrderForm()
    this.navigateTo(APP_ROUTES.FUNDSDASHBOARD.pathname)
  }

  render() {
    const { isGetLimitsLoading, availableEquitryLimitsSelector, values } =
      this.props
    const { availableEquityLimits = {} } = availableEquitryLimitsSelector
    const { currentMarginAvailable = '' } = availableEquityLimits
    const getAvailableLimitsSelector = currentMarginAvailable

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <DsStack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 'var(--ds-spacing-quickFreeze)'
            }}
          >
            {!isGetLimitsLoading ? (
              <DsTypography
                sx={{ cursor: 'pointer' }}
                onClick={this.navigateToFundsDashboard}
                variant='bodyRegularSmall'
              >
                Funds {getCurrencyFormat(getAvailableLimitsSelector) || 'NA'}
              </DsTypography>
            ) : (
              <DsSkeleton
                variant='text'
                width={150}
                sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
              />
            )}
            <DsRemixIcon
              onClick={this.handleOnRefresh}
              className='ri-refresh-line'
              fontSize='bitterCold'
            />
          </DsStack>
          {(values.sectionType === 0 && (
            <DsStack
              sx={{
                flexDirection: 'row',
                gap: 'var(--ds-spacing-quickFreeze)',
                alignItems: 'center'
              }}
            >
              <DsTypography variant='bodyRegularSmall'>Req Margin</DsTypography>
              {this.renderLoaderErrorOrNoDataForRequiredMargin()}
            </DsStack>
          )) ||
            null}
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const availableEquitryLimitsSelector = getAvailableEquityLimitsSelector(state)
  const requiredMargin = getRequiredMarginData(state)

  const isGetLimitsLoading =
    getServiceSelector(state, getAvailableEquityLimitsServiceName) === 'LOADING'
  const getMarginApiState = getServiceSelector(
    state,
    getRequiredMarginServiceName
  )

  const isGetMarginApiLoding = getMarginApiState === 'LOADING'
  const hasGetMarginApiFailed = getMarginApiState === 'ERROR'

  return {
    availableEquitryLimitsSelector,
    requiredMargin,
    isGetLimitsLoading,
    isGetMarginApiLoding,
    hasGetMarginApiFailed
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getAvailableEquityLimits: (data: any) =>
      dispatch(getAvailableEquityLimitsAction(data)),
    getRequiredMargin: (data: any) => dispatch(getRequiredMarginAction(data)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    resetOrderData: (requestData: any) => dispatch(resetOrderData(requestData)),
    resetEmarginData: (requestData: string) =>
      dispatch(resetEmarginData(requestData)),
    updateExchangeSelectorIndicator: (requestData: any) =>
      dispatch(updateExchangeSelectorIndicator(requestData)),
    getAvailableCommodityLimits: (data: any) =>
      dispatch(getAvailableCommodityLimitsAction(data))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(FundsAndMarginDetails)
