import Bowser from 'bowser'
import AppStore from '../Configurations/AppStore'
import { USE_CLEVERTAP } from '../Configurations/env'

export class ClevertapAnalytics {
  public trackEvent(data: any) {
    const { eventName, customAttributes } = data
    const { deviceName, osType } = AppStore.getState().indicators
    const systemAttributes = {
      'App Name': 'Galaxy',
      'App Version': '1.0.13',
      'Device Type': 'Web',
      'Name of Device':
        deviceName ||
        Bowser.parse(window.navigator.userAgent).browser.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).browser.version ||
        'Not Fetched',
      'OS Type':
        osType ||
        Bowser.parse(window.navigator.userAgent).os.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).os.version ||
        'Not Fetched',
      'Event Time': new Date().toISOString()
    }

    const eventData = { ...systemAttributes, ...customAttributes }

    if (USE_CLEVERTAP === 'true')
      window.clevertap.event.push(eventName, eventData)
  }
}
