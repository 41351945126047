import { DsDialog, withBreakpoints } from '@am92/react-design-system'
import React, { Component } from 'react'
import LinkDDPIWrapper from './LinkDDPI.Wrapper'
import withRouter from '~/src/Lib/withRouter'

export interface ILinkDDPIProps {
  breakpoints: any
  onClose: (item: boolean) => void
  openServiceModal: any
}

class LinkDDPI extends Component<ILinkDDPIProps> {
  onClose = () => {}
  render() {
    const { breakpoints = {}, onClose, openServiceModal } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    return (
      <DsDialog
        sx={{
          '.MuiPaper-root': {
            display: 'flex',
            borderRadius: {
              xs: 'var(--ds-radius-zero)',
              md: 'var(--ds-radius-bitterCold)'
            },
            '& .MuiStack-root': {
              justifyContent: 'center',
              '& .MuiBox-root': {}
            },
            '& .MuiBox-root': {
              display: 'flex'
            }
          }
        }}
        showClose={false}
        onClose={this.onClose}
        open={true}
        fullScreen={!isDesktop}
      >
        <LinkDDPIWrapper
          onClose={onClose}
          openServiceModal={openServiceModal}
        />
      </DsDialog>
    )
  }
}

export default withBreakpoints(withRouter(LinkDDPI))
