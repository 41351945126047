import { createAction } from '@reduxjs/toolkit'
import TickerStore from '~/src/Configurations/TickerStore'

export const updateStockMasterAction = createAction<any, string>(
  'stockMaster/updateStockMasterAction'
)

export const setTrendlyneData = (
  stockMasterData: any,
  activeTab: any,
  themeMode: any,
  scriptId: string | number
) => {
  return () => {
    setTrendlyneModalData(stockMasterData, activeTab, themeMode, scriptId)
  }
}

const setTrendlyneModalData = (
  stockMasterData: any,
  activeTab: any,
  themeMode: any,
  scriptId: string | number
) => {
  const tickerStore = TickerStore.getState()
  const { ticker = {} } = tickerStore || {}
  let { LTP } = ticker?.[scriptId] || {}
  if (window && (window as any).TLSummary) {
    var TLSummary = (window as any).TLSummary
    var serverName = 'https://atre.trendlyne.com'
    var config = {
      hideNav: true,
      theme: themeMode,
      targetId: 'summary-view',
      activeTab: 'overview'
    }
    var stockCode = stockMasterData.exchangeSymbol
    var currentPrice = LTP || stockMasterData.closeprice
    var targetId = 'summary-view'
    var activeTabTrendlyne = activeTab ? activeTab?.toLowerCase() : 'overview'
    var TLSummary = (window as any).TLSummary
    TLSummary.triggerSummaryModal(
      stockCode,
      currentPrice,
      null,
      serverName,
      activeTabTrendlyne,
      targetId,
      null,
      null,
      config
    )
    return
  }
  return
}
