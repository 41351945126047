// DONE - CLEANUP: @vasi
// define types
// use valid color codes

import React, { Component } from 'react'
import { DsBox, DsCheckbox, DsTypography } from '@am92/react-design-system'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import { SIPClevertap } from '~/src/Pages/Orderbook/SIP.ClevertapEvents'

export interface ITermsandConditionsProps extends IWithRouterProps {
  accepted: boolean
  setAccepted: (item: boolean) => void
}

export class TermsandConditions extends Component<ITermsandConditionsProps> {
  handleChange = () => {
    const { accepted, setAccepted } = this.props
    setAccepted(!accepted)
  }

  render() {
    const { accepted, navigateTo } = this.props
    return (
      <DsBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 'var(--ds-spacing-glacial)',
          alignItems: 'center',
          backgroundColor: 'var(--ds-colour-neutral1)',

          padding: 'var(--ds-spacing-frostbite)',
          borderRadius: 'var(--ds-spacing-quickFreeze)',
          width: '100%'
        }}
      >
        <DsCheckbox
          name='isTermsAndConditionsAccepted'
          onChange={this.handleChange}
          checked={accepted}
          sx={{ p: 'var(--ds-spacing-zero)' }}
        />
        <DsTypography variant='bodyRegularSmall'>
          I have read and accepted the{' '}
          <DsTypography
            color={'var(--ds-colour-actionSecondary)'}
            variant='bodyRegularSmall'
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => {
              navigateTo(`${APP_ROUTES.SIP_TERMS.pathname}?header=true`)
              new SIPClevertap().onClickConfirm({}, 'TnC')
            }}
          >
            terms and conditions
          </DsTypography>
        </DsTypography>
      </DsBox>
    )
  }
}
export default withRouter(TermsandConditions)
