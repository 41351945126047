import { ClevertapAnalytics } from '~/src/Lib/withClevertap'

export class GTDClevertap extends ClevertapAnalytics {
  onLoad = () => {
    const eventObject = {
      eventName: 'Trackscreen',
      customAttributes: {
        Event_Status: 'Pass',
        Target_Screen: 'GTD Orderbook'
      }
    }
    this.trackEvent(eventObject)
  }

  onUnMount = () => {
    const eventObject = {
      eventName: 'Trackscreen',
      customAttributes: {
        Event_Status: 'Pass',
        Initial_Screen: 'GTD Orderbook'
      }
    }
    this.trackEvent(eventObject)
  }

  //71
  onCreateNewGTD = (action: string, formData: any) => {
    const eventObject = {
      eventName: 'Order',
      customAttributes: {
        Event_Status: 'Pass',
        Action: action,
        Screen_Name: 'Order_GTD',
        Order_Data: formData
      }
    }
    this.trackEvent(eventObject)
  }

  static onLoad: any
  static onUnMount: any
}
