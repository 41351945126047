import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsCheckbox,
  DsRemixIcon,
  DsStack,
  DsTooltip,
  DsTypography
} from '@am92/react-design-system'
import { FormikErrors } from 'formik'
import { FormValues } from './OrderFormSection'
import { triggerCleverTapEvent } from '~/src/Utils/global'

type ActionTypes = {}

export interface IEncashSectionProps extends IWithRouterProps {
  actions: ActionTypes
  encash: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
  isModifyOrder: boolean
  isBUY?: boolean
}

class EncashSection extends Component<IEncashSectionProps> {
  componentWillUnmount(): void {
    const { setFieldValue } = this.props
    setFieldValue('encash', false)
  }
  handleEncashEvent = (value: boolean) => {
    if (!value) {
      const customAttributes = {
        Event_Status: 'Pass',
        Screen_Name: 'Transaction_Encash_Nudge',
        Action: 'SELL'
      }
      triggerCleverTapEvent('Order', customAttributes)
    }
  }
  render() {
    const { encash, handleChange, isModifyOrder, isBUY } = this.props
    return (
      <DsStack
        sx={{
          flexDirection: 'row',
          p: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
          backgroundColor: 'var(--ds-colour-neutral5)',
          gap: 'var(--ds-spacing-glacial)',
          alignItems: 'center'
        }}
      >
        <DsCheckbox
          sx={{
            '&.Mui-checked': {
              color: `var(--ds-colour-${
                isBUY ? 'supportPositive' : 'supportNegative'
              }) !important`
            },
            padding: 'var(--ds-spacing-zero)',
            paddingLeft: {
              md: 'var(--ds-spacing-glacial)',
              xs: 'var(--ds-spacing-zero)'
            }
          }}
          checked={encash}
          onClick={() => this.handleEncashEvent(encash)}
          onChange={handleChange}
          name='encash'
          disabled={isModifyOrder}
        />

        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 'var(--ds-spacing-quickFreeze)'
          }}
        >
          <DsTypography variant={'bodyRegularSmall'}>
            Use Encash and get Instant Payout
          </DsTypography>
          <DsTooltip
            title='Receive sale proceeds on the same day.'
            placement='bottom'
            arrow={false}
            disableTouchListener
            disableInteractive
          >
            <DsRemixIcon
              className='ri-information-line'
              fontSize='bitterCold'
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            />
          </DsTooltip>
        </DsStack>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(EncashSection)
