import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { getNotificationsActions } from './Actions'
import { _groupBy, _isEqual, _orderBy } from '~/src/Utils/lodash'

export type T_NOTIFICATION_DATA = {
  subAccountId: string
  category: string
  title: string
  description: string
  action: string
  createdAt: string
  expiryDate: string
}
export type T_NOTIFICATION_LIST = {
  [key: string]: T_NOTIFICATION_DATA[]
}

const INITIAL_STATE = {
  notifications: {},
  newNotificationIndicator: false
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getNotificationsActions.success, (state, { payload }) => {
      if (payload == null) {
        state.notifications = {}
      } else {
        state.notifications = {
          All: _orderBy(payload, ['createdAt'], 'asc'),
          ..._groupBy(_orderBy(payload, ['createdAt'], 'asc'), 'category')
        }
      }
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
