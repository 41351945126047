import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { updateHoldingDataInTickerAction } from './Actions'
import { updateStockTickerAction } from '../StockTicker/Actions'

export type TOverallPnl = {
  value: number
  percentChange: number
}

export type TTodayPnl = {
  value: number
  percentChange: number
}

export type TIndividualHolding = {
  scriptid: string
  avgPrice: number
  holdingQty: number
  closeprice: number
  overallPnl: TOverallPnl
  todayPnl: TTodayPnl
  currentValue: number
  investedValue: number
}

export type THoldingScripts = {
  [key: number | string]: TIndividualHolding
}

export interface IHOLDINGTICKER {
  overallPnl: TOverallPnl
  todayPnl: TTodayPnl
  currentValue: number
  scriptsInHolding: THoldingScripts
}

const INITIAL_STATE: IHOLDINGTICKER = {
  currentValue: 0,
  overallPnl: {
    value: 0,
    percentChange: 0
  },
  todayPnl: {
    value: 0,
    percentChange: 0
  },
  scriptsInHolding: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(updateHoldingDataInTickerAction, (state, { payload }) => {
      const { holdingData } = payload
      const scriptsInHolding: THoldingScripts = {}

      let initialTotalCurrentValue = 0
      let initialTotalOverallPnlValue = 0
      let initialTotalOverallPnlPercentage = 0
      let initialTotalTodayPnlValue = 0
      let initialTotalTodayPnlPercentage = 0

      holdingData.length &&
        holdingData.map((individualHoldingData: TIndividualHolding) => {
          const {
            holdingQty,
            closeprice: closePriceFromSecurityMaster,
            investedValue,
            scriptid
          } = individualHoldingData

          //SECTION - individual holding's calculations
          const initialIndividualCurrentValue =
            holdingQty * closePriceFromSecurityMaster
          const initialIndividualOverallPnlValue =
            initialIndividualCurrentValue - investedValue
          const initialIndividualOverallPnlPercentage =
            (investedValue &&
              (initialIndividualOverallPnlValue / investedValue) * 100) ||
            0
          const initialIndividualTodayPnlValue =
            initialIndividualCurrentValue -
            holdingQty * closePriceFromSecurityMaster
          const initialIndividualTodayPnlPercentage =
            (investedValue &&
              (initialIndividualTodayPnlValue / investedValue) * 100) ||
            0

          //SECTION - initial total holding's calculations
          initialTotalCurrentValue =
            initialTotalCurrentValue + initialIndividualCurrentValue
          initialTotalOverallPnlValue =
            initialTotalOverallPnlValue + initialIndividualOverallPnlValue
          initialTotalOverallPnlPercentage =
            (investedValue &&
              (initialTotalOverallPnlValue / investedValue) * 100) ||
            0
          initialTotalTodayPnlValue =
            initialTotalTodayPnlValue + initialIndividualTodayPnlValue
          initialTotalTodayPnlPercentage =
            (investedValue &&
              (initialTotalTodayPnlValue / investedValue) * 100) ||
            0
          scriptsInHolding[scriptid] = {
            ...individualHoldingData,
            currentValue: initialIndividualCurrentValue,
            overallPnl: {
              value: initialIndividualOverallPnlValue,
              percentChange: initialIndividualOverallPnlPercentage
            },
            todayPnl: {
              value: initialIndividualTodayPnlValue,
              percentChange: initialIndividualTodayPnlPercentage
            },
            investedValue
          }
        })
      state.currentValue = initialTotalCurrentValue
      state.overallPnl = {
        value: initialTotalOverallPnlValue,
        percentChange: initialTotalOverallPnlPercentage
      }
      state.todayPnl = {
        value: initialTotalTodayPnlValue,
        percentChange: initialTotalTodayPnlPercentage
      }
      state.scriptsInHolding = scriptsInHolding
    })
    builder.addCase(updateStockTickerAction, (state, { payload }) => {
      const { LTP, scriptId, CLOSE_PRICE: closePriceFromTicker } = payload

      //SECTION - destructuring total values
      const {
        currentValue: PREVIOUS_TOTAL_CURRENT_VALUE,
        todayPnl: PREVIOUS_TOTAL_TODAY_PNL,
        overallPnl: PREVIOUS_TOTAL_OVERALL_PNL
      } = state
      const { value: PREVIOUS_TOTAL_OVERALL_PNL_VALUE } =
        PREVIOUS_TOTAL_OVERALL_PNL
      const { value: PREVIOUS_TOTAL_TODAY_PNL_VALUE } = PREVIOUS_TOTAL_TODAY_PNL

      const scriptData = state['scriptsInHolding'][scriptId] || null
      if (scriptData) {
        //SECTION - destructuring individual values
        const {
          holdingQty,
          closeprice: closePriceFromSecurityMaster,
          overallPnl: previousIndividualOverallPnl,
          todayPnl: previousIndividualTodayPnl,
          currentValue: previousIndividualCurrentValue = 0,
          investedValue
        } = scriptData

        const { value: previousIndividualOverallPnlValue = 0 } =
          previousIndividualOverallPnl
        const { value: previousIndividualTodayPnlValue = 0 } =
          previousIndividualTodayPnl

        //SECTION - new values definitions
        let latestIndividualCurrentValue = 0
        let latestIndividualOverallPnlValue = 0
        let latestIndividualOverallPnlPercentage = 0
        let latestIndividualTodayPnlValue = 0
        let latestIndividualTodayPnlPercentage = 0

        let differenceInIndividualCurrentValue = 0
        let differenceInIndividualOverallPnlValue = 0
        let differenceInIndividualTodayPnlValue = 0

        let totalCurrentValue = 0
        let totalOverallPnlValue = 0
        let totalOverallPnlPercentage = 0
        let totalTodayPnlValue = 0
        let totalTodayPnlPercentage = 0

        //SECTION - individual holding's calculations
        latestIndividualCurrentValue =
          holdingQty * (LTP || closePriceFromSecurityMaster)
        latestIndividualOverallPnlValue =
          latestIndividualCurrentValue - investedValue
        latestIndividualOverallPnlPercentage =
          (investedValue &&
            (latestIndividualOverallPnlValue / investedValue) * 100) ||
          0
        latestIndividualTodayPnlValue =
          latestIndividualCurrentValue -
          holdingQty * (closePriceFromTicker || closePriceFromSecurityMaster)
        latestIndividualTodayPnlPercentage =
          (investedValue &&
            (latestIndividualTodayPnlValue / investedValue) * 100) ||
          0

        //SECTION - individual holding differences calculations
        differenceInIndividualCurrentValue =
          latestIndividualCurrentValue - previousIndividualCurrentValue
        differenceInIndividualOverallPnlValue =
          latestIndividualOverallPnlValue - previousIndividualOverallPnlValue
        differenceInIndividualTodayPnlValue =
          latestIndividualTodayPnlValue - previousIndividualTodayPnlValue

        //SECTION - total holding's calculations
        totalCurrentValue =
          PREVIOUS_TOTAL_CURRENT_VALUE + differenceInIndividualCurrentValue
        totalOverallPnlValue =
          PREVIOUS_TOTAL_OVERALL_PNL_VALUE +
          differenceInIndividualOverallPnlValue
        totalOverallPnlPercentage =
          (investedValue && (totalOverallPnlValue / investedValue) * 100) || 0
        totalTodayPnlValue =
          PREVIOUS_TOTAL_TODAY_PNL_VALUE + differenceInIndividualTodayPnlValue
        totalTodayPnlPercentage =
          (investedValue && (totalTodayPnlValue / investedValue) * 100) || 0

        //SECTION - setting new data in holding summary
        state.currentValue = totalCurrentValue
        state.overallPnl = {
          ...state.overallPnl,
          value: totalOverallPnlValue,
          percentChange: totalOverallPnlPercentage
        }
        state.todayPnl = {
          ...state.todayPnl,
          value: totalTodayPnlValue,
          percentChange: totalTodayPnlPercentage
        }

        //SECTION - setting new data in individual holdings
        state['scriptsInHolding'][scriptId] = {
          ...state['scriptsInHolding'][scriptId],
          currentValue: latestIndividualCurrentValue,
          overallPnl: {
            value: latestIndividualOverallPnlValue,
            percentChange: latestIndividualOverallPnlPercentage
          },
          todayPnl: {
            value: latestIndividualTodayPnlValue,
            percentChange: latestIndividualTodayPnlPercentage
          }
        }
      }
    })
  }
}

const holdingTickerSlice = createSlice(sliceOptions)
export default holdingTickerSlice.reducer
