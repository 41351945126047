import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  getScreenersByIdActions,
  getScreenersActiveConfigActions,
  setSelectedAssetClassAction,
  setSelectedExchangeAction,
  setSelectedGroupNameAction,
  setSelectedSubCategoryAction,
  selectedExpiryDateAction,
  selectedSegmentAction,
  setSelectedCallPutAction,
  setFilteredScreenersAction,
  clearFiltersScreenersAction
} from './Actions'

import { _set, _uniqBy } from '~/src/Utils/lodash'
import { getFilterData, getScreenersFilterData } from '~/src/Utils/global'
import {
  SCREENS_CALLPUT_MAPPER,
  SCREENS_SEGMENT_MAPPER,
  SORT_BY_OPTION_LIST,
  isEquity
} from '~/src/Constants/SCREENERS'

interface IExchange {
  NSE?: IGroupName
  BSE?: IGroupName
}

interface IGroupName {
  [key: string]: IAssetClass
}
interface IAssetClass {
  [key: string]: ISubCategory
}

interface ISubCategory {
  id: string
  uiConfig: any
  list: string[]
  isError: boolean
}

export interface IScreenerReducer {
  group: {
    [key in string]: IExchange
  }
  expiryDateOptions: any[]

  selectedGroupName: string
  selectedAssetClass: string
  selectedSubCategory: string

  // [selectedExchange] use for filter options
  selectedExchange: string

  // sort and filter options
  selectedSortBy: string
  selectedIndices: string
  selectedExpiryDate: string
  selectedSegment: string
  selectedCallPut: string
  isInitialRender: boolean
  filteredScreeners: any[]
  isDefaultFiltersEnabled: boolean
}

export const DEFAULT_VALUES = {
  selectedExchange: 'NSE',
  selectedGroupName: 'Nifty 50',
  selectedAssetClass: 'STOCKS',
  selectedSubCategory: 'Top Gainers',
  selectedExpiryDate: 'ALL EXPIRY',
  selectedSegment: 'ALL SEGMENTS',
  selectedCallPut: 'ALL OPTIONS',
  filteredScreeners: [],
  selectedSortBy: SORT_BY_OPTION_LIST.LTP_HTOL.value
}

const INITIAL_STATE: IScreenerReducer = {
  group: {},
  expiryDateOptions: [],
  selectedExchange: DEFAULT_VALUES.selectedExchange,
  selectedGroupName: DEFAULT_VALUES.selectedGroupName,
  selectedAssetClass: DEFAULT_VALUES.selectedAssetClass,
  selectedSubCategory: DEFAULT_VALUES.selectedSubCategory,
  selectedExpiryDate: DEFAULT_VALUES.selectedExpiryDate,
  selectedSegment: DEFAULT_VALUES.selectedSegment,
  selectedCallPut: DEFAULT_VALUES.selectedCallPut,
  filteredScreeners: DEFAULT_VALUES.filteredScreeners,
  selectedIndices: '',
  selectedSortBy: DEFAULT_VALUES.selectedSortBy,
  isInitialRender: false,
  isDefaultFiltersEnabled: true
}

type ScreenerIconColorMap = {
  [key: string]: {
    icon: string
    color: string
    label: string
  }
}

// TODO: get this from API itself within uiConfig object - remove
const SCREENER_ICON_COLOR_MAP: ScreenerIconColorMap = {
  'TOP GAINERS': {
    icon: 'ri-arrow-right-up-line',
    color: 'var(--ds-colour-iconPositive)',
    label: 'Top Gainers'
  },
  'TOP LOSERS': {
    icon: 'ri-arrow-left-down-line',
    color: 'var(--ds-colour-iconNegative)',
    label: 'Top Losers'
  },
  'ACTIVE BY VOLUME': {
    icon: 'ri-bar-chart-fill',
    color: 'var(--ds-colour-iconDefault)',
    label: 'Active by Volume'
  },
  'MOST ACTIVE': {
    icon: 'ri-bar-chart-fill',
    color: 'var(--ds-colour-iconDefault)',
    label: 'Most Active'
  },
  'OPEN INTEREST': {
    icon: 'ri-bar-chart-fill',
    color: 'var(--ds-colour-iconDefault)',
    label: 'Open Interest'
  },
  DEFAULT: {
    icon: 'ri-bar-chart-fill',
    color: 'var(--ds-colour-iconDefault)',
    label: 'Open Interest'
  }
}
const ASSETS_EXCHANGE_MAPPER: any = {
  STOCKS: 'NSE',
  FUTURES: 'NSE',
  OPTIONS: 'NSE',
  'CURR-FUT': 'NSE',
  'CURR-OPT': 'NSE',
  'COMM-FUT': 'MCX',
  'COMM-OPT': 'MCX'
}
const EXCHANGE_GROUPNAME: any = {
  STOCKS_NSE: 'Nifty 50',
  STOCKS_BSE: 'BSE 100'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(setSelectedAssetClassAction, (state, { payload }) => {
      const { selectedExchange, selectedGroupName, selectedSubCategory } = state
      const selectedAssetClass = payload
      const exchangePath = `${selectedAssetClass}.${selectedExchange}`
      state.selectedAssetClass = selectedAssetClass
      if (!isPathAvailable(state.group, exchangePath)) {
        state.selectedExchange = ASSETS_EXCHANGE_MAPPER[selectedAssetClass]
      }
      const groupPath = `${selectedAssetClass}.${state.selectedExchange}.${selectedGroupName}`
      if (!isPathAvailable(state.group, groupPath)) {
        state.selectedGroupName =
          EXCHANGE_GROUPNAME[`${selectedAssetClass}_${state.selectedExchange}`]
      }
      const subCategoryPath = isEquity(selectedAssetClass)
        ? `${selectedAssetClass}.${state.selectedExchange}.${state.selectedGroupName}.${selectedSubCategory}`
        : `${selectedAssetClass}.${state.selectedExchange}.${selectedSubCategory}`
      if (!isPathAvailable(state.group, subCategoryPath)) {
        state.selectedSubCategory = DEFAULT_VALUES.selectedSubCategory
      }
    })
    builder.addCase(setSelectedExchangeAction, (state, { payload }) => {
      const { selectedGroupName, selectedAssetClass, selectedSubCategory } =
        state
      const selectedExchange = payload
      state.selectedExchange = selectedExchange
      const groupPath = `${selectedAssetClass}.${state.selectedExchange}.${selectedGroupName}`
      if (!isPathAvailable(state.group, groupPath)) {
        state.selectedGroupName =
          EXCHANGE_GROUPNAME[`${selectedAssetClass}_${state.selectedExchange}`]
      }
      const subCategoryPath = isEquity(selectedAssetClass)
        ? `${selectedAssetClass}.${state.selectedExchange}.${state.selectedGroupName}.${selectedSubCategory}`
        : `${selectedAssetClass}.${state.selectedExchange}.${selectedSubCategory}`
      if (!isPathAvailable(state.group, subCategoryPath)) {
        state.selectedSubCategory = DEFAULT_VALUES.selectedSubCategory
      }
      // TODO: setting to default value if defined/search path is not found
      // should set the first key available instead of default
      // change this later
    })
    builder.addCase(setSelectedGroupNameAction, (state, { payload }) => {
      const { selectedExchange, selectedAssetClass, selectedSubCategory } =
        state
      const selectedGroupName = payload
      const path = isEquity(selectedAssetClass)
        ? `${selectedAssetClass}.${selectedExchange}.${selectedGroupName}.${selectedSubCategory}`
        : `${selectedAssetClass}.${selectedExchange}.${selectedGroupName}`
      state.selectedGroupName = selectedGroupName
      if (!isPathAvailable(state.group, path)) {
        state.selectedAssetClass = DEFAULT_VALUES.selectedAssetClass
        state.selectedSubCategory = DEFAULT_VALUES.selectedSubCategory
      }
    })
    builder.addCase(setSelectedSubCategoryAction, (state, { payload }) => {
      state.selectedSubCategory = payload

      if (payload === 'Top Gainers') {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_HTOL.value
      } else if (payload === 'Top Losers') {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_LTOH.value
      } else {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_HTOL.value
      }
    })
    builder.addCase(selectedSegmentAction, (state, { payload }) => {
      state.selectedSegment = payload
    })
    builder.addCase(selectedExpiryDateAction, (state, { payload }) => {
      state.selectedExpiryDate = payload
    })
    builder.addCase(setSelectedCallPutAction, (state, { payload }) => {
      state.selectedCallPut = payload
    })
    builder.addCase(getScreenersByIdActions.success, (state, { payload }) => {
      const {
        selectedExchange,
        selectedGroupName,
        selectedAssetClass,
        selectedSubCategory
      } = state
      if (
        state.group[selectedAssetClass] &&
        state.group[selectedAssetClass][selectedExchange]
      ) {
        if (isEquity(selectedAssetClass)) {
          state.group[selectedAssetClass][selectedExchange][selectedGroupName][
            payload.subCategory || selectedSubCategory
          ].list = payload.list
          state.group[selectedAssetClass][selectedExchange][selectedGroupName][
            selectedSubCategory
          ].isError = false
        } else {
          state.group[selectedAssetClass][selectedExchange][
            payload.subCategory || selectedSubCategory
          ].list = payload.list
          state.group[selectedAssetClass][selectedExchange][
            selectedSubCategory
          ].isError = false
          const res: any = []
          // TODO: remove this from here: add additional
          res.push({ label: 'All Expiry', value: 'ALL EXPIRY' })
          payload.list &&
            payload.list.forEach((element: any) => {
              const data: any = (window as any).secMaster.getByScriptId(
                element.scriptId
              )
              data?.expiryDate &&
                res.push({
                  label: data.expiryDate,
                  value: data.expiryDate
                })
            })
          state.expiryDateOptions = _uniqBy(res, (item: any) => item.value)
        }
      }
      const withSecMast: any[] = []
      payload.list.forEach((d: any) => {
        let data = (window as any).secMaster.getByScriptId(d.scriptId)
        if (data) {
          withSecMast.push(data)
        }
      })
      state.filteredScreeners = getFilterData(withSecMast, {})

      if (!state.isInitialRender) {
        state.isInitialRender = true
        if (selectedSubCategory === 'Top Gainers') {
          state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_HTOL.value
        } else if (selectedSubCategory === 'Top Losers') {
          state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_LTOH.value
        } else {
          state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_HTOL.value
        }
      }
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    })
    builder.addCase(setFilteredScreenersAction, (state, { payload }) => {
      const { selectedAssetClass, selectedSubCategory } = state

      // passed values
      const {
        selectedCallPut: passedSelectedCallPut,
        selectedExpiryDate: passedSelectedExpiryDate,
        selectedSegment: passedSelectedSegment,
        selectedExchange: passedSelectedExchange,
        selectedSortBy: passedSelectedSortBy,
        selectedGroupName: passedSelectedGroupName
      } = payload

      state.selectedCallPut = passedSelectedCallPut
      state.selectedExpiryDate = passedSelectedExpiryDate
      state.selectedSegment = passedSelectedSegment

      state.selectedExchange = passedSelectedExchange
      state.selectedSortBy = passedSelectedSortBy
      state.selectedGroupName = passedSelectedGroupName

      let result = []
      if (isEquity(selectedAssetClass)) {
        result =
          state?.group?.[selectedAssetClass]?.[passedSelectedExchange]?.[
            passedSelectedGroupName
          ]?.[selectedSubCategory].list || []
      } else {
        result =
          state?.group?.[selectedAssetClass]?.[passedSelectedExchange]?.[
            selectedSubCategory
          ].list || []
      }
      const withSecMast: any[] = []
      result.forEach((d: any) => {
        let data = (window as any).secMaster.getByScriptId(d.scriptId)
        if (data) {
          withSecMast.push(data)
        }
      })

      let optionTypeOptions = SCREENS_CALLPUT_MAPPER[passedSelectedCallPut]
      let instrumentTypeOptions =
        SCREENS_SEGMENT_MAPPER?.[passedSelectedSegment]?.[selectedAssetClass]
      let filterOptionObj: any = {
        expiryDate: passedSelectedExpiryDate
      }
      if (instrumentTypeOptions) {
        filterOptionObj.instrumentType = instrumentTypeOptions
      }
      if (optionTypeOptions) {
        filterOptionObj.optionType = optionTypeOptions
      }

      state.filteredScreeners = getScreenersFilterData(
        withSecMast,
        filterOptionObj
      )

      if (
        checkIfDefaultFiltersAreSet({
          selectedSubCategory,
          selectedAssetClass,
          ...payload
        })
      ) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    })
    builder.addCase(getScreenersByIdActions.error, (state, { payload }) => {
      const {
        selectedExchange,
        selectedGroupName,
        selectedAssetClass,
        selectedSubCategory
      } = state
      if (
        state.group[selectedAssetClass] &&
        state.group[selectedAssetClass][selectedExchange]
      ) {
        if (isEquity(selectedAssetClass)) {
          state.group[selectedAssetClass][selectedExchange][selectedGroupName][
            selectedSubCategory
          ].isError = true
        } else {
          state.group[selectedAssetClass][selectedExchange][
            selectedSubCategory
          ].isError = true
        }
      }

      state.filteredScreeners = []

      if (selectedSubCategory === 'Top Gainers') {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_HTOL.value
      } else if (selectedSubCategory === 'Top Losers') {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_LTOH.value
      } else {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_HTOL.value
      }
    })
    builder.addCase(
      getScreenersActiveConfigActions.success,
      (state, { payload }) => {
        const groupedResult = payload.reduce((group: any, config: any) => {
          const { uiConfig = {}, exchange = '' } = config
          const {
            groupNameDescription = '',
            // assetClassDescription = '',
            assetClassName = '',
            subCategoryName = ''
          } = uiConfig
          _set(
            group,
            isEquity(assetClassName)
              ? `${assetClassName}.${exchange}.${groupNameDescription}.${subCategoryName}`
              : `${assetClassName}.${exchange}.${subCategoryName}`,
            {
              ...config,
              list: [],
              isError: false
            }
          )

          return group
        }, {})

        state.group = groupedResult
      }
    )
    builder.addCase(clearFiltersScreenersAction, state => {
      const { selectedSubCategory } = state
      state.selectedExchange = DEFAULT_VALUES.selectedExchange
      state.selectedGroupName = DEFAULT_VALUES.selectedGroupName
      state.selectedExpiryDate = DEFAULT_VALUES.selectedExpiryDate
      state.selectedSegment = DEFAULT_VALUES.selectedSegment
      state.selectedCallPut = DEFAULT_VALUES.selectedCallPut
      if (selectedSubCategory === 'Top Gainers') {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_HTOL.value
      } else if (selectedSubCategory === 'Top Losers') {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_PERCENT_LTOH.value
      } else {
        state.selectedSortBy = SORT_BY_OPTION_LIST.LTP_HTOL.value
      }

      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer

type TNestedObject = { [key: string]: TNestedObject } | any

function isPathAvailable(obj: TNestedObject, path: string): boolean {
  if (!obj || typeof obj !== 'object' || !path || typeof path !== 'string') {
    // Handle invalid input
    return false
  }

  const keys: string[] = path.split('.')

  return keys.every(key => {
    if (obj && typeof obj === 'object' && key in obj) {
      obj = obj[key]
      return true
    }
    return false
  })
}

function checkIfDefaultFiltersAreSet(state: any) {
  const {
    selectedSubCategory,
    selectedExchange,
    selectedGroupName,
    selectedExpiryDate,
    selectedSegment,
    selectedCallPut,
    selectedSortBy,
    selectedAssetClass
  } = state
  const isDefaultGroupName = isEquity(selectedAssetClass)
    ? selectedGroupName === DEFAULT_VALUES.selectedGroupName
    : true
  if (
    selectedExchange === DEFAULT_VALUES.selectedExchange &&
    isDefaultGroupName &&
    selectedExpiryDate === DEFAULT_VALUES.selectedExpiryDate &&
    selectedSegment === DEFAULT_VALUES.selectedSegment &&
    selectedCallPut === DEFAULT_VALUES.selectedCallPut &&
    ((selectedSubCategory === 'Top Gainers' &&
      selectedSortBy === SORT_BY_OPTION_LIST.LTP_PERCENT_HTOL.value) ||
      (selectedSubCategory === 'Top Losers' &&
        selectedSortBy === SORT_BY_OPTION_LIST.LTP_PERCENT_LTOH.value) ||
      selectedSortBy === SORT_BY_OPTION_LIST.LTP_HTOL.value)
  ) {
    return true
  }

  return false
}
