import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsStack,
  DsRemixIcon,
  DsPaper,
  DsToggleButton,
  DsToggleButtonGroup,
  styled
} from '@am92/react-design-system'
import {
  getOrderBuyOrSell,
  isModifyOrderRequest
} from '~/src/Redux/Orders/selectors'
import {
  resetOrderData,
  switchBuySellButton,
  updateExchangeSelectorIndicator,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Pages/Orderbook/Orderbook.Page'
import { resetEmarginData } from '~/src/Redux/Customer/Reducer'

type ActionTypes = {
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  switchBuySellButton: (requestData: string) => Promise<any>
  resetOrderData: (requestData: string) => Promise<any>
  resetEmarginData: (requestData: string) => Promise<any>
  updateExchangeSelectorIndicator: (data: any) => void
}

export interface IOrderHeaderTopProps extends IWithRouterProps {
  actions: ActionTypes
  orderBuyOrSell: string
  isModifyOrder: boolean
}

class OrderHeaderTop extends React.Component<IOrderHeaderTopProps> {
  handleCloseOrderForm = async () => {
    const { actions } = this.props
    await actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: false,
      orderScriptId: '',
      orderBuyOrSell: '',
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
    await actions.resetOrderData('resetOrderData')
    await actions.resetEmarginData('reset')
    actions.updateExchangeSelectorIndicator({
      exchangeSelectorIndicator: false,
      orderScriptId: ''
    })
  }

  handleSwitch = (event: React.MouseEvent<HTMLElement>, value: string) => {
    const { actions } = this.props
    value && actions.switchBuySellButton(value)
  }

  render() {
    const { orderBuyOrSell, isModifyOrder } = this.props
    const isBUY = orderBuyOrSell === 'BUY'
    const isSELL = orderBuyOrSell === 'SELL'
    const StyledToggleButtonGroup = styled(DsToggleButtonGroup)(
      ({ theme }) => ({
        height: 'var(--ds-spacing-warm)',
        '& .MuiToggleButtonGroup-grouped': {
          margin: theme.spacing(0.5),
          border: 0,
          '&:not(:first-of-type)': {
            borderRadius: 'var(--ds-radius-quickFreeze)',
            backgroundColor: isSELL
              ? 'var(--ds-colour-supportNegative)'
              : 'var(--ds-colour-surfaceBackground)',
            color: isSELL
              ? 'var(--ds-colour-surfaceBackground)'
              : 'var(--ds-colour-typoPrimary)'
          },
          '&:first-of-type': {
            borderRadius: 'var(--ds-radius-quickFreeze)',
            backgroundColor: isBUY
              ? 'var(--ds-colour-supportPositive)'
              : 'var(--ds-colour-surfaceBackground)',
            color: isBUY
              ? 'var(--ds-colour-surfaceBackground)'
              : 'var(--ds-colour-typoPrimary)',
            '&:hover': {
              backgroundColor: isBUY
                ? 'var(--ds-colour-supportPositive)'
                : 'var(--ds-colour-surfaceBackground)'
            }
          }
        }
      })
    )
    const onlyShowBuyButton = isModifyOrder && isBUY
    const onlyShowSellButton = isModifyOrder && isSELL
    const showBothButtons = !onlyShowBuyButton && !onlyShowSellButton

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 'var(--ds-spacing-deepFreeze)',
            marginLeft: '-5px'
          }}
        >
          <DsRemixIcon
            onClick={this.handleCloseOrderForm}
            className='ri-close-fill'
            fontSize='mild'
            sx={{
              cursor: 'pointer'
            }}
          />
          <DsPaper
            elevation={0}
            sx={{
              display: 'flex',
              border: theme => `1px solid ${theme.palette.divider}`,
              flexWrap: 'wrap',
              borderRadius:
                'calc(var(--ds-radius-quickFreeze) + var(--ds-radius-deepFreeze))'
            }}
          >
            {showBothButtons ? (
              <StyledToggleButtonGroup
                size='small'
                value={orderBuyOrSell}
                exclusive
                onChange={this.handleSwitch}
                aria-label='text alignment'
                sx={{
                  height: 'var(--ds-spacing-pleasant)'
                }}
              >
                <DsToggleButton value='BUY' aria-label='left aligned'>
                  BUY
                </DsToggleButton>
                <DsToggleButton value='SELL' aria-label='centered'>
                  SELL
                </DsToggleButton>
              </StyledToggleButtonGroup>
            ) : onlyShowBuyButton ? (
              <StyledToggleButtonGroup
                size='small'
                value={orderBuyOrSell}
                exclusive
                onChange={this.handleSwitch}
                aria-label='text alignment'
                sx={{
                  height: 'var(--ds-spacing-pleasant)'
                }}
              >
                <DsToggleButton value='BUY' aria-label='left aligned'>
                  BUY
                </DsToggleButton>
              </StyledToggleButtonGroup>
            ) : (
              <StyledToggleButtonGroup
                size='small'
                value={orderBuyOrSell}
                exclusive
                onChange={this.handleSwitch}
                aria-label='text alignment'
                sx={{
                  height: 'var(--ds-spacing-pleasant)'
                }}
              >
                <DsToggleButton value='SELL' aria-label='centered'>
                  SELL
                </DsToggleButton>
              </StyledToggleButtonGroup>
            )}
          </DsPaper>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderBuyOrSell = getOrderBuyOrSell(state)
  const isModifyOrder = isModifyOrderRequest(state)

  return {
    orderBuyOrSell,
    isModifyOrder
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    switchBuySellButton: (requestData: string) =>
      dispatch(switchBuySellButton(requestData)),
    resetOrderData: (requestData: any) => dispatch(resetOrderData(requestData)),
    resetEmarginData: (requestData: string) =>
      dispatch(resetEmarginData(requestData)),
    updateExchangeSelectorIndicator: (requestData: any) =>
      dispatch(updateExchangeSelectorIndicator(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHeaderTop)
