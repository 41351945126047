import {
  APP_TO_WORKER_ACTIONS,
  DEFAULT_WSS_URL,
  T_ONERROR_CTX,
  T_ONMESSAGE_CTX,
  T_WORKER_TO_APP_ACTIONS_TYPES,
  WORKER_TO_APP_ACTIONS,
  BINARY,
  T_LOGIN_DATA,
  T_STOCKDATA
} from './ODIN_WORKER_CONSTANT'
import AppStore from '../../Configurations/AppStore'
import { updateOdinConnectionIndicatorAction } from '../../Redux/Indicators/Actions'
import { T_SCRIPTS_OBJ } from '~/src/Redux/WatchList/Reducer'
import { updateStockMasterAction } from '~/src/Redux/StockMaster/Actions'
import { updateOldStockTickerAction } from '~/src/Redux/StockTicker/Actions'
import TickerStore from '~/src/Configurations/TickerStore'

class OdinWorkerManagerClass {
  worker: any
  userCallbacks: {
    onMessageCtxNFunc: T_ONMESSAGE_CTX
    onErrorCtxNFunc: T_ONERROR_CTX
  }

  constructor(
    onMessageCtxNFunc: T_ONMESSAGE_CTX,
    onErrorCtxNFunc: T_ONERROR_CTX
  ) {
    if (!!window.Worker) {
      this.worker = new Worker(new URL('./OdinWorker.ts', import.meta.url), {
        type: 'module'
      })
      this.worker.onerror = (e: MessageEvent) => this.onError(e)
      this.worker.onmessage = (e: MessageEvent) => this.onMessage(e)
      this.userCallbacks = {
        onMessageCtxNFunc,
        onErrorCtxNFunc
      }
    } else {
      throw new Error(
        'WebWorker not supported by browser. Please use an updated browser.'
      )
    }
  }

  postMessage(data = {}) {
    this.worker.postMessage(data)
  }

  socketInitiate(loginData: T_LOGIN_DATA) {
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_INITIALIZE,
      payload: {
        socketURL: DEFAULT_WSS_URL,
        binaryType: BINARY,
        loginData
      }
    })
  }

  subscribeStocks(scriptsArray: T_SCRIPTS_OBJ[] | any[]) {
    const scriptsDetailedArray: T_STOCKDATA[] = []

    scriptsArray.forEach((scriptObj: T_SCRIPTS_OBJ | any) => {
      const { scriptId } = scriptObj
      if (scriptId) {
        const {
          coname,
          exchange,
          odinTokenId,
          segment,
          exchangeSymbol,
          closeprice,
          maxSingleOrderQty,
          ticksize,
          lotsize,
          isinCode,
          instrumentType,
          asm_flag,
          aslAllowed,
          optionType,
          expiryDate,
          assetClass,
          yesterdayOpenInt,
          ODIN_LLFC_SEGMENTID,
          underlying,
          CMOTS_COCODE,
          DPRLOW,
          DPRHIGH,
          FIFTY_TWO_WEEK_LOW,
          FIFTY_TWO_WEEK_HIGH
        } = (window as any).secMaster.getByScriptId(scriptId) || {}

        if (odinTokenId) {
          const scriptData = {
            scriptId,
            coname,
            exchange,
            odinTokenId,
            segment,
            exchangeSymbol,
            closeprice,
            maxSingleOrderQty,
            ticksize,
            lotsize,
            isinCode,
            instrumentType,
            asm_flag,
            aslAllowed,
            optionType,
            expiryDate,
            assetClass,
            yesterdayOpenInt,
            ODIN_LLFC_SEGMENTID,
            underlying,
            CMOTS_COCODE,
            DPRLOW,
            DPRHIGH,
            FIFTY_TWO_WEEK_LOW,
            FIFTY_TWO_WEEK_HIGH
          }
          scriptsDetailedArray.push(scriptData)
        }
      }
    })

    AppStore.dispatch(updateStockMasterAction(scriptsDetailedArray))
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_SUBSCRIBE,
      payload: scriptsDetailedArray
    })
  }

  unSubscribeStocks(scriptsArray: any[]) {
    this.postMessage({
      action: APP_TO_WORKER_ACTIONS.WEB_SOCKET_UNSUBSCRIBE,
      payload: scriptsArray
    })
  }

  terminate() {
    this.worker.closeWebSocket()
    this.worker.terminate()
    this.worker = null
  }

  onError = (event: MessageEvent) => {
    console.log(
      'There is an error with the dedicated worker thread of Order Table',
      event
    )

    this.userCallbacks.onErrorCtxNFunc.func.apply(
      this.userCallbacks.onErrorCtxNFunc.ctx,
      [event]
    )
  }

  onMessage = (event: MessageEvent) => {
    this.userCallbacks.onMessageCtxNFunc.func.apply(
      this.userCallbacks.onMessageCtxNFunc.ctx,
      [event]
    )
  }
}

const workerOnMessageHandler = (
  workerData: MessageEvent<{ action: T_WORKER_TO_APP_ACTIONS_TYPES; data: any }>
) => {
  const { action, data } = workerData.data
  switch (action) {
    case WORKER_TO_APP_ACTIONS.WEB_SOCKET_ONOPEN:
      AppStore.dispatch(updateOdinConnectionIndicatorAction(true))
      break
    case WORKER_TO_APP_ACTIONS.ODIN_DATA_RECEIVED:
      TickerStore.dispatch(updateOldStockTickerAction(data))
      break
    default:
      break
  }
}

const errorOnMessageHandler = () => {
  console.log('error')
}

export const odinWorkerManager = new OdinWorkerManagerClass(
  {
    func: workerOnMessageHandler,
    ctx: this
  },
  {
    func: errorOnMessageHandler,
    ctx: this
  }
)
