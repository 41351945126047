import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import {
  getNotificationsActions,
  getNotificationsServiceName
} from '../Actions'

export type T_GET_NOTIFICATIONS_PAYLOAD = {
  category?: string
}

async function getNotifications(payload: T_GET_NOTIFICATIONS_PAYLOAD) {
  const options = {
    url: `/notification/get`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getNotificationsAction = serviceActionCreator(
  getNotificationsActions,
  getNotifications
)

export default getNotificationsAction
export { getNotificationsServiceName }
