import React, { Component } from 'react'
import { DsDrawer } from '@am92/react-design-system'

import LTPTicker from '../SortingTable/LTPTicker'
import AlertsForm from './AlertsForm'
import { T_ALERT_FORM_DETAILS } from '~/src/Redux/Alerts/Reducer'

interface IAlertsFormModalProps {
  alertFormDetails: T_ALERT_FORM_DETAILS
}

export class AlertsFormModal extends Component<IAlertsFormModalProps> {
  render() {
    const { alertFormDetails } = this.props
    const { toggle } = alertFormDetails
    return (
      <DsDrawer
        PaperProps={{
          sx: {
            top: {
              md: '5.3rem',
              lg: '5.4rem',
              xs: '0%'
            },
            height: {
              md: 'calc(100% - 4.2rem)',
              lg: 'calc(100% - 5.4rem)',
              xs: '100%'
            },
            width: { md: 'auto', sm: '100%' },
            boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)'
          }
        }}
        keepMounted={false}
        sx={theme => ({ position: 'absolute', zIndex: theme.zIndex.drawer })}
        anchor='right'
        open={toggle}
        variant='persistent'
        hideBackdrop
      >
        <AlertsForm
          alertFormDetails={alertFormDetails}
          LTP={<LTPTicker scriptId={alertFormDetails.scriptId} />}
        />
      </DsDrawer>
    )
  }
}

export default AlertsFormModal
