import React, { ReactNode } from 'react'
import {
  DsBox,
  DsSkeleton,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

class PartnersSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <DsBox
        sx={{
          backgroundColor: 'var(--ds-colour-surfaceSecondary)',
          mb: 'var(--ds-spacing-bitterCold)'
        }}
      >
        <DsTypography
          sx={{ display: { xs: 'none', md: 'flex' } }}
          variant='headingBoldLarge'
        >
          Partner Apps
        </DsTypography>
        <DsStack
          spacing={'var(--ds-spacing-warm)'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 'var(--ds-spacing-zero)', md: 'var(--ds-spacing-mild)' },
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            margin: {
              xs: 'var(--ds-spacing-zero)',
              md: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero)'
            },
            padding: { md: 'var(--ds-spacing-mild)' }
          }}
        >
          <DsStack
            spacing={'var(--ds-spacing-cool)'}
            sx={{
              width: '30%',
              flexDirection: 'row',
              gap: 'var(--ds-spacing-bitterCold)'
            }}
          >
            <DsSkeleton variant='rounded' sx={{ height: '10px' }} />
            <DsSkeleton variant='rounded' sx={{ height: '10px' }} />
          </DsStack>
          <DsStack
            spacing={'var(--ds-spacing-cool)'}
            sx={{
              width: '100%',
              flexDirection: 'row',
              gap: 'var(--ds-spacing-bitterCold)'
            }}
          >
            <DsSkeleton variant='rounded' sx={{ height: '80px' }} />
          </DsStack>
        </DsStack>
      </DsBox>
    )
  }
}

export default PartnersSkeleton
