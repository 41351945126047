import React from 'react'
// DS Components
import {
  DsBox,
  DsRemixIcon,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import { TView } from '~/src/Pages/Quotes/Quotes.Types'

// Lib
export interface IEventsContainerTabProps
  extends IWithRouterProps,
    IwithBreakpoints {
  renderTabs: any
  returnValue: string
  onChange: (returnValue: string) => void
  enableArrow?: boolean
  containedTabs: boolean
  view?: TView
}

class EventsContainerTab extends React.Component<IEventsContainerTabProps> {
  ref: React.RefObject<any>
  scrollPosition: number

  constructor(props: any) {
    super(props)
    this.ref = React.createRef()
    this.scrollPosition = 0
  }

  state = {
    selectedTab: this.props.renderTabs[0].value,
    leftScroll: false,
    rightScroll: true,
    scrollPosition: 0
  }

  componentDidUpdate(prevProps: Readonly<IEventsContainerTabProps>): void {
    const { params, renderTabs } = this.props
    if (prevProps.params !== params) {
      this.handleSelec(renderTabs[0].value)
    }
  }
  handleScroll = () => {
    const section = this.ref.current

    if (section) {
      const newScrollPosition = section.scrollLeft + section.clientWidth + 10
      this.setState({ scrollPosition: newScrollPosition })
      this.scrollPosition = newScrollPosition
    }
    if (this.scrollPosition > section?.scrollWidth) {
      this.setState({ rightScroll: false })
    }
    if (this.scrollPosition < section?.scrollWidth) {
      this.setState({ rightScroll: true })
    }
    if (this.scrollPosition - section.clientWidth - 10 < 15) {
      this.setState({ leftScroll: false })
    }
    if (this.scrollPosition - section.clientWidth - 10 > 15) {
      this.setState({ leftScroll: true })
    }
  }
  handleScrollLeft = () => {
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft -= 110
    }
  }

  handleScrollRight = () => {
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft += 100
    }
  }

  handleSelec = (value: string) => {
    const { onChange } = this.props
    this.setState({ selectedTab: value })
    onChange(value)
  }

  handleTabChange = (event: any, newvalue: number) => {
    this.setState({ selectedTab: newvalue })
  }

  render() {
    const {
      renderTabs,
      enableArrow = true,
      breakpoints = {},
      view
    } = this.props
    const { selectedTab, rightScroll, leftScroll } = this.state

    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <DsBox
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            paddingLeft:
              view === 'detailedView'
                ? 'var(--ds-spacing-bitterCold)'
                : 'var(--ds-spacing-zero)'
          }}
        >
          {enableArrow && leftScroll && isDesktop && (
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                height:
                  'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                marginLeft:
                  'calc (-1 * (var(--ds-spacing-quickFreeze) + var(--ds-spacing-deepFreeze)))',
                cursor: 'pointer'
              }}
              onClick={this.handleScrollLeft}
            >
              <DsRemixIcon className='ri-arrow-left-s-line' fontSize='mild' />
            </DsBox>
          )}
          <DsBox
            id='scrollEventsContainer'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 'var(--ds-spacing-bitterCold)',
              overflowX: 'auto',
              whiteSpace: 'pre',
              scrollBehavior: 'smooth',
              '&::-webkit-scrollbar': { display: 'none' }
            }}
            ref={this.ref}
            onScroll={() => this.handleScroll()}
          >
            {renderTabs.map((item: any, index: any) => {
              return (
                <DsBox
                  key={index}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'var(--ds-colour-primary)',
                    backgroundColor: `${
                      selectedTab === item.value
                        ? 'var(--ds-colour-actionSecondary)'
                        : 'var(--ds-colour-surfacePrimary)'
                    }`,
                    borderRadius: 'var(--ds-radius-bitterCold)',
                    p: 'var(--ds-spacing-quickFreeze) var(--ds-spacing-frostbite)',
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    cursor: 'pointer'
                  }}
                  onClick={() => this.handleSelec(item.value)}
                >
                  <DsTypography
                    textTransform='capitalize'
                    variant='bodyRegularMedium'
                    color={
                      selectedTab === item.value
                        ? 'var(--ds-colour-surfaceBackground)'
                        : 'var(--ds-colour-typoPrimary)'
                    }
                  >
                    {item.label}
                  </DsTypography>
                </DsBox>
              )
            })}
          </DsBox>
          {enableArrow && rightScroll && isDesktop && (
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                height:
                  'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                pl: 'var(--ds-spacing-glacial)',
                cursor: 'pointer'
              }}
              onClick={this.handleScrollRight}
            >
              <DsRemixIcon className='ri-arrow-right-s-line' fontSize='mild' />
            </DsBox>
          )}
        </DsBox>
      </>
    )
  }
}

export default withBreakpoints(withRouter(EventsContainerTab))
