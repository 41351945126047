import { asHttp } from '~/src/Configurations/WebHttp'

import { getSurveillanceScriptsErrorsActions } from '../../Indicators/Actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

async function getSurveillanceScriptsErrors() {
  const options = {
    url: `/configs?platform=web`,
    method: 'POST',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getSurveillanceScriptsErrorsAction = serviceActionCreator(
  getSurveillanceScriptsErrorsActions,
  getSurveillanceScriptsErrors
)

export default getSurveillanceScriptsErrorsAction
