import { DsTypography } from '@am92/react-design-system'
import React from 'react'
import { TAppStore, TAppDispatch } from '~/src/Configurations/AppStore'
import { tickerContext } from '~/src/Configurations/TickerStore'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { TSipIndiInfo, TSipInfo } from '~/src/Redux/Orders/reducer'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'
import { getCurrencyFormat } from '~/src/Utils/global'

interface SipScriptsDetailsAmtValueProps {
  updateSelectedScriptsData: (item: any) => any
  item: TSipIndiInfo
  sipInfo: TSipInfo
  value: number
  stockPriceDetails: ITicker
  isModifing: boolean
}

class SipScriptsDetailsAmtValue extends React.Component<SipScriptsDetailsAmtValueProps> {
  async componentDidUpdate(
    prevProps: Readonly<SipScriptsDetailsAmtValueProps>
  ): Promise<void> {
    const { value, item } = this.props
    if (prevProps.value !== value) {
      const {
        sipInfo,
        stockPriceDetails,
        isModifing,
        updateSelectedScriptsData
      } = this.props
      const { basedOn } = sipInfo
      const { scriptId } = item
      const { LTP = 0 } = stockPriceDetails

      const formattedBasedOn =
        basedOn.toLowerCase() === 'quantity' ? 'quantity' : 'amount'
      const formattedSecondValue =
        formattedBasedOn === 'amount' ? 'quantity' : 'amount'

      const finalValue =
        formattedSecondValue === 'quantity'
          ? Number(value) / LTP
          : LTP * Number(value)

      let formattedData = {}

      if (isModifing) {
        formattedData = {
          [formattedBasedOn]: value,
          [formattedSecondValue]: finalValue
        }
      } else {
        formattedData = {
          [formattedBasedOn]: value,
          [formattedSecondValue]: finalValue
        }
      }

      await updateSelectedScriptsData({ scriptId, formattedData })
    }
  }
  render() {
    const { value, sipInfo, stockPriceDetails = {}, item } = this.props
    const { basedOn } = sipInfo
    const { LTP = 0, CLOSE_PRICE } = stockPriceDetails

    const formattedValue = LTP === '0.00' || LTP === '0' ? CLOSE_PRICE : LTP

    const normalizedBasedOn = basedOn.toLowerCase()

    const finalValue =
      normalizedBasedOn === 'amount'
        ? getCurrencyFormat(
            Math.floor(Number(value) / Number(formattedValue)),
            { style: 'decimal' }
          )
        : getCurrencyFormat(
            Math.floor(Number(formattedValue) * Number(value)),
            { style: 'decimal' }
          ) || 0

    return (
      <>
        <DsTypography
          sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          variant='bodyRegularSmall'
        >
          {finalValue}
        </DsTypography>
      </>
    )
  }
}
const mapStateToProps = (state: TAppStore, ownProps: any) => {
  const { item } = ownProps
  const stockPriceDetails: ITicker = getTickerData(item.scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(SipScriptsDetailsAmtValue)
