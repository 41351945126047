export const DsSelectDefaultProps = {
    options: [],
    variant: 'filled',
    defaultValue: '',
    MenuProps: {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
        }
    }
};
