import { createSelector } from '@reduxjs/toolkit'
import { getPrimaryDpAccount } from '~/src/Utils/global'

export const SLICE_NAME = 'customer'
const select = (state: any) => state[SLICE_NAME]

export const getCustomerReducer = (state: any) => {
  return state[SLICE_NAME]
}

//get bank details
export const getCustomerProfileSelector = createSelector(
  (state: { [x: string]: { customerProfile: any } }) =>
    state[SLICE_NAME].customerProfile,
  customerProfile => customerProfile
)

export const isNSEFOEnabled = createSelector(
  (state: { [x: string]: { customerProfile: any } }) =>
    state[SLICE_NAME].customerProfile,
  customerProfile => customerProfile.segmentsEnabled?.nseFO
)

export const bankAccountDetailsSelect = (state: any) =>
  state[SLICE_NAME].customerProfile?.bankAccountDetails

export const currentActiveBankAccountSelector = createSelector(
  bankAccountDetailsSelect,
  bankAccountDetails => _getActiveBankAccount(bankAccountDetails)
)
const _getActiveBankAccount = (data: any) => {
  return data && Object.keys(data).length > 0
    ? data.find((d: { isDefault: boolean }) => {
        return d?.isDefault === true
      })
    : {}
}

// HRT allowed check
export const isHRTAllowed = createSelector(
  currentActiveBankAccountSelector,
  bankAccountDetail => _getIsHRTAllowed(bankAccountDetail)
)
const _getIsHRTAllowed = (data: any) => {
  const { hrtAllowed = false } = data || {}
  return hrtAllowed
}

//POA customer select
export const isThreeInOneCustomerSelector = createSelector(
  currentActiveBankAccountSelector,
  bankAccountDetail => _getIsThreeInOneCustomer(bankAccountDetail)
)
const _getIsThreeInOneCustomer = (data: any) => {
  const { hasPOA = '', hrtAllowed = '' } = data || {}
  return hasPOA === true && hrtAllowed === true
}

// bank balance
export const getAvailableBalanceSelector = createSelector(
  (state: { [x: string]: { bankbalance: any } }) =>
    state[SLICE_NAME].bankbalance,
  bankbalance => bankbalance?.balance?.availableBalance
)

// commodity check
export const isUserHaveCommodity = createSelector(
  (state: { [x: string]: { customerProfile: any } }) =>
    state[SLICE_NAME].customerProfile,
  customerProfile => _getUserCommodityValidation(customerProfile)
)
const _getUserCommodityValidation = (data: any) => {
  const { segmentsEnabled = {} } = data
  const { mcxCmx = '', ncdexCmx = '' } = segmentsEnabled

  if (mcxCmx || ncdexCmx) {
    return true
  }
  return false
}

// spot check
export const checkSpotActiveSelector = createSelector(
  (state: { [x: string]: { customerProfile: any } }) =>
    state[SLICE_NAME].customerProfile,
  customerProfile => customerProfile.productsAllowed?.spot?.isActive
)

export const isEmarginActive = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { emargin = {} } = customerProfile?.productsAllowed || {}
  return emargin?.isActive
})

export const getEMarginDetails = createSelector(select, customer => {
  const { emarginDetails = {} } = customer
  const eMarginPercentage =
    emarginDetails?.marginDetails?.eMarginPercentage || 0
  const isEmarginPreviledged =
    emarginDetails?.commonPrivilegeDetails?.emarginPrivilege || false
  return { eMarginPercentage, isEmarginPreviledged }
})

export const getDpIdAndEdisFlagOfCustomer = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { dpAccountDetails } = customerProfile
  const { dpId, edis } = getPrimaryDpAccount(dpAccountDetails) || {}
  const { isActive } = edis || {}
  return {
    dpId,
    isEdisActive: isActive
  }
})

// dp account details selector
export const DpAccountDetailsSelect = (state: any) =>
  state[SLICE_NAME].customerProfile?.dpAccountDetails

export const currentActiveDpAccountSelector = createSelector(
  DpAccountDetailsSelect,
  dpAccountDetails => _getActiveDpAccount(dpAccountDetails)
)

const _getActiveDpAccount = (data: any) => {
  return data && Object.keys(data).length > 0
    ? data.find((d: { dpDefault: boolean }) => {
        return d?.dpDefault === true
      })
    : {}
}

// partners selectors
export const getPartnertsCategoriesList = createSelector(select, partners =>
  Object.keys(partners.partners)
)

export const getPartnersList = createSelector(
  select,
  partners => partners.formattedPartners
)

export const partners = createSelector(select, partners => partners.partners)

export const getResearchPartnersList = createSelector(
  select,
  partners => partners.researchPartners
)

//reports selector
export const getReportsList = createSelector(select, data => data.reports)

export const selectedPartner = createSelector(
  select,
  data => data?.selectedPartner
)

export const openPartnerSearch = createSelector(
  select,
  data => data?.openPartnerSearch
)
export const partnerSearchString = createSelector(
  select,
  data => data?.searchString
)
export const fundsSegment = createSelector(
  select,
  data => data?.selectedFundsSegment
)

// gtd and sip allowed selector

export const isGTDAllowedSelector = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { productsAllowed } = customerProfile
  const { gtd } = productsAllowed || {}
  const { isActive } = gtd || {}
  return isActive
})

export const isSIPAllowedSelector = createSelector(select, customer => {
  const { customerProfile = {} } = customer
  const { productsAllowed } = customerProfile
  const { sip } = productsAllowed || {}
  const { isActive } = sip || {}
  return isActive
})
