// NOTE - CLEANUP: @pratik
// 1. DsPopup - sx is not props in DsPopup
// 2. split the component - JSX part

import React, { Component } from 'react'
// Lib
// Ds Components
import {
  DsBox,
  DsImage,
  DsModal,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

// Constants
import { BNPL, BULB } from '~/src/Constants/ASSET_MAP'
import { EMARGIN_LABEL } from '~/src/Constants/LABEL'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'

export interface IEmarginInfoModalProps
  extends IWithRouterProps,
    IwithBreakpoints {
  showEmarginInfoModal: boolean
  setShowEmarginInfoModal: () => void
}

class EmarginInfoModal extends Component<IEmarginInfoModalProps> {
  state = { checked: false }

  render() {
    const { showEmarginInfoModal, setShowEmarginInfoModal, breakpoints } =
      this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        <DsPopup
          showClose={true}
          primaryButtonText='Okay'
          primaryButtonProps={{
            onClick: setShowEmarginInfoModal
          }}
          open={isDesktop && showEmarginInfoModal}
          onClose={setShowEmarginInfoModal}
          sx={{
            '.MuiDialogContent-root': { marginTop: 'var(--ds-spacing-zero)' }
          }}
          // DsDialogProps={{
          //   open: isDesktop && showEmarginInfoModal,
          //   onClose: setShowEmarginInfoModal,
          //   sx: {
          //     '.MuiDialogContent-root': { marginTop: 'var(--ds-spacing-zero)' }
          //   }
          // }}
        >
          <DsStack
            sx={{
              flexDirection: 'column',
              textAlign: 'center'
            }}
          >
            <DsTypography variant='headingBoldLarge'>
              {EMARGIN_LABEL.EMARGIN_INFO.NEXT_STEPS}
            </DsTypography>
            <DsTypography
              variant='bodyRegularMedium'
              color={'text.secondary'}
              sx={{
                mt: 'var(--ds-spacing-glacial)',
                mb: 'var(--ds-spacing-bitterCold)'
              }}
            >
              {EMARGIN_LABEL.EMARGIN_INFO.DESCRIPTION}
            </DsTypography>
          </DsStack>
          <DsStack
            sx={{
              p: 'var(--ds-spacing-bitterCold)',
              border: '1px solid var(--ds-colour-strokeDefault)',
              borderRadius: 'var(--ds-radius-quickFreeze)',
              flexDirection: 'row',
              mb: 'var(--ds-spacing-mild)',
              gap: 'var(--ds-spacing-warm)',
              display: 'flex'
            }}
          >
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-frostbite)',
                flex: 1,
                alignItems: 'flex-start'
              }}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--ds-spacing-frostbite)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <DsStack
                  sx={{
                    borderRadius: 'var(--ds-radius-quickFreeze)',
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    p: 'var(--ds-spacing-glacial)',
                    backgroundColor: 'var(--ds-colour-neutral1)'
                  }}
                >
                  <DsRemixIcon className='ri-link-m' fontSize='bitterCold' />
                </DsStack>
                <DsTypography variant='bodyBoldMedium'>
                  {EMARGIN_LABEL.EMARGIN_INFO.STEP_1}
                </DsTypography>
                <DsTypography
                  variant='supportRegularInfo'
                  sx={{ textAlign: 'center' }}
                  color={'text.secondary'}
                >
                  {EMARGIN_LABEL.EMARGIN_INFO.STEP_1_DESCRIPTION}
                </DsTypography>
              </DsBox>
            </DsBox>
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-frostbite)',
                flex: 1,
                alignItems: 'flex-start'
              }}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--ds-spacing-frostbite)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <DsStack
                  sx={{
                    borderRadius: 'var(--ds-radius-quickFreeze)',
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    p: 'var(--ds-spacing-glacial)',
                    backgroundColor: 'var(--ds-colour-neutral1)'
                  }}
                >
                  <DsRemixIcon
                    className='ri-lock-password-line'
                    fontSize='bitterCold'
                  />
                </DsStack>
                <DsTypography variant='bodyBoldMedium'>
                  {EMARGIN_LABEL.EMARGIN_INFO.STEP_2}
                </DsTypography>
                <DsTypography
                  variant='supportRegularInfo'
                  sx={{ textAlign: 'center' }}
                  color={'text.secondary'}
                >
                  {EMARGIN_LABEL.EMARGIN_INFO.STEP_2_DESCRIPTION}
                </DsTypography>
              </DsBox>
            </DsBox>
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 'var(--ds-spacing-frostbite)',
                flex: 1,
                alignItems: 'flex-start'
              }}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--ds-spacing-frostbite)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <DsStack
                  sx={{
                    borderRadius: 'var(--ds-radius-quickFreeze)',
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    p: 'var(--ds-spacing-glacial)',
                    backgroundColor: 'var(--ds-colour-neutral1)'
                  }}
                >
                  <DsRemixIcon
                    className='ri-check-line'
                    fontSize='bitterCold'
                  />
                </DsStack>
                <DsTypography variant='bodyBoldMedium'>
                  {EMARGIN_LABEL.EMARGIN_INFO.STEP_3}
                </DsTypography>
                <DsTypography
                  variant='supportRegularInfo'
                  sx={{ textAlign: 'center' }}
                  color={'text.secondary'}
                >
                  {EMARGIN_LABEL.EMARGIN_INFO.STEP_3_DESCRIPTION}
                </DsTypography>
              </DsBox>
            </DsBox>
          </DsStack>
          <DsStack
            sx={{
              p: 'var(--ds-spacing-bitterCold)',
              border: '1px solid var(--ds-colour-strokeDefault)',
              borderRadius: 'var(--ds-radius-quickFreeze)',
              flexDirection: 'row',
              width: '100%',
              gap: 'var(--ds-spacing-frostbite)',
              alignItems: 'center'
            }}
          >
            <DsBox>
              <DsImage srcSet={BULB} />
            </DsBox>
            <DsTypography color={'text.secondary'} variant='bodyRegularSmall'>
              {EMARGIN_LABEL.EMARGIN_INFO.TIP}
            </DsTypography>
          </DsStack>
        </DsPopup>

        <DsModal open={!isDesktop && showEmarginInfoModal}>
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: {
                md: 'calc(var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot))',
                xs: '100vw'
              },
              height: {
                md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-superheated) + var(--ds-spacing-superheated) + var(--ds-spacing-superheated) + var(--ds-spacing-superheated) + var(--ds-spacing-superheated))',
                xs: '100vh'
              },
              position: 'absolute',
              top: { md: '50%', xs: '0' },
              left: { md: '50%', xs: '0' },
              transform: { md: 'translate(-50%, -50%)', xs: '' },
              backgroundColor: 'var(--ds-colour-surfaceBackground)',
              animation: 'reverse',
              overflow: 'hidden',
              overflowY: 'scroll',
              p: {
                md: 'var(--ds-spacing-mild)',
                xs: 'var(--ds-spacing-bitterCold)'
              },
              borderRadius: {
                md: 'var(--ds-radius-glacial)',
                xs: 'var(--ds-radius-zero)'
              },
              justifyContent: { md: 'center', xs: 'flex-start' }
            }}
          >
            <DsStack
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                gap: 'var(--ds-spacing-bitterCold)',
                alignItems: 'center',
                backgroundColor: 'var(--ds-colour-surfaceBackground)',
                position: 'sticky',
                top: 0,
                mb: 'var(--ds-spacing-cool)'
              }}
            >
              <DsRemixIcon
                className='ri-close-line'
                fontSize='mild'
                onClick={setShowEmarginInfoModal}
              />
              <DsTypography variant='headingBoldSmall'>
                {EMARGIN_LABEL.EMARGIN_INFO.HEADING}
              </DsTypography>
            </DsStack>
            <DsStack
              sx={{
                alignItems: { md: 'center', xs: 'flex-start' },
                justifyContent: 'center'
              }}
            >
              <DsTypography
                variant='headingBoldLarge'
                sx={{ display: { md: 'flex', xs: 'none' } }}
              >
                {EMARGIN_LABEL.EMARGIN_INFO.NEXT_STEPS}
              </DsTypography>
              <DsTypography
                variant='bodyBoldMedium'
                sx={{ display: { md: 'none', xs: 'flex' } }}
              >
                {EMARGIN_LABEL.EMARGIN_INFO.NEXT_STEPS}
              </DsTypography>
              <DsTypography
                variant='bodyRegularMedium'
                color={'text.secondary'}
                sx={{
                  mt: 'var(--ds-spacing-glacial)',
                  mb: 'var(--ds-spacing-bitterCold)'
                }}
              >
                {EMARGIN_LABEL.EMARGIN_INFO.DESCRIPTION}
              </DsTypography>
            </DsStack>
            <DsBox>
              <DsImage
                srcSet={BNPL}
                style={{
                  width: '100%',
                  marginBottom: 'var(--ds-spacing-mild)',
                  display: isDesktop ? 'none' : 'flex'
                }}
              />
            </DsBox>
            <DsStack
              sx={{
                p: 'var(--ds-spacing-bitterCold)',
                border: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: 'var(--ds-radius-quickFreeze)',
                flexDirection: { md: 'row', xs: 'column' },
                width: '100%',
                mb: 'var(--ds-spacing-mild)',
                gap: 'var(--ds-spacing-warm)',
                display: { md: 'flex', xs: 'none' }
              }}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-frostbite)',
                  flex: 1
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-frostbite)',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      backgroundColor: 'var(--ds-colour-neutral1)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-smartphone-line'
                      fontSize='bitterCold'
                    />
                  </DsStack>
                  <DsTypography variant='bodyBoldMedium'>
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_1}
                  </DsTypography>
                  <DsTypography
                    variant='supportRegularInfo'
                    sx={{ textAlign: 'center' }}
                    color={'text.secondary'}
                  >
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_1_DESCRIPTION}
                  </DsTypography>
                </DsBox>
              </DsBox>
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-frostbite)',
                  flex: 1
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-frostbite)',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      backgroundColor: 'var(--ds-colour-neutral1)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-lock-password-line'
                      fontSize='bitterCold'
                    />
                  </DsStack>
                  <DsTypography variant='bodyBoldMedium'>
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_2}
                  </DsTypography>
                  <DsTypography
                    variant='supportRegularInfo'
                    sx={{ textAlign: 'center' }}
                    color={'text.secondary'}
                  >
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_2_DESCRIPTION}
                  </DsTypography>
                </DsBox>
              </DsBox>
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-frostbite)',
                  flex: 1
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-frostbite)',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      backgroundColor: 'var(--ds-colour-neutral1)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-check-line'
                      fontSize='bitterCold'
                    />
                  </DsStack>
                  <DsTypography variant='bodyBoldMedium'>
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_3}
                  </DsTypography>
                  <DsTypography
                    variant='supportRegularInfo'
                    sx={{ textAlign: 'center' }}
                    color={'text.secondary'}
                  >
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_3_DESCRIPTION}
                  </DsTypography>
                </DsBox>
              </DsBox>
            </DsStack>
            <DsStack
              sx={{
                p: 'var(--ds-spacing-bitterCold)',
                border: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: 'var(--ds-radius-quickFreeze)',
                flexDirection: { md: 'row', xs: 'column' },
                width: '100%',
                mb: 'var(--ds-spacing-mild)',
                display: { md: 'none', xs: 'flex' }
              }}
            >
              <DsStack
                sx={{
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-bitterCold)'
                }}
              >
                <DsStack
                  sx={{
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      backgroundColor: 'var(--ds-colour-neutral1)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-smartphone-line'
                      fontSize='bitterCold'
                    />
                  </DsStack>
                  <DsStack
                    sx={{
                      height: 'calc(var(--ds-spacing-blazing)  )',
                      border: '1px dashed var(--ds-colour-strokeDefault)',
                      width: '1px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 'var(--ds-spacing-glacial)'
                    }}
                  ></DsStack>
                </DsStack>
                <DsStack
                  sx={{
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsTypography variant='bodyBoldMedium'>
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_1}
                  </DsTypography>
                  <DsTypography
                    variant='bodyRegularSmall'
                    color={'text.secondary'}
                  >
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_1_DESCRIPTION}
                  </DsTypography>
                </DsStack>
              </DsStack>
              <DsStack
                sx={{
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-bitterCold)'
                }}
              >
                <DsStack
                  sx={{
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      backgroundColor: 'var(--ds-colour-neutral1)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-lock-password-line'
                      fontSize='bitterCold'
                    />
                  </DsStack>
                  <DsStack
                    sx={{
                      height: 'calc(var(--ds-spacing-blazing)  )',
                      border: '1px dashed var(--ds-colour-strokeDefault)',
                      width: '1px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 'var(--ds-spacing-glacial)'
                    }}
                  ></DsStack>
                </DsStack>
                <DsStack
                  sx={{
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsTypography variant='bodyBoldMedium'>
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_2}
                  </DsTypography>
                  <DsTypography
                    variant='bodyRegularSmall'
                    color={'text.secondary'}
                  >
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_2_DESCRIPTION}
                  </DsTypography>
                </DsStack>
              </DsStack>
              <DsStack
                sx={{
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-bitterCold)'
                }}
              >
                <DsStack
                  sx={{
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsStack
                    sx={{
                      borderRadius: 'var(--ds-radius-quickFreeze)',
                      border: '1px solid var(--ds-colour-strokeDefault)',
                      p: 'var(--ds-spacing-glacial)',
                      backgroundColor: 'var(--ds-colour-neutral1)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-check-line'
                      fontSize='bitterCold'
                    />
                  </DsStack>
                </DsStack>
                <DsStack
                  sx={{
                    flexDirection: 'column',
                    gap: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsTypography variant='bodyBoldMedium'>
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_3}
                  </DsTypography>
                  <DsTypography
                    variant='bodyRegularSmall'
                    color={'text.secondary'}
                  >
                    {EMARGIN_LABEL.EMARGIN_INFO.STEP_3_DESCRIPTION}
                  </DsTypography>
                </DsStack>
              </DsStack>
            </DsStack>
            <DsStack
              sx={{
                p: 'var(--ds-spacing-bitterCold)',
                border: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: 'var(--ds-radius-quickFreeze)',
                flexDirection: 'row',
                width: '100%',
                mb: 'var(--ds-spacing-mild)',
                gap: 'var(--ds-spacing-frostbite)'
              }}
            >
              <DsBox>
                <DsImage srcSet={BULB} />
              </DsBox>
              <DsTypography color={'text.secondary'} variant='bodyRegularSmall'>
                {EMARGIN_LABEL.EMARGIN_INFO.TIP}
              </DsTypography>
            </DsStack>
          </DsBox>
        </DsModal>
      </>
    )
  }
}

export default withBreakpoints(withRouter(EmarginInfoModal))
