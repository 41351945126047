import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { updateOldStockTickerAction, updateStockTickerAction } from './Actions'

export interface IB5 {
  price: number
  quantity: number
  order: number
  pos: number //NOTE - check usage
}
export interface ITicker {
  EXCHANGE?: string
  CMOTS_COCODE?: number
  ODIN_TOKEN_ID?: number
  LTQ?: number //NOTE - check where to use
  TRADE_QTY_ATP?: number //NOTE - check where to use
  NET_CHANGE_1?: number //NOTE - check usage
  TICK_SIZE?: number //NOTE - check usage
  CURRENT_CHANGE?: string //NOTE - check usage
  OPEN_INTEREST_CHANGE?: string //NOTE - check usage
  OPEN_INTEREST_PERCENTAGE_CHANGE?: string //NOTE - check usage
  TOTAL_BID_QTY?: number
  TOTAL_ASK_QTY?: number
  FIFTY_TWO_WEEK_HIGH?: number
  FIFTY_TWO_WEEK_LOW?: number
  UPPER_CIRCUIT?: number
  LOWER_CIRCUIT?: number
  LTP: number
  NET_CHANGE: number
  PERCENT_CHANGE: number
  LTT: string
  B5BUY: IB5[]
  B5SELL: IB5[]
  VOLUME: number
  CLOSE_PRICE: number
  OPEN_PRICE: number
  AVERAGE_TRADE_PRICE: number
  LOW_PRICE: number
  HIGH_PRICE: number
  LIFE_TIME_HIGH: number //NOTE - check usage
  LIFE_TIME_LOW: number //NOTE - check usage
  TOTAL_BUY_QTY: number //NOTE - check usage
  TOTAL_SELL_QTY: number //NOTE - check usage
  DAILY_PRICE_RANGE: number
  OPEN_INTEREST: string
}

export interface TickerStore {
  [key: number | string]: ITicker
}

const INITIAL_STATE: TickerStore = {}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    //updateStockTicker
    builder.addCase(updateStockTickerAction, (state, { payload }) => {
      const [processedData, scriptId] = payload
      const {
        LTP: prevLTP = 0,
        NET_CHANGE: prevNetChange = 0,
        PERCENT_CHANGE: prevPerChange = 0,
        LTT: prevLtt = '',
        B5BUY: prevB5Buy = null,
        B5SELL: prevB5Sell = null,
        VOLUME: prevVolume = 0,
        OPEN_PRICE: prevOpenPrice = 0,
        CLOSE_PRICE: prevClosePrice = 0,
        AVERAGE_TRADE_PRICE: prevAvgPrice = 0,
        LOW_PRICE: prevLowPrice = 0,
        HIGH_PRICE: prevHighPrice = 0,
        LIFE_TIME_HIGH: prevLifeTimeHigh = 0,
        LIFE_TIME_LOW: prevLifeTimeLow = 0,
        FIFTY_TWO_WEEK_LOW: prevFiftyTwoWeekLow = 0,
        FIFTY_TWO_WEEK_HIGH: prevFiftyTwoWeekHigh = 0,
        TOTAL_BUY_QTY: prevTotalBuyQty = 0,
        TOTAL_SELL_QTY: prevTotalSellQty = 0,
        DAILY_PRICE_RANGE: prevDailyPriceRange = {},
        OPEN_INTEREST: prevOpenInterest = '',
        UPPER_CIRCUIT: prevUpperCircuit = 0,
        LOWER_CIRCUIT: prevLowerCircuit = 0,
        TOTAL_BID_QTY: prevTotalBidQty = 0,
        TOTAL_ASK_QTY: prevTotalAskQty = 0
      } = state[scriptId] || {}
      const {
        LTP = prevLTP,
        NET_CHANGE = prevNetChange,
        PERCENT_CHANGE = prevPerChange,
        LTT = prevLtt,
        B5BUY = prevB5Buy,
        B5SELL = prevB5Sell,
        VOLUME = prevVolume,
        OPEN_PRICE = prevOpenPrice,
        CLOSE_PRICE = prevClosePrice,
        AVERAGE_TRADE_PRICE = prevAvgPrice,
        LOW_PRICE = prevLowPrice,
        HIGH_PRICE = prevHighPrice,
        LIFE_TIME_HIGH = prevLifeTimeHigh,
        LIFE_TIME_LOW = prevLifeTimeLow,
        FIFTY_TWO_WEEK_LOW = prevFiftyTwoWeekLow,
        FIFTY_TWO_WEEK_HIGH = prevFiftyTwoWeekHigh,
        TOTAL_BUY_QTY = prevTotalBuyQty,
        TOTAL_SELL_QTY = prevTotalSellQty,
        DAILY_PRICE_RANGE = prevDailyPriceRange,
        OPEN_INTEREST = prevOpenInterest,
        UPPER_CIRCUIT = prevUpperCircuit,
        LOWER_CIRCUIT = prevLowerCircuit,
        TOTAL_BID_QTY = prevTotalBidQty,
        TOTAL_ASK_QTY = prevTotalAskQty
      } = processedData

      state[scriptId] = {
        LTP,
        NET_CHANGE,
        PERCENT_CHANGE,
        LTT,
        B5BUY,
        B5SELL,
        VOLUME,
        OPEN_PRICE,
        CLOSE_PRICE,
        AVERAGE_TRADE_PRICE,
        LOW_PRICE,
        HIGH_PRICE,
        LIFE_TIME_HIGH,
        LIFE_TIME_LOW,
        FIFTY_TWO_WEEK_LOW,
        FIFTY_TWO_WEEK_HIGH,
        TOTAL_BUY_QTY,
        TOTAL_SELL_QTY,
        DAILY_PRICE_RANGE,
        OPEN_INTEREST,
        UPPER_CIRCUIT,
        LOWER_CIRCUIT,
        TOTAL_BID_QTY,
        TOTAL_ASK_QTY
      }
    })

    builder.addCase(updateOldStockTickerAction, (state, { payload }) => {
      const {
        scriptId,
        LTP,
        NET_CHANGE,
        PERCENT_CHANGE,
        LTT,
        B5BUY,
        B5SELL,
        VOLUME,
        OPEN_PRICE,
        CLOSE_PRICE,
        AVERAGE_TRADE_PRICE,
        LOW_PRICE,
        HIGH_PRICE,
        LIFE_TIME_HIGH,
        LIFE_TIME_LOW,
        TOTAL_BUY_QTY,
        TOTAL_SELL_QTY,
        DAILY_PRICE_RANGE,
        OPEN_INTEREST
      } = payload

      state[scriptId] = {
        LTP,
        NET_CHANGE,
        PERCENT_CHANGE,
        LTT,
        B5BUY,
        B5SELL,
        VOLUME,
        OPEN_PRICE,
        CLOSE_PRICE,
        AVERAGE_TRADE_PRICE,
        LOW_PRICE,
        HIGH_PRICE,
        LIFE_TIME_LOW,
        LIFE_TIME_HIGH,
        TOTAL_BUY_QTY,
        TOTAL_SELL_QTY,
        DAILY_PRICE_RANGE,
        OPEN_INTEREST
      }
    })
  }
}

const ticketSlice = createSlice(sliceOptions)
export default ticketSlice.reducer
