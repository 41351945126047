import { format, addDays } from 'date-fns'
import { ORDERBOOK_TAB } from '~/src/Pages/Orderbook/Orderbook.constants'

export type TSelectedStock = {
  scriptId: string
  exchangeSymbol: string
  coname: string
  exchange: string
}

export type T_SIP_INFO = {
  sipName: string
  startDate: string
  frequency: string
  duration: string
  basedOn: string
  selectedScripts: TSelectedStock[] // TODO - PRIORITY: remove any replace with type
  orgSelectedScripts: TSelectedStock[] // TODO - PRIORITY: remove any replace with type
}

export type T_ORDER_BOOK_OBJECT = {
  orderRefId: string
  omsOrderId: string
  exchangeOrderId: string
  exchange: string
  scriptId: string
  orderStatus: string
  transactionType: string
  product: string
  orderType: string
  tradedQty: number
  totalQty: number
  openQty: number
  disclosedQty: number
  orderPrice: number
  validityType: string
  symbol: string
  series: string
  instrument: string
  expiryDate: string
  optionType: string
  omsOrderTS: string
  omsOrderSerialNumber: string
  goodTillDate: string
  triggerPrice: number
  isAmo: boolean
}

export type TOrderBookWithAdditionalKeysObject = T_ORDER_BOOK_OBJECT & {
  id: string
  status: string
  isNonTradable: boolean
}

export type T_SIP_ORDER = {
  [key in string]: any // TODO - PRIORITY: define valid type
}

export type T_ISIN_CODE = string[] | number[]

export type T_ORDER_FORM = {
  quantity: number
  price: string
  marketDepth: string
  orderType: string // TODO - PRIORITY: define valid enum
  triggerPrice: number
  validity: string // TODO - PRIORITY: define valid enum
  disclosedQty: number
  afterMarketOrder: boolean
  sellableQtyType: string // TODO - PRIORITY: define valid enum
  encash: boolean
  bnpl: boolean
  sectionType: number
  goodTillDate: string
}

export type T_ORDER_REDUCER = {
  sipInfo: T_SIP_INFO
  orderBook: T_ORDER_BOOK_OBJECT[] | null
  sipOrderBook: T_SIP_ORDER
  sipOrderBookFilter: any // TODO - PRIORITY: define valid type(not used anywhere)
  sipOrderDetails: any // TODO - PRIORITY: define valid type
  commonIsinCodeScriptArray: T_ISIN_CODE
  placeOrderDetails: any // TODO - PRIORITY: define valid type
  modifyOrderDetails: any // TODO - PRIORITY: define valid type
  cancelOrderDetails: any // TODO - PRIORITY: define valid type
  orderStatusCount: any // TODO - PRIORITY: define valid type
  orderPageModalIndicator: boolean
  orderBuyOrSell: string
  orderScriptId: null | string | number
  isModifyOrderRequest: boolean
  isRepeatOrderRequest: boolean
  selectedExchange: string
  requiredMargin: any // TODO - PRIORITY: define valid type
  tradeBookData: any | null // TODO - PRIORITY: define valid type
  avgTradedPrice: number
  orderTrailData: any // TODO - PRIORITY: define valid type
  exchangeSelectorIndicator: boolean
  orderFormData: T_ORDER_FORM
  orderTaxAndCharges: any // TODO - PRIORITY: define valid type
  currentModifyOrderData: any // TODO - PRIORITY: define valid type
  orderbookFilter: {
    stocksFilter: any[] // TODO - PRIORITY: define valid type
    productsFilter: any[] // TODO - PRIORITY: define valid type
    exchangeFilter: any[] // TODO - PRIORITY: define valid type
    segmentFilter: any[] // TODO - PRIORITY: define valid type
    sortBy: string // TODO - PRIORITY: define valid enum
  }
  SIPFilter: {
    freqFilter: any[] // TODO - PRIORITY: define valid type
    typeFilter: any[] // TODO - PRIORITY: define valid type
    sortBy: string // TODO - PRIORITY: define valid enum
  }
  filterLabels: {
    productFilterLabel: any[] // TODO - PRIORITY: define valid type
    exchangeFilterLabel: any[] // TODO - PRIORITY: define valid type
    segmentFilterLabel: any[] // TODO - PRIORITY: define valid type
    stockFilterLabel: any[] // TODO - PRIORITY: define valid type
  }
  SIPFilterLabels: {
    freqFilterLabel: [] // TODO - PRIORITY: define valid type
    typeFilterLabel: [] // TODO - PRIORITY: define valid type
  }
  openOrderTradeBookAccordion: boolean
  openOrderTrailAccordion: boolean
  orderRejectionReason: string
  showPostLoginError: boolean
  ssoAuthentication: any // TODO - PRIORITY: we dont need ssoAuthentication here(please reverify and remove from here)
  temporaryOrderData: any // TODO - PRIORITY: define valid type
  isDefaultFiltersEnabled: boolean
  orderBookTab: string
}

export const INITIAL_STATE: T_ORDER_REDUCER = {
  sipInfo: {
    sipName: '',
    startDate: format(addDays(new Date(), 1), 'dd/MM/yy'),
    frequency: 'Monthly',
    duration: '1 Year',
    basedOn: 'quantity',
    selectedScripts: [],
    orgSelectedScripts: []
  },
  orderBook: [],
  sipOrderBook: [],
  sipOrderBookFilter: {},
  sipOrderDetails: [],
  commonIsinCodeScriptArray: [],
  placeOrderDetails: {},
  modifyOrderDetails: {},
  cancelOrderDetails: {},
  orderStatusCount: {},
  orderPageModalIndicator: false,
  orderBuyOrSell: '',
  orderScriptId: null,
  isModifyOrderRequest: false,
  isRepeatOrderRequest: false,
  selectedExchange: '',
  requiredMargin: {},
  tradeBookData: null,
  avgTradedPrice: 0,
  orderTrailData: null,
  exchangeSelectorIndicator: false,
  orderFormData: {
    quantity: 1,
    price: '',
    marketDepth: 'market',
    orderType: 'DELIVERY',
    triggerPrice: 0,
    validity: 'Day',
    disclosedQty: 0,
    afterMarketOrder: false,
    sellableQtyType: 'DEMAT',
    encash: false,
    bnpl: false,
    sectionType: 0,
    goodTillDate: ''
  },
  orderTaxAndCharges: {},
  currentModifyOrderData: {},
  orderbookFilter: {
    stocksFilter: [],
    productsFilter: [],
    exchangeFilter: [],
    segmentFilter: [],
    sortBy: 'CREATED_DESCENDING'
  },
  SIPFilter: {
    freqFilter: [],
    typeFilter: [],
    sortBy: 'DUE_DATE'
  },
  filterLabels: {
    productFilterLabel: [],
    exchangeFilterLabel: [],
    segmentFilterLabel: [],
    stockFilterLabel: []
  },
  SIPFilterLabels: {
    freqFilterLabel: [],
    typeFilterLabel: []
  },
  openOrderTradeBookAccordion: false,
  openOrderTrailAccordion: false,
  orderRejectionReason: '',
  showPostLoginError: false,
  ssoAuthentication: {
    refreshToken: { token: '', expireAt: '' },
    authToken: { token: '', expireAt: '' },
    metadata: null
  },
  temporaryOrderData: {},
  isDefaultFiltersEnabled: true,
  orderBookTab: ORDERBOOK_TAB.Orders.value
}
