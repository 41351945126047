import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  closeAppStateDialogActions,
  openAndSetCancelOrderDrawerDataActions,
  openAppStateDialogActions,
  openSetOrderDetailsDrawerDataActions,
  setAppStateDialogActions
} from './actions'
import { SLICE_NAME } from './selectors'

export type TDeepLink = {
  path: string
  parameterList: string[]
}
interface IAppState {
  dialog: object
  deepLinkData: TDeepLink | null
  orderDrawer: {
    open: boolean
    data: any
  }
  cancelOrderDrawer: {
    open: boolean
    data: any
  }
}

const INITIAL_STATE: IAppState = {
  dialog: {},
  deepLinkData: null,
  orderDrawer: {
    open: false,
    data: null
  },
  cancelOrderDrawer: {
    open: false,
    data: null
  }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDeepLinkData: (state, action) => {
      const { payload } = action
      state.deepLinkData = payload
    },
    resetDeepLinkData: state => {
      state.deepLinkData = INITIAL_STATE.deepLinkData
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // builder.addCase(clearAppStateDialogActions, (state, { payload }) => {
    //   const { key, data } = payload
    //   state.dialog[key] = data
    // })
    builder.addCase(setAppStateDialogActions, (state, { payload }) => {
      const { key, data } = payload
      state.dialog[key] = data
    })
    builder.addCase(openAppStateDialogActions, (state, { payload }) => {
      state.dialog[payload] = { open: true }
    })
    builder.addCase(closeAppStateDialogActions, (state, { payload }) => {
      state.dialog[payload] = { open: false }
    })

    builder.addCase(
      openAndSetCancelOrderDrawerDataActions,
      (state, { payload }) => {
        state.cancelOrderDrawer = { open: true, data: payload }
      }
    )

    builder.addCase(
      openSetOrderDetailsDrawerDataActions,
      (state, { payload }) => {
        console.log(payload, '>>>>>>>>')

        state.orderDrawer = { open: true, data: payload }
      }
    )
  }
}

const slice = createSlice(sliceOptions)
export const { setDeepLinkData, resetDeepLinkData } = slice.actions
export default slice.reducer
