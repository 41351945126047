import * as React from 'react'
import {
  DsBox,
  DsButton,
  DsButtonGroup,
  DsDialog,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import {
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system/dist/Hocs/withBreakpoints'
import Lottie from 'lottie-react'

import successMarkGreen from './successMarkGreen.json'

import { BULB } from '~/src/Constants/ASSET_MAP'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'

export interface IGratificationDialogProps
  extends IWithRouterProps,
    IwithBreakpoints {
  showGratification: boolean
  heading: string
  subHeading?: string
  isButtonGroupNeeded?: boolean
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  isHRTAllowedSelect: boolean
  primaryButtonClickAction?: {
    actionType: string
    pathname?: string
    primaryButtonClickFunction?: () => any
  }
  secondaryButtonClickAction?: {
    actionType: string
    pathname?: string
    secondaryButtonClickFunction?: () => any
  }
  onCloseAction?: {
    actionType: string
    pathname?: string
    onCloseFunction?: () => any
  }
}

class GratificationDialog extends React.PureComponent<IGratificationDialogProps> {
  handleOnClose = () => {
    const { navigateTo, onCloseAction } = this.props
    const { actionType, pathname, onCloseFunction } = onCloseAction || {}
    if (actionType === 'NAVIGATION' && pathname) {
      navigateTo(pathname)
    } else if (
      actionType === 'FUNCTION' &&
      onCloseFunction &&
      typeof onCloseFunction === 'function'
    ) {
      onCloseFunction()
    }
  }

  handlePrimaryButtonClick = () => {
    const { navigateTo, primaryButtonClickAction } = this.props
    const { actionType, pathname, primaryButtonClickFunction } =
      primaryButtonClickAction as any
    if (actionType === 'NAVIGATION' && pathname) {
      navigateTo(pathname)
    } else if (actionType === 'FUNCTION' && primaryButtonClickFunction) {
      primaryButtonClickFunction()
    }
  }

  handleSecondaryButtonClick = () => {
    const { navigateTo, secondaryButtonClickAction } = this.props
    const { actionType, pathname, secondaryButtonClickFunction } =
      secondaryButtonClickAction as any
    if (actionType === 'NAVIGATION' && pathname) {
      navigateTo(pathname)
    } else if (actionType === 'FUNCTION' && secondaryButtonClickFunction) {
      secondaryButtonClickFunction()
    }
  }

  renderButtonGroup = () => {
    const {
      primaryButtonLabel,
      primaryButtonClickAction,
      secondaryButtonClickAction,
      secondaryButtonLabel
    } = this.props

    return (
      <DsButtonGroup
        size='large'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--ds-spacing-bitterCold)',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {primaryButtonClickAction && (
          <DsButton color='primary' onClick={this.handlePrimaryButtonClick}>
            {primaryButtonLabel}
          </DsButton>
        )}
        {secondaryButtonClickAction && (
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <DsButton
              color='secondary'
              variant='text'
              onClick={this.handleSecondaryButtonClick}
              sx={{
                width: 'max-content'
              }}
            >
              {secondaryButtonLabel}
            </DsButton>
          </DsBox>
        )}
      </DsButtonGroup>
    )
  }

  _getActionButtonPropsIfNeeded = () => {
    const { isButtonGroupNeeded, primaryButtonLabel, secondaryButtonLabel } =
      this.props
    if (isButtonGroupNeeded) {
      return {
        primaryButtonText: primaryButtonLabel,
        primaryButtonProps: {
          onClick: this.handlePrimaryButtonClick
        },
        secondaryButtonText: secondaryButtonLabel,
        secondaryButtonProps: {
          onClick: this.handleSecondaryButtonClick
        }
      }
    }
  }

  render() {
    const {
      heading,
      subHeading,
      isHRTAllowedSelect,
      showGratification,
      breakpoints
    } = this.props

    const isDesktop = getDeviceActiveScreen(breakpoints)

    return (
      <DsDialog
        fullScreen={!isDesktop}
        open={showGratification}
        showClose={false}
        {...this._getActionButtonPropsIfNeeded()}
        onClose={this.handleOnClose}
        sx={{
          '& .MuiDialog-paperFullScreen': {
            justifyContent: 'center',
            borderRadius: 'var(--ds-radius-zero)'
          },
          '& .MuiDialogContent-root': {
            mt: { xs: '10rem', md: '0' }
          }
        }}
      >
        <DsStack
          spacing='var(--ds-spacing-mild)'
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <DsBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              WebkitAlignItems: 'center'
            }}
          >
            <Lottie
              animationData={successMarkGreen}
              loop={0}
              style={{
                width: 'var(--ds-spacing-plasma)',
                height: 'var(--ds-spacing-plasma)',
                textAlign: 'center'
              }}
            />
          </DsBox>
          <DsTypography variant='headingBoldLarge' component={'div'}>
            {heading}
          </DsTypography>
          {subHeading && (
            <DsTypography
              variant='bodyRegularSmall'
              component={'div'}
              sx={{
                color: 'var(--ds-colour-typoSecondary)'
              }}
            >
              {subHeading}
            </DsTypography>
          )}
        </DsStack>

        {isHRTAllowedSelect && (
          <DsBox
            sx={{
              display: 'grid',
              gridTemplateColumns: '10% 90%',
              gap: '1rem',
              alignItems: 'flex-start',
              borderRadius: 'var(--ds-radius-glacial)',
              border: '1px solid var(--ds-colour-strokeDefault)',
              padding: '1rem',
              position: { xs: 'absolute', md: 'unset' },
              bottom: '1rem',
              left: '1rem',
              right: '1rem',
              mt: '1rem'
            }}
          >
            <DsImage srcSet={BULB}></DsImage>
            <DsTypography
              sx={{
                color: 'var(--ds-colour-typoSecondary)'
              }}
              variant='bodyRegularSmall'
            >
              If the funds in the account are insufficient, a lien will be
              placed on the default bank account.
            </DsTypography>
          </DsBox>
        )}
      </DsDialog>
    )
  }
}

export default withRouter(withBreakpoints(GratificationDialog))
