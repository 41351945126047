// DONE - CLEANUP: @vasi
// 1. define types

import React, { Component } from 'react'
import { DsBox, DsStack, DsTypography } from '@am92/react-design-system'

import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import QuotesStockData from '~/src/Pages/Quotes/Components/QuotesStockData'
import SipScriptDetailsAmtValue from '../SipScriptDetailsAmtValue'
import {
  ISipInfo,
  TSipIndiInfo,
  TSipInfo,
  updateAmtQtySelectedScriptsData,
  updateSelectedScriptsData
} from '~/src/Redux/Orders/reducer'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'

export interface ActionType {
  updateSelectedScriptsData: (item: ISipInfo) => void
}

export interface IItem {
  scriptId: string
  exchangeSymbol: string
  coname: string
  exchange: string
  amount?: number | string
  quantity?: string | number
}

export interface ISipScriptsDetailsLeftProps {
  actions: ActionType
  item: TSipIndiInfo
  sipInfo: TSipInfo
  value: number
  stockPriceDetails: ITicker
  isModifing: boolean
}

export class SipScriptsDetailsLeft extends Component<ISipScriptsDetailsLeftProps> {
  render() {
    const { item, sipInfo, value, isModifing, actions } = this.props
    const { basedOn } = sipInfo
    const normalizedBasedOn = basedOn.toLowerCase()
    const finalValueLabel =
      normalizedBasedOn === 'amount' ? 'Quantity' : 'Amount'
    return (
      <DsStack gap={'8px'} alignItems={'flex-start'}>
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <DsTypography variant='bodyRegularMedium'>{item.coname}</DsTypography>
          <DsTypography
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
            variant='bodyRegularSmall'
          >
            {finalValueLabel}:
            <SipScriptDetailsAmtValue
              item={item}
              sipInfo={sipInfo}
              value={value}
              isModifing={isModifing}
              updateSelectedScriptsData={actions.updateSelectedScriptsData}
            />
          </DsTypography>
        </DsBox>
        <DsBox sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <DsTypography variant='supportRegularInfo'>
            {item.exchange}
          </DsTypography>

          <QuotesStockData
            scriptId={item.scriptId}
            type='quotes'
            ltpTypoVariant='bodyRegularSmall'
            netChangeTypoVariant='supportRegularInfo'
          />
        </DsBox>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: TAppStore, ownProps: any) => {}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateSelectedScriptsData: (requestData: TSipInfo) =>
      dispatch(updateAmtQtySelectedScriptsData(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(SipScriptsDetailsLeft)
