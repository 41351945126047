import React, { Component } from 'react'
import {
  DsRemixIcon,
  DsSkeleton,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'
import { calculateNetChange, getCurrencyFormat } from '~/src/Utils/global'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import withErrorConnect from '~/src/Lib/withErrorConnect'

interface IStockTickerOnHeader {
  scriptId: string
  closeprice: number
  netChangeTypoVariant?: string
  showNetChange?: boolean
  showLTP?: boolean
  type?: string
  segment: string
  stockDetails: T_SCRIPTDATA
  stockPriceDetails: ITicker
}

type IStockTickerState = {
  NET_CHANGE: number
  PERCENT_CHANGE: number
}
class StockTickerOnHeader extends Component<
  IStockTickerOnHeader,
  IStockTickerState
> {
  constructor(props: IStockTickerOnHeader) {
    super(props)
    const { stockPriceDetails, closeprice = 0 } = props
    const {
      NET_CHANGE = 0,
      PERCENT_CHANGE = 0,
      LTP = closeprice
    } = (stockPriceDetails as ITicker) || {}

    this.state = {
      NET_CHANGE: NET_CHANGE
        ? NET_CHANGE
        : calculateNetChange(LTP, closeprice)[0],
      PERCENT_CHANGE: PERCENT_CHANGE
        ? PERCENT_CHANGE
        : calculateNetChange(LTP, closeprice)[1]
    }
  }

  componentDidUpdate(prevProps: Readonly<IStockTickerOnHeader>): void {
    const { stockPriceDetails, closeprice = 0 } = this.props
    const {
      NET_CHANGE = 0,
      PERCENT_CHANGE = 0,
      LTP = closeprice
    } = stockPriceDetails || {}
    if (
      prevProps.stockPriceDetails !== stockPriceDetails &&
      stockPriceDetails
    ) {
      if (!NET_CHANGE) {
        const [netChange, percentChange] = calculateNetChange(LTP, closeprice)
        this.setState({
          NET_CHANGE: netChange,
          PERCENT_CHANGE: percentChange
        })
        return
      }
      this.setState({
        NET_CHANGE: NET_CHANGE,
        PERCENT_CHANGE: PERCENT_CHANGE
      })
    }
  }

  renderLTP = () => {
    const { stockPriceDetails, closeprice = 0, type = '', segment } = this.props
    const { LTP = closeprice } = stockPriceDetails || {}
    const optionLtp = type === 'option'
    const options = { minimumFractionDigits: segment === 'CURR' ? 4 : 2 }
    return (
      <DsTypography
        variant='subheadingSemiboldDefault'
        sx={{
          color: 'var(--ds-colour-surfaceBackground)'
        }}
      >
        {!LTP ? (
          closeprice ? (
            `${getCurrencyFormat(closeprice, {
              ...options,
              style: 'decimal'
            })}`
          ) : (
            <DsSkeleton
              variant='text'
              width={60}
              sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
            />
          )
        ) : optionLtp ? (
          getCurrencyFormat(LTP, { ...options, style: 'decimal' })
        ) : (
          getCurrencyFormat(LTP, { ...options, style: 'decimal' })
        )}
      </DsTypography>
    )
  }

  renderNetChange = () => {
    const { NET_CHANGE, PERCENT_CHANGE } = this.state
    const { segment } = this.props
    const options = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2
    }
    return (
      <>
        <DsTypography
          align='center'
          color='var(--ds-colour-surfaceBackground)'
          variant='subheadingSemiboldDefault'
        >
          {`(${getCurrencyFormat(PERCENT_CHANGE / 100, {
            ...options,
            signDisplay: 'never',
            style: 'percent'
          })})`}
        </DsTypography>
        <DsRemixIcon
          className={`ri-arrow-${NET_CHANGE > 0 ? 'up' : 'down'}-fill`}
          fontSize='bitterCold'
          sx={{
            display: 'flex',
            marginLeft: 'var(--ds-spacing-deepFreeze)',
            color: 'var(--ds-colour-surfaceBackground)'
          }}
        />
      </>
    )
  }

  render() {
    const { NET_CHANGE } = this.state
    const { showNetChange = true, showLTP = true } = this.props

    return (
      <>
        {showLTP ? this.renderLTP() : null}

        {showNetChange ? (
          <DsStack
            sx={{
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            {/* {NET_CHANGE ? (
              this.renderNetChange()
            ) : (
              <DsSkeleton
                variant='text'
                width={80}
                sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
              />
            )} */}
            {this.renderNetChange()}
          </DsStack>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const stockPriceDetails = getTickerData(scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
    context: tickerContext
  })(StockTickerOnHeader)
)
