import React, { Component } from 'react'
import {
  DsBox,
  DsRemixIcon,
  DsSkeleton,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'
import { calculateNetChange, getCurrencyFormat } from '~/src/Utils/global'
import withErrorConnect from '~/src/Lib/withErrorConnect'

interface IStockTicker {
  scriptId: string
  closeprice: number
  stockPriceDetails: ITicker
  component?: string
  ltpTypoVariant?: string
  netChangeTypoVariant?: string
  showNetChange?: boolean
  showLTP?: boolean
  type?: string
  path?: string
  segment: string
}

type IStockTickerState = {
  NET_CHANGE: number
  PERCENT_CHANGE: number
}

class StockTicker extends Component<IStockTicker, IStockTickerState> {
  constructor(props: IStockTicker) {
    super(props)
    const { stockPriceDetails, closeprice = 0 } = props
    const {
      NET_CHANGE = 0,
      PERCENT_CHANGE = 0,
      LTP = closeprice
    } = stockPriceDetails || {}
    this.state = {
      NET_CHANGE: NET_CHANGE
        ? NET_CHANGE
        : calculateNetChange(LTP, closeprice)[0],
      PERCENT_CHANGE: PERCENT_CHANGE
        ? PERCENT_CHANGE
        : calculateNetChange(LTP, closeprice)[1]
    }
  }

  componentDidUpdate(prevProps: Readonly<IStockTicker>): void {
    const { stockPriceDetails, closeprice = 0 } = this.props
    const {
      NET_CHANGE = 0,
      PERCENT_CHANGE = 0,
      LTP = closeprice
    } = stockPriceDetails || {}
    if (
      prevProps.stockPriceDetails !== stockPriceDetails &&
      stockPriceDetails
    ) {
      if (!NET_CHANGE) {
        const [netChange, percentChange] = calculateNetChange(LTP, closeprice)
        this.setState({
          NET_CHANGE: netChange,
          PERCENT_CHANGE: percentChange
        })
        return
      }
      this.setState({
        NET_CHANGE: NET_CHANGE,
        PERCENT_CHANGE: PERCENT_CHANGE
      })
    }
  }

  renderLTP = () => {
    const {
      stockPriceDetails,
      closeprice = 0,
      ltpTypoVariant = '',
      segment
    } = this.props
    const { LTP = closeprice } = stockPriceDetails || {}
    const options = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2
    }
    const ltp = getCurrencyFormat(LTP || 0, options)
    return (
      <DsTypography
        variant={(ltpTypoVariant && ltpTypoVariant) || 'bodyRegularSmall'}
      >
        {ltp}
      </DsTypography>
    )
  }

  renderNetChange = () => {
    const { NET_CHANGE, PERCENT_CHANGE } = this.state
    const {
      component = '',
      netChangeTypoVariant = '',
      type = '',
      segment,
      stockPriceDetails
    } = this.props
    const { LTP = 0 } = stockPriceDetails || {}
    // here do not use closeprice instead of LTP since need to know whether ltp is cominh
    const options = {
      minimumFractionDigits: segment === 'CURR' ? 4 : 2
    }

    if (type === 'option') {
      return (
        <DsTypography
          align='center'
          variant='bodyRegularMedium'
          color={'var(--ds-colour-secondary)'}
          sx={{ textAlign: 'center' }}
        >
          {getCurrencyFormat(PERCENT_CHANGE, {
            ...options,
            signDisplay: 'exceptZero',
            style: 'decimal'
          })}
          {/* //NOTE - give sign option */}
        </DsTypography>
      )
    }

    if (type === 'changePer') {
      return (
        <>
          <DsTypography
            align='center'
            color={
              NET_CHANGE > 0
                ? 'var(--ds-colour-iconPositive)'
                : 'var(--ds-colour-iconNegative)'
            }
            variant={
              netChangeTypoVariant ? netChangeTypoVariant : 'supportRegularInfo'
            }
          >
            {/* {`${PERCENT_CHANGE.toString()?.slice(1)}%`} */}
            {`${getCurrencyFormat(PERCENT_CHANGE / 100, {
              ...options,
              signDisplay: 'never',
              style: 'percent'
            })}`}
          </DsTypography>
          <DsRemixIcon
            className={`ri-arrow-${NET_CHANGE > 0 ? 'up' : 'down'}-fill`}
            fontSize='bitterCold'
            sx={{
              display: 'flex',
              marginLeft: 'var(--ds-spacing-deepFreeze)'
            }}
            color={NET_CHANGE > 0 ? 'success' : 'error'}
          />
        </>
      )
    }

    if (type === 'change') {
      return (
        <DsTypography
          align='center'
          color={
            NET_CHANGE > 0
              ? 'var(--ds-colour-iconPositive)'
              : 'var(--ds-colour-iconNegative)'
          }
          variant={
            netChangeTypoVariant ? netChangeTypoVariant : 'supportRegularInfo'
          }
        >
          {getCurrencyFormat(NET_CHANGE, { ...options, style: 'decimal' })}
        </DsTypography>
      )
    }

    return (
      <DsBox sx={{ display: 'flex' }}>
        <DsTypography
          color={
            LTP
              ? NET_CHANGE > 0
                ? 'var(--ds-colour-iconPositive)'
                : 'var(--ds-colour-iconNegative)'
              : 'var(--ds-colour-typoPrimary)'
          }
          variant={
            netChangeTypoVariant ? netChangeTypoVariant : 'supportRegularInfo'
          }
        >
          {component !== 'orders'
            ? `${getCurrencyFormat(NET_CHANGE, {
                ...options,
                signDisplay: 'exceptZero',
                style: 'decimal'
              })} (${getCurrencyFormat(PERCENT_CHANGE / 100, {
                ...options,
                signDisplay: 'never',
                style: 'percent'
              })})`
            : `${getCurrencyFormat(PERCENT_CHANGE / 100, {
                ...options,
                signDisplay: 'never',
                style: 'percent'
              })}`}
        </DsTypography>
        {(LTP && (
          <DsRemixIcon
            className={`ri-arrow-${NET_CHANGE > 0 ? 'up' : 'down'}-fill`}
            fontSize='bitterCold'
            sx={{
              display: 'flex',
              marginLeft: 'var(--ds-spacing-deepFreeze)'
            }}
            color={NET_CHANGE > 0 ? 'success' : 'error'}
          />
        )) ||
          null}
      </DsBox>
    )
  }

  render() {
    const {
      showNetChange = true,
      showLTP = true,
      path = '',
      type = ''
    } = this.props
    return (
      <DsBox
        sx={
          path === 'research' || type == 'quotes' || type == 'optionChain'
            ? { display: 'flex', gap: '8px' }
            : type == 'watchlist'
              ? {
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column'
                }
              : {}
        }
      >
        {showLTP ? this.renderLTP() : null}

        {showNetChange ? (
          <DsStack
            sx={{
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            {this.renderNetChange()}
          </DsStack>
        ) : null}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const stockPriceDetails = getTickerData(scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(StockTicker)
