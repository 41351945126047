import React, { Component } from 'react'
import {
  DsBottomSheet,
  DsPopup,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'

import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import { SIPClevertap } from '~/src/Pages/Orderbook/SIP.ClevertapEvents'

export class SipDiscardConfirmation extends Component<any> {
  render() {
    const { breakpoints, open, setOpen, handleDiscard } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        {isDesktop ? (
          <DsPopup
            DsDialogProps={{
              open: open,
              onClose: () => setOpen(!open)
            }}
            primaryButtonText='No'
            secondaryButtonText='Yes, Discard'
            showClose={true}
            primaryButtonProps={{
              onClick: () => {
                setOpen(!open)
                new SIPClevertap().onClickBackBtn('No')
              }
            }}
            secondaryButtonProps={{
              onClick: () => handleDiscard()
            }}
            title={'Discard Changes?'}
          >
            <DsTypography variant='bodyRegularMedium'>
              You have some unsaved changes. Are you sure you want to discard
              the changes?
            </DsTypography>
          </DsPopup>
        ) : (
          <DsBottomSheet
            open={open}
            onClick={() => setOpen(!open)}
            primaryButtonText='No'
            secondaryButtonText='Yes, Discard'
            showClose={true}
            primaryButtonProps={{
              onClick: () => setOpen(!open)
            }}
            secondaryButtonProps={{
              onClick: () => handleDiscard()
            }}
            title={'Discard Changes?'}
          >
            <DsTypography variant='bodyRegularMedium'>
              You have some unsaved changes. Are you sure you want to discard
              the changes?
            </DsTypography>
          </DsBottomSheet>
        )}
      </>
    )
  }
}

export default withBreakpoints(SipDiscardConfirmation)
