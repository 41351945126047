import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import Select from '@mui/material/Select';
import { DsSelectDefaultProps } from './DsSelect.Types';
import { DsFormControl } from '../DsFormControl';
import { DsInputLabel } from '../DsInputLabel';
import { DsInputBase } from '../DsInputBase';
import { DsMenuItem } from '../DsMenuItem';
import { DsTypography } from '../DsTypography';
import { DsRemixIcon } from '../DsRemixIcon';
import { DsHelperText } from '../DsHelperText';
export class DsSelect extends PureComponent {
    static defaultProps = DsSelectDefaultProps;
    selectRef = React.createRef();
    render() {
        const { id, name, placeholder, label, labelSupportText, color, helperText, success, error, sx, fullWidth, children, options, disabled, FormControlProps, InputLabelProps, FormHelperTextProps, required, ...selectProps } = this.props;
        const customColor = success ? 'success' : color;
        const valueMap = options.reduce((acc, item) => {
            acc.set(item.value, item.label);
            return acc;
        }, new Map());
        return (_jsxs(DsFormControl, { fullWidth: fullWidth, color: customColor, error: error, sx: sx, disabled: disabled, required: required, ...FormControlProps, children: [_jsx(DsInputLabel, { label: label, labelSupportText: labelSupportText, error: error, success: success, htmlFor: id || name, disabled: disabled, ...InputLabelProps }), _jsxs(Select, { ref: this.selectRef, id: id, name: name, IconComponent: IconComponent, displayEmpty: true, input: _jsx(DsInputBase, {}), renderValue: value => {
                        if (!value || (value instanceof Array && !value.length)) {
                            return (_jsx(DsTypography, { variant: "bodyRegularMedium", color: "text.disabled", children: placeholder }));
                        }
                        if (value instanceof Array) {
                            return (_jsx(SelectedValueArray, { selectedValue: value, valueMap: valueMap }));
                        }
                        return _jsx(SelectedValue, { selectedValue: value, valueMap: valueMap });
                    }, error: error, disabled: disabled, ...selectProps, MenuProps: {
                        anchorEl: () => this.selectRef.current,
                        ...selectProps?.MenuProps,
                        sx: {
                            marginTop: 'var(--ds-spacing-glacial)',
                            ...selectProps?.MenuProps?.sx
                        }
                    }, children: [children, options.map(({ label, value }, index) => (_jsx(DsMenuItem, { value: value, sx: {
                                '&.Mui-selected': {
                                    backgroundColor: 'transparent',
                                    fontWeight: 'var(--ds-typo-bodyBoldMedium-fontWeight)',
                                    fontSize: 'var(--ds-typo-bodyBoldMedium-fontSize)',
                                    lineHeight: 'var(--ds-typo-bodyBoldMedium-lineHeight)',
                                    letterSpacing: 'var(--ds-typo-bodyBoldMedium-letterSpacing)'
                                }
                            }, children: _jsx("span", { children: label }) }, index)))] }), _jsx(DsHelperText, { helperText: helperText, color: color, success: success, error: error, ...FormHelperTextProps })] }));
    }
}
const IconComponent = (props) => {
    return (_jsx(DsRemixIcon, { ...props, className: `${props.className} ri-arrow-down-s-line` }));
};
const SelectedValueArray = (props) => {
    const { selectedValue, valueMap } = props;
    const returnValue = selectedValue
        .map(selectedVal => valueMap.get(selectedVal))
        .join(', ');
    return _jsx(_Fragment, { children: returnValue });
};
const SelectedValue = (props) => {
    const { selectedValue, valueMap } = props;
    const returnValue = valueMap.get(selectedValue);
    return _jsx(_Fragment, { children: returnValue });
};
