import { createAction } from '@reduxjs/toolkit'

export const clearAppStateDialogServiceName = 'appstate/clearAppStateDialog'
export const clearAppStateDialogActions = createAction<any>(
  clearAppStateDialogServiceName
)
export const setAppStateDialogServiceName = 'appstate/setAppStateDialog'
export const setAppStateDialogActions = createAction<any>(
  setAppStateDialogServiceName
)
export const openAppStateDialogServiceName = 'appstate/openAppStateDialog'
export const openAppStateDialogActions = createAction<any>(
  openAppStateDialogServiceName
)
export const closeAppStateDialogServiceName = 'appstate/closeAppStateDialog'
export const closeAppStateDialogActions = createAction<any>(
  closeAppStateDialogServiceName
)

export const openSetOrderDetailsDrawerDataServiceName =
  'appstate/openSetOrderDetailsDrawerData'

export const openSetOrderDetailsDrawerDataActions = createAction<any, any>(
  openSetOrderDetailsDrawerDataServiceName
)

export const openAndSetCancelOrderDrawerDataServiceName =
  'appstate/openAndSetCancelOrderDrawerData'

export const openAndSetCancelOrderDrawerDataActions = createAction<any>(
  openAndSetCancelOrderDrawerDataServiceName
)
