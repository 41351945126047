import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { closeSnackbar } from 'notistack';
import { DsToast } from '../DsToast';
class AlertMessage extends Component {
    handleClose = () => {
        const { id } = this.props;
        closeSnackbar(id);
    };
    render() {
        const { ref, toastVariant, variant, message, id, autoHideDuration, hideIconVariant, anchorOrigin, persist, iconVariant, ...restProps } = this.props;
        return (_jsx(DsToast, { ...restProps, variant: toastVariant, color: variant, onClose: this.handleClose, children: message }));
    }
}
export const DsNotistackAlertDefault = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "default" });
});
export const DsNotistackAlertSuccess = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "success" });
});
export const DsNotistackAlertError = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "error" });
});
export const DsNotistackAlertWarning = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "warning" });
});
export const DsNotistackAlertInfo = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "info" });
});
