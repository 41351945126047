import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  getDetailsResearchDataActions,
  getResearchConfigActions,
  getResearchDataActions,
  getSimilarResearchDataActions
} from './Actions'
import { _isEqual } from '~/src/Utils/lodash'
import { sortRecommendation } from '~/src/Pages/Research/Recommendations/SortRecommendations.constants'
import { DURATION_MAPPER } from '~/src/Pages/Research/Recommendations/RecommendationFilterOptions'

export type T_RecommendationData = {
  callStatus: string
  callType: string
  category: string
  closingPrice: number
  entryPrice: number[]
  exchangeDetails: {
    exchange: string
    ltp: number
    scriptId: string
    searchable: string
  }[]
  expiryDate: string
  isin: string
  logoUrl: string
  marketCap: string
  marketCategory: string
  rationaleOfStock: any[]
  reportAttachment: any
  reportHeader: any
  segment: string
  stopLoss: number[]
  subCategory: string
  symbol: string
  targetPercentage: number
  targetPrice: number[]
  timeDurationOfInvestment: string
  timeOfCall: string
  timeSinceTheCall: string
}
export type T_RecommendationsDataArray = T_RecommendationData[]
export type T_RecommendationsDataObject = { data: T_RecommendationsDataArray }
export type T_ConfigData = {
  segments: string[]
  marketCategory: string[]
  subCategory: string[]
  scriptIds: number[]
  search: string
  marketCap: string[]
  category: string[]
  configTS: string
  targetPercentage: {
    min: number
    max: number
  }
  timeDurationOfInvestment: {
    min: number
    max: number
  }
}

export type T_ConfigDataArray = T_ConfigData[]

type searchType = {
  configData: any
  platformData: any
  data: any
  configTs: string
  researchData: any
  category: any
  recommendationFilter: any
  subCategory: string
  similarResearchData: any
  recommendationTab: string
}
interface ImageUrl {
  dark: string
  light: string
}

export type T_CategoryData = {
  categories: string[]
  count: number
  description: string
  imageUrl: ImageUrl
  subCategory: string
}

export type T_RecommendationFilterOptions = {
  durationFilter: keyof typeof DURATION_MAPPER
  marketCapFilter: string[]
  returnsFilter: string
  sortBy: string
}

const INITIAL_STATE: searchType = {
  configData: [],
  platformData: [],
  data: [],
  configTs: '',
  category: {},
  subCategory: '',
  recommendationFilter: {
    marketCapFilter: [],
    returnsFilter: [],
    durationFilter: [],
    sortBy: 'RELEVANCE'
  },
  researchData: [
    {
      category: 'Positional EQ (5-15 Days)',
      subCategory: 'Positional',
      timeOfCall: '2024-04-16T08:45:41Z',
      callStatus: 'Open',
      callType: 'sell',
      isin: 'INE228A01035',
      symbol: 'USHAMART',
      targetPrice: [378, 0, 0],
      stopLoss: [330, 0, 0],
      closingPrice: 0,
      marketCap: 'other',
      marketCategory: 'Steel',
      timeDurationOfInvestment: '16 days',
      entryPrice: [334, 338.8],
      rationaleOfStock: [],
      reportHeader: 'USHAMART-Positional-2024-04-16 14:15:41',
      reportAttachment: null,
      timeSinceTheCall: '2024-04-16T08:45:41Z',
      segment: 'EQ',
      exchangeDetails: [
        {
          exchange: 'BSE',
          ltp: 0,
          scriptId: '1794058'
        },
        {
          exchange: 'NSE',
          ltp: 368.4,
          scriptId: '50498'
        }
      ],
      expiryDate: '2024-05-09',
      targetPercentage: 130
    },
    {
      category: 'Positional EQ (5-15 Days)',
      subCategory: 'Positional',
      timeOfCall: '2024-04-16T08:45:41Z',
      callStatus: 'Open',
      callType: 'buy',
      isin: 'INE228A01035',
      symbol: 'USHAMART',
      targetPrice: [378, 0, 0],
      stopLoss: [330, 0, 0],
      closingPrice: 0,
      marketCap: 'other',
      marketCategory: 'Steel',
      timeDurationOfInvestment: '16 days',
      entryPrice: [334, 338.8],
      rationaleOfStock: [],
      reportHeader: 'USHAMART-Positional-2024-04-16 14:15:41',
      reportAttachment: null,
      timeSinceTheCall: '2024-04-16T08:45:41Z',
      segment: 'EQ',
      exchangeDetails: [
        {
          exchange: 'BSE',
          ltp: 0,
          scriptId: '1794058'
        },
        {
          exchange: 'NSE',
          ltp: 368.4,
          scriptId: '50498'
        }
      ],
      expiryDate: '2024-05-09',
      targetPercentage: 130
    },
    {
      category: 'Positional EQ (5-15 Days)',
      subCategory: 'Positional',
      timeOfCall: '2024-04-16T08:45:41Z',
      callStatus: 'Open',
      callType: 'hold',
      isin: 'INE228A01035',
      symbol: 'USHAMART',
      targetPrice: [378, 0, 0],
      stopLoss: [330, 0, 0],
      closingPrice: 0,
      marketCap: 'other',
      marketCategory: 'Steel',
      timeDurationOfInvestment: '16 days',
      entryPrice: [334, 338.8],
      rationaleOfStock: [],
      reportHeader: 'USHAMART-Positional-2024-04-16 14:15:41',
      reportAttachment: null,
      timeSinceTheCall: '2024-04-16T08:45:41Z',
      segment: 'EQ',
      exchangeDetails: [
        {
          exchange: 'BSE',
          ltp: 0,
          scriptId: '1794058'
        },
        {
          exchange: 'NSE',
          ltp: 368.4,
          scriptId: '50498'
        }
      ],
      expiryDate: '2024-05-09',
      targetPercentage: 130
    },
    {
      category: 'Positional DRV (5-15 Days)',
      subCategory: 'Positional',
      timeOfCall: '2024-04-16T03:54:55Z',
      callStatus: 'Open',
      callType: 'buy',
      isin: 'INE127D01025',
      symbol: 'HDFCAMC',
      targetPrice: [3868, 0, 0],
      stopLoss: [3680, 0, 0],
      closingPrice: 0,
      marketCap: 'other',
      marketCategory: 'Finance',
      timeDurationOfInvestment: '16 days',
      entryPrice: [3690, 3727],
      rationaleOfStock: [],
      reportHeader: 'HDFCAMC-Positional-2024-04-16 09:24:55',
      reportAttachment: null,
      timeSinceTheCall: '2024-04-16T03:54:55Z',
      segment: 'EQ',
      exchangeDetails: [
        {
          exchange: 'BSE',
          ltp: 0,
          scriptId: '1756925'
        },
        {
          exchange: 'NSE',
          ltp: 3647.5,
          scriptId: '51127'
        }
      ],
      expiryDate: '2024-05-09',
      targetPercentage: 4
    },
    {
      category: 'Positional EQ (5-15 Days)',
      subCategory: '',
      timeOfCall: '2024-04-15T08:25:30Z',
      callStatus: 'Open',
      callType: 'buy',
      isin: 'INE397D01024',
      symbol: 'BHARTIARTL',
      targetPrice: [1292, 0, 0],
      stopLoss: [1208, 0, 0],
      closingPrice: 0,
      marketCap: 'other',
      marketCategory: 'Telecomm-Service',
      timeDurationOfInvestment: '15 days',
      entryPrice: [1217, 1229],
      rationaleOfStock: [],
      reportHeader: 'BHARTIARTL--2024-04-15 13:55:30',
      reportAttachment: null,
      timeSinceTheCall: '2024-04-15T08:25:30Z',
      segment: 'EQ',
      exchangeDetails: [
        {
          exchange: 'BSE',
          ltp: 1260,
          scriptId: '1743635'
        },
        {
          exchange: 'NSE',
          ltp: 1297.55,
          scriptId: '50659'
        }
      ],
      expiryDate: '2024-05-08',
      targetPercentage: 60
    }
  ],
  similarResearchData: [],
  recommendationTab: 'Recommendations'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setResearchData: (state, action) => {
      state.data = action?.payload.data
    },
    setCategory: (state, action) => {
      state.category = action?.payload.category
    },
    setSubCategory: (state, action) => {
      state.subCategory = action?.payload.subCategory
    },
    setRecommendationFilter: (state, action) => {
      state.recommendationFilter = action?.payload
    },
    setRecommendationTab: (state, action) => {
      state.recommendationTab = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getResearchConfigActions.success, (state, { payload }) => {
      const { unifiedCounts, configTs } = payload
      const { subCategoryCounts } = unifiedCounts
      state.configData = subCategoryCounts || []
      state.configTs = configTs || ''
      const pathArray = window.location.pathname.split('/')
      const path = pathArray[pathArray.length - 1].replace('%20', ' ')
      const category = state.configData.find(
        (obj: any) => obj.subCategory === path
      )
      state.category = category || {}
    })

    builder.addCase(getResearchDataActions.success, (state, { payload }) => {
      const { entries } = payload
      const { entry } = entries
      const { data, recommendationFilter } = state
      const { sortBy } = recommendationFilter
      const areArraysDeepEqual = _isEqual(data, entry)
      if (!areArraysDeepEqual && state?.data?.length) {
        state.data = [...state.data, ...entry]
        state.data = sortRecommendation(state.data, sortBy)
      } else {
        state.data = entry
        state.data = sortRecommendation(state.data, sortBy)
      }
    })

    builder.addCase(
      getSimilarResearchDataActions.success,
      (state, { payload }) => {
        const { entries } = payload
        const { entry } = entries
        state.similarResearchData = entry
      }
    )

    builder.addCase(
      getDetailsResearchDataActions.success,
      (state, { payload }) => {
        const { entries = {} } = payload || {}
        const { entry } = entries || {}
        state.data = entry
      }
    )
  }
}

const searchSlice = createSlice(sliceOptions)

export const {
  setResearchData,
  setCategory,
  setSubCategory,
  setRecommendationFilter,
  setRecommendationTab
} = searchSlice.actions

export default searchSlice.reducer
