import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useUtils } from '@mui/x-date-pickers/internals';
import { useThemeProps } from '@mui/system';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { DsStack, DsTypography, DsIconButton, DsRemixIcon } from '../../../Components';
export const DefaultToolbar = React.forwardRef(function DatePickerToolbar(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'MuiDatePickerToolbar' });
    const { value, ownerState } = props;
    const { onCancel } = ownerState;
    const translations = useLocaleText();
    const utils = useUtils();
    const dateText = (value && `: ${utils.formatByString(value, utils.formats.fullDate)}`) || '';
    return (_jsx(DsStack, { sx: {
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            padding: 'var(--ds-spacing-cool) var(--ds-spacing-bitterCold)',
            gridArea: '1 / 2 / auto / 4',
            borderRadius: '16px 16px 0px 0px',
            borderBottom: '1px solid var(--ds-colour-strokeDefault)'
        }, children: _jsxs(DsTypography, { sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }, variant: "headingBoldExtraSmall", children: [`${translations.datePickerToolbarTitle}${dateText}`, _jsx(DsIconButton, { onClick: onCancel, children: _jsx(DsRemixIcon, { className: "ri-close-line" }) })] }) }));
});
