// DONE - CLEANUP: @vasi
// 1. define types

import React, { Component } from 'react'
import {
  DsBox,
  DsButton,
  DsChip,
  DsFab,
  DsRemixIcon,
  DsStack,
  DsTypography,
  enqueueNotistack
} from '@am92/react-design-system'

import SearchStocks from '~/src/Components/Sidebar/Components/Search'

import SipHeader from './SipHeader'
import SipScriptHeader from './SipScriptHeader'
import SipScriptsDetails from './SipScriptsDetails'

import {
  updateIsEditingSip,
  updateModifySipPopup
} from '~/src/Redux/Indicators/Reducer'
import {
  isModifingSelector,
  openModifySip
} from '~/src/Redux/Indicators/Selectors'
import {
  updateSelectedScriptsData,
  updateSipSelectedScript
} from '~/src/Redux/Orders/reducer'
import { SIPClevertap } from '~/src/Pages/Orderbook/SIP.ClevertapEvents'

import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

type ActionTypes = {
  updateOpenModify: (item: any) => any
  updateSelectedScriptsData: (item: any) => any
  updateIsEditingSip: (item: boolean) => any
  updateSelectedScripts: (data: any[]) => Promise<any>
}

export interface ISipScriptsPageProps {
  selectedSipScripts: any
  sipInfo: any
  subscribeLtpData: (scriptArray: any[]) => void
  unSubscribeLtpData: (scriptArray: any[]) => void
  setShowSearch: (scriptId: boolean) => void
  showSelecetStock: any
  setShowSelecetStock: any
  openModify: boolean
  isModifing: boolean
  showSearch: boolean
  isDesktop: boolean
  actions: ActionTypes
}
class SipScriptsPage extends Component<ISipScriptsPageProps> {
  handleInitialise = () => {}

  componentDidUpdate(prevProps: ISipScriptsPageProps) {
    const { selectedSipScripts, subscribeLtpData, actions } = this.props
    const filteredScripts = this.handleGetFilteredData()

    if (selectedSipScripts.length !== filteredScripts.length) {
      actions.updateSelectedScripts(filteredScripts)
    }

    if (selectedSipScripts.length) {
      subscribeLtpData(filteredScripts)
    }
  }

  componentWillUnmount() {
    const { selectedSipScripts, unSubscribeLtpData } = this.props
    selectedSipScripts.length && unSubscribeLtpData(selectedSipScripts)
  }

  handleClose = () => {
    const { setShowSelecetStock } = this.props
    setShowSelecetStock(false)
  }

  handleOnproceed = () => {
    const { actions, sipInfo } = this.props
    const { selectedScripts = [], orgSelectedScripts = [] } = sipInfo
    new SIPClevertap().onClickNextOrBack(sipInfo, 'Proceed')
    actions.updateOpenModify(true)
    const result = this.compareArrays(orgSelectedScripts, selectedScripts)
    const updatedSipInfo = {
      ...sipInfo,
      orgSelectedScripts: result || []
    }
    actions.updateSelectedScriptsData(updatedSipInfo)
  }

  compareArrays = (arr1: any, arr2: any) => {
    const result = []
    const processedNames = new Set()

    if (arr1.length === 0) {
      return arr2.map((obj: any) => ({ ...obj, action: 'Add' }))
    }

    for (const obj1 of arr1) {
      const matchingObj2Index = arr2.findIndex(
        (obj: any) => obj?.scriptId === obj1?.scriptId
      )
      let matchingObj2

      if (matchingObj2Index === -1 || processedNames.has(obj1?.scriptId)) {
        result.push({ ...obj1, action: 'Delete' })
      } else {
        processedNames.add(obj1?.scriptId)
        matchingObj2 = arr2[matchingObj2Index]

        if (matchingObj2 && matchingObj2?.quantity !== obj1?.quantity) {
          result.push({ ...matchingObj2, action: 'Change' })
        } else {
          result.push({ ...matchingObj2, action: 'Unchanged' })
        }
      }
    }

    for (const obj2 of arr2) {
      if (!processedNames.has(obj2.scriptId)) {
        result.push({ ...obj2, action: 'Add' })
        processedNames.add(obj2.scriptId)
      }
    }

    return result
  }

  handleDisableProceedButton = () => {
    // Check if any script has quantity or amount less than or equal to 0
    //  or is missing so will disable the proceed button
    const { selectedSipScripts } = this.props
    return selectedSipScripts.some((script: { quantity: any; amount: any }) => {
      const quantity = Number(script.quantity)
      const amount = Number(script.amount)
      return quantity <= 0 || isNaN(quantity) || amount <= 0 || isNaN(amount)
    })
  }

  handleGetFilteredData = (): { scriptId: string; coname: string }[] => {
    const { selectedSipScripts } = this.props
    const scriptIds: { [key: string]: boolean } = {}
    const notifiedScripts: { [key: string]: boolean } = {}
    const filteredScripts = selectedSipScripts.filter(
      ({ scriptId, coname }: any) => {
        if (scriptIds[scriptId]) {
          if (!notifiedScripts[scriptId]) {
            enqueueNotistack({
              message: `${coname} Already added`,
              autoHideDuration: 2000
            })
            notifiedScripts[scriptId] = true
          }
          return false
        }
        scriptIds[scriptId] = true
        return true
      }
    )

    return filteredScripts
  }

  render() {
    const {
      selectedSipScripts,
      sipInfo,
      showSelecetStock,
      setShowSelecetStock,
      openModify,
      actions,
      isModifing,
      setShowSearch,
      showSearch,
      isDesktop
    } = this.props

    const { basedOn = '', sipName } = sipInfo

    const totalHeading =
      basedOn.toLowerCase() === 'amount' ? 'Total Amount' : 'Total Quantity'

    const totalValue = selectedSipScripts
      ?.filter((item: { action: string }) => item?.action !== 'Delete')
      .reduce(
        (total: number, item: any) =>
          total + (parseFloat(item?.[basedOn?.toLowerCase()]) || 0),
        0
      )

    return (
      <>
        <DsBox
          sx={{
            position: 'relative',
            background: 'var(--ds-colour-surfaceSecondary)'
          }}
        >
          {showSearch && !isDesktop && (
            <>
              <DsStack
                sx={{
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  padding:
                    'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold) var(--ds-spacing-mild) var(--ds-spacing-bitterCold)'
                }}
              >
                <DsRemixIcon
                  onClick={() => setShowSearch(false)}
                  className='ri-arrow-left-line'
                  fontSize='mild'
                  sx={{
                    cursor: 'pointer'
                  }}
                />
                <DsTypography variant='headingBoldSmall'>
                  Add Scrips to {sipName}
                </DsTypography>
              </DsStack>

              {showSearch && (
                <SearchStocks
                  type={'sip'}
                  setOpen={setShowSearch}
                  open={showSearch}
                />
              )}
            </>
          )}
        </DsBox>

        <DsBox sx={{ position: 'relative' }}>
          <DsBox
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              width: { md: '25rem', xs: '100vw' },
              animation: 'reverse',
              overflow: 'hidden',
              overscrollBehavior: 'contain'
            }}
          >
            <SipHeader type={'Add Stock'} />
            <DsStack
              sx={{
                width: '100%'
              }}
            >
              <SipScriptHeader
                updateIsEditingSip={actions.updateIsEditingSip}
                openModify={openModify}
                sipInfo={sipInfo}
                setShowSelecetStock={setShowSelecetStock}
                showSelecetStock={showSelecetStock}
              />

              <DsStack
                sx={{
                  padding: 'var(--ds-spacing-bitterCold)',
                  paddingBottom: 'var(--ds-spacing-superheated)'
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    mb: 'var(--ds-spacing-bitterCold)'
                  }}
                >
                  <DsChip
                    label='Buy'
                    variant='filled'
                    type='nudge'
                    color='success'
                  />
                  <DsTypography variant='supportRegularMetadata'>
                    {basedOn}
                  </DsTypography>
                </DsBox>

                {this.handleGetFilteredData().length &&
                  this.handleGetFilteredData().map(
                    (item: any, index: number) => {
                      const { action = '' } = item
                      if (action === 'Delete') {
                        return
                      }
                      return (
                        <React.Fragment key={index}>
                          <SipScriptsDetails
                            isModifing={isModifing}
                            openModify={openModify}
                            selectedSipScripts={selectedSipScripts}
                            sipInfo={sipInfo}
                            item={item}
                            updateSelectedScriptsData={
                              actions.updateSelectedScriptsData
                            }
                          />
                        </React.Fragment>
                      )
                    }
                  )}
              </DsStack>
            </DsStack>

            <DsBox
              sx={{
                position: 'fixed',
                bottom: '0',
                padding: 'var(--ds-spacing-bitterCold)',
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                width: '-webkit-fill-available',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: 'var(--ds-colour-surfaceBackground)'
              }}
            >
              <DsStack gap={'2px'}>
                <DsTypography variant='supportRegularInfo'>
                  {totalHeading}
                </DsTypography>
                <DsBox
                  sx={{ display: 'flex', gap: '2px', alignItems: 'center' }}
                >
                  <DsTypography variant='bodyBoldLarge'>
                    {totalValue}
                  </DsTypography>
                  |
                  <DsTypography variant='supportRegularInfo'>
                    {selectedSipScripts.length} Stocks
                  </DsTypography>
                </DsBox>
              </DsStack>
              <DsButton
                onClick={this.handleOnproceed}
                size='medium'
                disabled={this.handleDisableProceedButton()}
              >
                Proceed
              </DsButton>
            </DsBox>
          </DsBox>
        </DsBox>

        {selectedSipScripts.length <= 9 && (
          <DsFab
            onClick={() => setShowSearch(true)}
            sx={{
              position: 'fixed',
              bottom: 'var(--ds-spacing-meltdown)',
              right: 'var(--ds-spacing-bitterCold)'
            }}
            size='medium'
          >
            <DsRemixIcon className='ri-add-line' />
          </DsFab>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const openModify = openModifySip(state)
  const isModifing = isModifingSelector(state)

  return {
    openModify,
    isModifing
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    updateOpenModify: (requestData: boolean) =>
      dispatch(updateModifySipPopup(requestData)),
    updateSelectedScriptsData: (requestData: any) =>
      dispatch(updateSelectedScriptsData(requestData)),
    updateIsEditingSip: (requestData: boolean) =>
      dispatch(updateIsEditingSip(requestData)),
    updateSelectedScripts: (val: any) => dispatch(updateSipSelectedScript(val))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SipScriptsPage)
)
