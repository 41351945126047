import React, { Component } from 'react'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsListItemButton,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import ManageWatchlist from './Components/ManageWatchlist'
import SearchStocks from './Components/Search'
import WatchList from '~/src/Components/Sidebar/Components/WatchList'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import {
  getSecuritiesLength,
  getSelectedWatchlistTab,
  getWatchlist
} from '~/src/Redux/WatchList/Selectors'
import { selectWatchlistTab } from '~/src/Redux/WatchList/Reducer'
import createWatchlistAction, {
  createWatchlistPayload
} from '~/src/Redux/WatchList/Services/CreateWatchlist.Service'
import { To } from 'react-router-dom'
import CreateWatchlist from '../ManageWatchlist/CreateWatchlist'
import FabIcon from './Components/FabIcon'
import SidebarContainer from './Components/SidebarContainer'
import SortWatchListScriptsWrapper from './Components/SortWatchListScriptsWrapper'
import { getDeepLinkData, triggerCleverTapEvent } from '~/src/Utils/global'
import { getDeepLinkAttributes } from '~/src/Redux/AppState/selectors'
import { DEEP_LINK_KEYS } from '~/src/Constants/APP_CONSTANTS'
import { TDeepLink } from '~/src/Redux/AppState/reducer'

export type T_SET_SORT_SCRIPT_OPTION_DATA = {
  sortScriptOption: number
  watchlistId: string
}

type ActionTypes = {
  createWatchlist: (reqData: createWatchlistPayload) => Promise<any>
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
}

export interface IrenderTabObj {
  [index: string]: string
  label: string
  id: string
}

export interface ISidebarProps extends IWithRouterProps {
  breakpoints: any
  actions: ActionTypes
  selectedWatchlistTab: string | number
  open: boolean
  currentWatchlistId: string
  currentWatchlistType: string
  currentSecuritiesLength: number
}

class Sidebar extends Component<ISidebarProps> {
  state = {
    manageWatchlist: this.props.selectedWatchlistTab === 13 ? true : false,
    open: false,
    watchlistCreated: false,
    isUserScrollingDown: false,
    oldScrollY: 0
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  setOpen = (e: boolean) => {
    this.setState({ open: e })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.controlDirection)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', e => this.controlDirection)
  }

  controlDirection = () => {
    const { oldScrollY } = this.state
    if (window.scrollY > oldScrollY) {
      this.setState({ isUserScrollingDown: true })
    } else {
      this.setState({ isUserScrollingDown: false })
    }
    this.setState({ oldScrollY: window.scrollY })
  }

  handleManageWatchlist = () => {
    const customAttributes = {
      'Event_Status': 'Pass',
      'Action': 'Manage'
    }
    triggerCleverTapEvent('Watchlist', customAttributes)
    this.setState({ manageWatchlist: true }, () => {
      window.scrollTo(0, 0)
    })
  }

  handleSelectedWatchlistTab = (selectedTab: string) => {
    const { actions } = this.props
    if (selectedTab === '13') {
      this.setState({ manageWatchlist: true })
    } else {
      this.setState({ manageWatchlist: false })
    }
    actions.setSelectedWatchlistTab(+selectedTab)
  }

  handleTabScrollingOnNewWatchlistCreation = (isWatchlistCreated: boolean) => {
    this.setState({ watchlistCreated: isWatchlistCreated })
  }

  renderWatchlistAndManageWatchlist = () => {
    return <></>
  }

  render(): React.ReactNode {
    const {
      breakpoints = {},
      selectedWatchlistTab,
      currentWatchlistType,
      currentSecuritiesLength
    } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    const { manageWatchlist, open, watchlistCreated, isUserScrollingDown } =
      this.state
    const isAnyStockAvailable = currentSecuritiesLength
    const watchListType = currentWatchlistType || ''

    return (
      <>
        <DsStack sx={{ width: '100%', height: '100%' }}>
          {isDesktop && (
            <SearchStocks
              isShortcutSearch={false}
              setOpen={this.setOpen}
              open={open}
            />
          )}
          {!manageWatchlist || !isDesktop ? (
            <DsBox
              sx={{
                backgroundColor: 'var(--ds-colour-surfaceBackground)',
                mt: {
                  md: 'var(--ds-spacing-bitterCold)',
                  xs: 'var(--ds-spacing-zero)'
                },
                borderRadius:
                  'var(--ds-radius-glacial) var(--ds-radius-glacial) var(--ds-radius-zero) var(--ds-radius-zero)',
                position: { md: 'unset', xs: 'sticky' },
                zIndex: { md: 'unset', xs: '1' },
                top: {
                  md: 'unset',
                  xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze))'
                }
              }}
            >
              <SidebarContainer
                selectedWatchlistTab={selectedWatchlistTab}
                handleSelectedWatchlistTab={this.handleSelectedWatchlistTab}
                watchlistCreated={watchlistCreated}
              />
            </DsBox>
          ) : (
            ''
          )}
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              height: {
                md: !manageWatchlist ? '70vh' : 'unset',
                xs: !manageWatchlist ? '100vh' : 'unset'
              },
              backgroundColor: 'var(--ds-colour-surfaceBackground)',
              position: 'relative',
              marginTop:
                manageWatchlist && isDesktop
                  ? 'var(--ds-spacing-bitterCold)'
                  : 'var(--ds-spacing-zero)',
              borderRadius:
                'var(--ds-radius-glacial) var(--ds-radius-glacial) var(--ds-radius-zero) var(--ds-radius-zero)'
            }}
          >
            <DsBox
              sx={{
                overflowY: { xs: 'unset', md: 'auto' },
                height: { xs: 'unset', md: 'auto' },
                overscrollBehavior: { xs: 'unset', md: 'contain' }
              }}
            >
              {manageWatchlist ? (
                <>
                  {isDesktop && (
                    <DsBox
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 'var(--ds-spacing-bitterCold)',
                        gap: 'var(--ds-spacing-glacial)',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'var(--ds-colour-surfaceBackground)',
                        zIndex: 2
                      }}
                    >
                      <DsRemixIcon
                        onClick={() => {
                          const { actions, selectedWatchlistTab } = this.props
                          actions.setSelectedWatchlistTab(
                            selectedWatchlistTab === 13 ||
                              selectedWatchlistTab === '13'
                              ? 0
                              : selectedWatchlistTab
                          )
                          this.setState({ manageWatchlist: false })
                        }}
                        className='ri-arrow-left-line'
                        sx={{
                          cursor: 'pointer'
                        }}
                      />
                      <DsTypography variant='bodyBoldLarge'>
                        {
                          WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION
                            .MANAGE_WATCHLIST_LABEL
                        }
                      </DsTypography>
                    </DsBox>
                  )}

                  <DsDivider
                    sx={{
                      position: 'sticky',
                      top: {
                        md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-glacial))',
                        xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-molten) - var(--ds-spacing-glacial))'
                      },
                      zIndex: 3
                    }}
                  />
                  <ManageWatchlist />
                </>
              ) : (
                <WatchList
                  selectedWatchlistTab={+selectedWatchlistTab}
                  handleManageWatchList={this.handleManageWatchlist}
                  setOpen={this.setOpen}
                />
              )}
            </DsBox>
            {isDesktop ? (
              <DsBox
                sx={{
                  position: 'sticky',
                  width: '100%',
                  bottom: 0,
                  border: manageWatchlist
                    ? 'none'
                    : '1px solid var(--ds-colour-strokeDefault)',
                  borderTopLeftRadius: 'var(--ds-spacing-glacial)',
                  borderTopRightRadius: 'var(--ds-spacing-glacial)',
                  backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                  zIndex: 2
                }}
              >
                <DsGrid container>
                  {!manageWatchlist ? (
                    <>
                      {isAnyStockAvailable ? (
                        <SortWatchListScriptsWrapper />
                      ) : null}
                      <DsGrid item xs={isAnyStockAvailable ? 4 : 6}>
                        <CreateWatchlist
                          componentType='sidebar'
                          handleTabScrollingOnNewWatchlistCreation={
                            this.handleTabScrollingOnNewWatchlistCreation
                          }
                        />
                      </DsGrid>
                      <DsGrid item xs={isAnyStockAvailable ? 4 : 6}>
                        <DsListItemButton
                          sx={{
                            display: 'block',
                            p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold) var(--ds-spacing-frostbite) var(--ds-spacing-zero)'
                          }}
                          onClick={this.handleManageWatchlist}
                        >
                          <DsBox
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              textAlign: 'center',
                              justifyContent: 'center',
                              gap: 'var(--ds-spacing-frostbite)'
                            }}
                          >
                            <DsRemixIcon
                              className='ri-settings-4-line'
                              fontSize='mild'
                            />
                            <DsTypography variant='bodyRegularLarge'>
                              {WATCHLIST_LABEL.BOTTOM_BUTTONS.MANAGE_WATCHLIST}
                            </DsTypography>
                          </DsBox>
                        </DsListItemButton>
                      </DsGrid>
                    </>
                  ) : (
                    ''
                  )}
                </DsGrid>
              </DsBox>
            ) : isAnyStockAvailable &&
              !isDesktop &&
              +selectedWatchlistTab !== 13 ? (
              <DsBox
                sx={{
                  display:
                    !isUserScrollingDown && !isDesktop ? 'block' : 'none',
                  position: 'sticky',
                  bottom: 64,
                  border: '1px solid var(--ds-colour-strokeDefault)',
                  backgroundColor: 'var(--ds-colour-surfaceSecondary)'
                }}
              >
                <DsGrid container>
                  <SortWatchListScriptsWrapper />
                  <DsGrid item xs={6}>
                    <CreateWatchlist
                      componentType='sidebar'
                      handleTabScrollingOnNewWatchlistCreation={
                        this.handleTabScrollingOnNewWatchlistCreation
                      }
                    />
                  </DsGrid>
                </DsGrid>
              </DsBox>
            ) : (
              ''
            )}
          </DsBox>
          {isAnyStockAvailable &&
          !isDesktop &&
          +selectedWatchlistTab !== 13 &&
          watchListType !== 'PRE-DEFINED' ? (
            <DsBox
              sx={{
                position: 'fixed',
                bottom: '150px',
                right: 'var(--ds-spacing-warm)'
              }}
            >
              <FabIcon selectedWatchlistTab={selectedWatchlistTab} />
            </DsBox>
          ) : (
            ''
          )}
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const { currentWatchlistId, currentWatchlistType } =
    getWatchlist(selectedWatchlistTab)(state) || {}
  const currentSecuritiesLength =
    getSecuritiesLength(selectedWatchlistTab)(state)
  return {
    selectedWatchlistTab,
    currentWatchlistId,
    currentWatchlistType,
    currentSecuritiesLength
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createWatchlist: (requestData: createWatchlistPayload) =>
      dispatch(createWatchlistAction(requestData)),
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      )
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(Sidebar)
)
