import React, { Component } from 'react'
import { DsBox, DsDivider, DsDrawer } from '@am92/react-design-system'

import OrderFormSection from './Components/OrderFormSection'
import OrderHeader from './Components/OrderHeader'

import { getOrderPageModalIndicator } from '~/src/Redux/Orders/selectors'

import { TAppStore } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

export interface IOrderModalProps extends IWithRouterProps {
  orderPageModalIndicator: boolean
}

class OrderModal extends Component<IOrderModalProps> {
  render() {
    const { orderPageModalIndicator } = this.props
    return (
      <>
        <DsDrawer
          PaperProps={{
            sx: {
              top: {
                md: '4.8rem',
                lg: '5.4rem',
                xs: '0%'
              },
              height: {
                md: 'calc(100% - 4.2rem)',
                lg: 'calc(100% - 5.4rem)',
                xs: '100%'
              },
              boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)'
            }
          }}
          sx={theme => ({ position: 'absolute', zIndex: theme.zIndex.drawer })}
          anchor='right'
          open={orderPageModalIndicator}
          variant='persistent'
          hideBackdrop
        >
          {orderPageModalIndicator && (
            <>
              <OrderHeader />
              <DsDivider />
              <DsBox
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: { md: '25rem', xs: '100vw' },
                  animation: 'reverse',
                  overflow: 'hidden',
                  overscrollBehavior: 'contain'
                }}
              >
                <OrderFormSection />
              </DsBox>
            </>
          )}
        </DsDrawer>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const orderPageModalIndicator = getOrderPageModalIndicator(state)
  return {
    orderPageModalIndicator
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(OrderModal)
