import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from '~/src/Redux/Customer/Selectors'
import {
  getBankBalanceActions,
  getCustomerProfileDetailsActions,
  getEmarginDetailsActions,
  getPartnersListActions,
  getReportsActions,
  getResearchPartnersListActions
} from '~/src/Redux/Customer/Actions'
import { _groupBy } from '~/src/Utils/lodash'

export interface IPartner {
  _id: string
  category: string
  description: string
  imageUrl: {
    dark: string
    light: string
  }
  redirectUrl: string
  showOnResearch: boolean
  ssoType: string
  title: string
}

export type IPartnersList = {
  [category: string]: IPartner[]
}

const INITIAL_STATE = {
  customerProfile: {},
  bankbalance: {},
  emarginDetails: {},
  partners: {},
  formattedPartners: {},
  reports: [],
  researchPartners: [],
  selectedPartner: '',
  openPartnerSearch: false,
  searchString: '',
  selectedFundsSegment: 'equities'
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    resetEmarginData: (state, action) => {
      state.emarginDetails = {}
    },

    setCustomerEmargin: (state, action) => {
      const { payload } = action
      state.customerProfile['productsAllowed']['emargin']['isActive'] = payload
    },
    updateSelectedPartner: (state, action) => {
      const { selectedPartner, partnersList } = action.payload
      state.selectedPartner = selectedPartner

      const formattedList = {
        [selectedPartner]: partnersList?.[selectedPartner]
      }
      state.formattedPartners = formattedList
    },
    updateOpenPartnerSearch: (state, action) => {
      const isSearchMode = action.payload
      if (isSearchMode) {
        state.formattedPartners = {}
      } else {
        state.formattedPartners = state.partners
      }
      state.openPartnerSearch = action.payload
    },
    updateSelectedFundsSegment: (state, action) => {
      state.selectedFundsSegment = action.payload
    },
    updateSearchString: (state, action) => {
      const {
        searchString,
        partnersList
      }: { searchString: string; partnersList: IPartnersList } = action.payload

      state.searchString = searchString

      const flatPartnersArray: IPartner[] = Object.values(partnersList).flat()

      const filteredPartners = flatPartnersArray.filter((item: IPartner) =>
        item?.title?.toLowerCase().includes(searchString.toLowerCase())
      )
      const groupedResults: IPartnersList = _groupBy(
        filteredPartners,
        'category'
      )

      state.formattedPartners = groupedResults
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getCustomerProfileDetailsActions.success,
      (state, { payload }) => {
        state.customerProfile = payload
      }
    )
    builder.addCase(getBankBalanceActions.success, (state, { payload }) => {
      state.bankbalance = payload
    })
    builder.addCase(getPartnersListActions.success, (state, { payload }) => {
      // Group the partners by category
      const groupedPartners = _groupBy(payload, 'category') || {}
      const initialSelectedPartner = Object.keys(groupedPartners)[0] || ''

      state.partners = groupedPartners
      state.selectedPartner = initialSelectedPartner
      state.formattedPartners = groupedPartners
    })
    builder.addCase(
      getResearchPartnersListActions.success,
      (state, { payload }) => {
        state.researchPartners = payload
      }
    )
    builder.addCase(getReportsActions.success, (state, { payload }) => {
      state.reports = payload
    })
    builder.addCase(getEmarginDetailsActions.success, (state, { payload }) => {
      state.emarginDetails = payload
    })
  }
}

const slice = createSlice(sliceOptions)

export const {
  resetEmarginData,
  setCustomerEmargin,
  updateSelectedPartner,
  updateOpenPartnerSearch,
  updateSearchString,
  updateSelectedFundsSegment
} = slice.actions

export default slice.reducer
