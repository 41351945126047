// DONE - CLEANUP: @vasi
// 1. define types
import React from 'react'
// Lib
import { To } from 'react-router-dom'
// DS Components
import {
  DsAvatar,
  DsBox,
  DsButton,
  DsButtonBase,
  DsList,
  DsMenu,
  DsRemixIcon,
  DsStack,
  DsTypography,
  SupportedColorScheme
} from '@am92/react-design-system'

import GlobalMenu from '../GlobalMenu'
import Notifications from '../Notifications/NotificationPopover'
import NriDialog from '../NRI/NriDialog'

import { resetDeepLinkData, TDeepLink } from '~/src/Redux/AppState/reducer'
import { getDeepLinkAttributes } from '~/src/Redux/AppState/selectors'
// redux
import {
  getCommodityValueSelector,
  getEquityValueSelector
} from '~/src/Redux/Inquiry/Selectors'
import getNotificationsAction from '~/src/Redux/Notifications/Services/getNotifications.Service'
import {
  getNRIType,
  getRequiredCustomerDetailForNRI,
  isNRICustomer
} from '~/src/Redux/SSO/Selectors'
import { getThemeReducer } from '~/src/Redux/Theme/Selectors'

import { DEEP_LINK_KEYS, NRI_TYPES } from '~/src/Constants/APP_CONSTANTS'
// utils
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { TAppDispatch, TAppStore } from '~/src/Configurations/AppStore'
import { getCurrencyFormat } from '~/src/Utils/global'

import withColorScheme, {
  IWithColorSchemeProps
} from '~/src/Lib/withColorScheme'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'

export interface IHeaderRightMenuActionProps
  extends IWithRouterProps,
    IWithColorSchemeProps {
  showMenu: boolean
  anchorEl: any
  setAnchorEl: (data: any) => void
  setShowMenu: (data: boolean) => void
  handleError: (data: any) => void
  commodityValue: number
  equityValue: number
  actions: ActionTypes
  deepLinkAttributes: TDeepLink
  theme: SupportedColorScheme
  NRIType: string
  isNRI: boolean
  name: string
  subAccountId: string
}

type ActionTypes = {
  resetDeepLinkData: () => Promise<any>
  getNotifications: (data: any) => void
}

type IHeaderRightMenuActionStates = {
  showMenu: boolean
  anchorEl: null
  showNotification: boolean
  anchorNotification: null
  openNRISelectionPage: boolean
}

class HeaderRightMenuAction extends React.Component<
  IHeaderRightMenuActionProps,
  IHeaderRightMenuActionStates
> {
  ref: React.RefObject<any>
  constructor(props: IHeaderRightMenuActionProps) {
    super(props)
    this.state = {
      showMenu: false,
      anchorEl: null,
      showNotification: false,
      anchorNotification: null,
      openNRISelectionPage: false
    }
    this.ref = React.createRef()
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.handleDeepLink()
    }, 100)
  }

  handleDeepLink = () => {
    const { deepLinkAttributes, actions } = this.props
    if (deepLinkAttributes) {
      const { path } = deepLinkAttributes
      if (path === DEEP_LINK_KEYS.SETTING) {
        const avatarIconDiv = this.ref.current
        avatarIconDiv?.click()
        actions.resetDeepLinkData()
      }
    }
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  setShowMenu = (value: boolean) => {
    this.setState({ showMenu: value })
  }

  setAnchorEl = (value: any) => {
    this.setState({ anchorEl: value.currentTarget })
  }
  setShowNotification = (value: boolean) => {
    this.setState({ showNotification: value })
  }

  setAnchorNotification = (value: any) => {
    this.setState({ anchorNotification: value.currentTarget })
  }

  handleRedirectTo = (to: string) => {
    const { navigateTo } = this.props

    if (to === 'pledge') {
      navigateTo(APP_ROUTES.PLEDGE.pathname)
      return
    }
    if (to === 'funds') {
      navigateTo(APP_ROUTES.FUNDSDASHBOARD.pathname)
      return
    }
  }

  handleAvatarClick = (event: any) => {
    this.setShowMenu(true)
    this.setAnchorEl(event)
  }

  handleNotificationClick = async (event: any) => {
    this.setShowNotification(true)
    this.setAnchorNotification(event)
  }

  handleOnClickNRIButton = () => {
    const { openNRISelectionPage } = this.state
    this.setState({ openNRISelectionPage: !openNRISelectionPage })
  }

  render() {
    const {
      showMenu,
      anchorEl,
      anchorNotification,
      showNotification,
      openNRISelectionPage
    } = this.state
    const {
      commodityValue = 0,
      equityValue = 0,
      theme,
      NRIType,
      isNRI
    } = this.props

    const totalAvalFunds = equityValue + commodityValue

    const HeaderFunds =
      !totalAvalFunds && totalAvalFunds !== 0
        ? 'Funds : '
        : `Funds : ${getCurrencyFormat(totalAvalFunds)}`

    const formattedType =
      Object.keys(NRI_TYPES)
        .find(key => NRI_TYPES[key] === Number(NRIType))
        ?.replace(/-/g, ' ') || ''

    return (
      <>
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          {isNRI && (
            <DsButton
              sx={{ width: 'max-content' }}
              onClick={this.handleOnClickNRIButton}
              color='primary'
            >
              {formattedType}
            </DsButton>
          )}
          <DsButtonBase>
            <DsStack
              sx={{
                p: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
                border: '1px solid var(--ds-colour-strokeDefault)',
                backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                borderRadius: 'var(--ds-radius-glacial)'
              }}
              onClick={() => this.handleRedirectTo('funds')}
            >
              <DsTypography
                variant='bodyBoldSmall'
                sx={{
                  whiteSpace: 'nowrap',
                  color: 'var(--ds-colour-actionPrimary)',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {HeaderFunds}
                {!totalAvalFunds && totalAvalFunds !== 0 && (
                  <DsRemixIcon
                    fontSize='bitterCold'
                    className='ri-error-warning-line'
                    color='primary'
                  />
                )}
              </DsTypography>
            </DsStack>
          </DsButtonBase>
          <DsBox
            sx={{
              borderRadius: '0.25rem',
              height: '2.5rem',
              width: '2.5rem',
              background: showNotification
                ? theme == 'light'
                  ? 'rgba(100%, 0%, 0%, 8%)'
                  : 'rgba(100%, 0%, 0%, 25%)'
                : 'var(--ds-colour-surfacePrimary)',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 4
            }}
            onClick={this.handleNotificationClick}
          >
            <DsRemixIcon className='ri-notification-2-line' />
          </DsBox>
          <DsMenu
            sx={{
              maxWidth: '100%',
              '& .MuiPaper-root': {
                width: '28rem',
                right: '35px',
                top: '83px !important',
                borderRadius: '4px'
              },
              '& .MuiMenu-list': {
                pb: '0px',
                pt: '0px'
              },
              '& .MuiList-root': {
                pb: '0px',
                pt: '0px'
              }
            }}
            keepMounted={false}
            anchorEl={anchorNotification}
            id='notification'
            open={showNotification}
            onClose={() => this.setShowNotification(false)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <DsList>
              <Notifications
                toggle={showNotification}
                setShowNotification={this.setShowNotification}
              />
            </DsList>
          </DsMenu>
          <DsAvatar
            sx={{
              cursor: 'pointer'
            }}
            onClick={this.handleAvatarClick}
            ref={this.ref}
          />
          <DsMenu
            sx={{
              maxWidth: '100%',
              '& .MuiPaper-root': { minWidth: 300 }
            }}
            anchorEl={anchorEl}
            id='basic-menu'
            open={showMenu}
            onClose={() => this.setShowMenu(false)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <GlobalMenu setShowMenu={this.setShowMenu} />
          </DsMenu>
        </DsBox>
        <NriDialog
          open={openNRISelectionPage}
          onClick={this.handleOnClickNRIButton}
        />
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const commodityValue = Number(getCommodityValueSelector(state))
  const equityValue = Number(getEquityValueSelector(state))
  const deepLinkAttributes = getDeepLinkAttributes(state)
  const NRIType = getNRIType(state)
  const isNRI = isNRICustomer(state)
  const customerDetail = getRequiredCustomerDetailForNRI(state)
  const { customerName: name, subAccountId } = customerDetail
  const { scheme: theme } = getThemeReducer(state)
  return {
    commodityValue,
    equityValue,
    deepLinkAttributes,
    theme,
    NRIType,
    isNRI,
    name,
    subAccountId
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    resetDeepLinkData: () => dispatch(resetDeepLinkData(null)),
    getNotifications: () =>
      dispatch((data: any) => getNotificationsAction(data))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(withColorScheme(HeaderRightMenuAction))
