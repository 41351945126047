import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  getOrderBookActions,
  placeOrderActions,
  modifyOrderActions,
  cancelOrderActions,
  getRequiredMarginActions,
  getByOmsOrderIDActions,
  getTaxAndChargesActions,
  getSipOrderBookActions,
  getSipOrderDetailsActions
} from './actions'
import { SLICE_NAME } from './selectors'
import { ssoAuthenticateActions } from '../SSO/Actions'
import localforage from 'localforage'
import { STATUS_MAPPER } from '~/src/Pages/Orderbook/Orderbook.constants'
import { addDays, format } from 'date-fns'
import { INITIAL_STATE } from './TYPES'
import { _groupBy, _assign } from '~/src/Utils/lodash'

export const DEFAULT_VALUES = {
  selectedSortBy: 'CREATED_DESCENDING'
}

// export type ISipInfo = {
//   sipName: string
//   startDate: string
//   frequency: string
//   duration: string
//   basedOn: string
//   selectedScripts: any[]
//   orgSelectedScripts: any[]
// }

// export type orderBookSingleOrder = {
//   orderRefId: string
//   omsOrderId: string
//   exchangeOrderId: string
//   exchange: string
//   scriptId: string
//   orderStatus: string
//   transactionType: string
//   product: string
//   orderType: string
//   tradedQty: number
//   totalQty: number
//   openQty: number
//   disclosedQty: number
//   orderPrice: number
//   validityType: string
//   symbol: string
//   series: string
//   instrument: string
//   expiryDate: string
//   optionType: string
//   omsOrderTS: string
//   omsOrderSerialNumber: string
//   goodTillDate: string
//   triggerPrice: number
//   isAmo: boolean
// }

// export type TOrderBook = orderBookSingleOrder[]

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateOrderPageModalIndicator: (state, action) => {
      const { ssoAuthentication } = state
      const { metadata } = ssoAuthentication
      const { errorCode = '' } = metadata || {}
      const orderFormData = action.payload
      const value = orderFormData.orderPageModalIndicator
      const isMarketDepthClicked = orderFormData.isMarketDepthClicked
      // && !isMarketDepthClicked

      // this check is for checking if there is any post login error from the user
      if (value && errorCode !== '') {
        state.showPostLoginError = true
        state.temporaryOrderData = orderFormData
        return
      }

      for (const key in orderFormData) {
        state[key] = orderFormData[key]
      }
    },
    updateSipInfo: (state, action) => {
      const { name, value } = action?.payload
      state.sipInfo[name] = value
    },
    replaceSipInfo: (state, action) => {
      state.sipInfo = action.payload
    },
    clearSipInfo: state => {
      state.sipInfo = {
        sipName: '',
        startDate: format(addDays(new Date(), 1), 'dd/MM/yy'),
        frequency: 'Monthly',
        duration: '1 Year',
        basedOn: 'quantity',
        selectedScripts: [],
        orgSelectedScripts: []
      }
    },
    updateSelectedScriptsData: (state, action) => {
      state.sipInfo = action.payload
    },
    updateAmtQtySelectedScriptsData: (state, action) => {
      action.payload
      const { formattedData, scriptId } = action.payload
      const { sipInfo } = state
      const { selectedScripts } = sipInfo
      const updatedSipInfo = {
        ...sipInfo,
        selectedScripts: selectedScripts.map((item: any) =>
          item.scriptId === scriptId ? _assign({}, item, formattedData) : item
        )
      }
      state.sipInfo = updatedSipInfo
    },
    updateOrgSipSelectedScript: (state, action) => {
      state.sipInfo.orgSelectedScripts = action.payload
    },
    updateSipSelectedScript: (state, action) => {
      state.sipInfo.selectedScripts = action.payload
    },
    updateDataOnExchangeChange: (state, action) => {
      const { orderScriptId, selectedExchange } = action?.payload
      state.orderScriptId = orderScriptId
      state.selectedExchange = selectedExchange
    },
    updateExchangeSelectorIndicator: (state, action) => {
      const { exchangeSelectorIndicator, orderScriptId } = action?.payload
      state.exchangeSelectorIndicator = exchangeSelectorIndicator
      state.orderScriptId = orderScriptId
    },
    setSelectedExchange: (state, action) => {
      const { selectedExchange } = action?.payload
      state.selectedExchange = selectedExchange
    },
    switchBuySellButton: (state, action) => {
      const orderBuyOrSell = action?.payload
      state.orderBuyOrSell = orderBuyOrSell
    },
    setCommonIsinCodeScriptArray: (state, action) => {
      state.commonIsinCodeScriptArray = action?.payload
    },
    resetOrderData: state => {
      state.orderFormData = INITIAL_STATE.orderFormData
    },
    changeOrderFormData: (state, action) => {
      const { key, data } = action.payload
      state.orderFormData[key] = data
    },
    modifyOrderFormData: (state, action) => {
      const orderFormData = action.payload
      for (const key in orderFormData) {
        state.orderFormData[key] = orderFormData[key]
      }
    },
    storeCurrentModifyOrderData: (state, action) => {
      state.currentModifyOrderData = action.payload
    },
    orderbookFilter: (state, action) => {
      state.orderbookFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    setSIPFilter: (state, action) => {
      state.SIPFilter = action?.payload
      // if (checkIfDefaultFiltersAreSet(state)) {
      //   state.isDefaultFiltersEnabled = true
      // } else {
      //   state.isDefaultFiltersEnabled = false
      // }
    },
    setOrderbook: (state, action) => {
      state.orderBook = action?.payload
    },
    setSipOrderbook: (state, action) => {
      const result = _groupBy(action?.payload, 'sipOrderStatus')
      state.sipOrderBook = result
      state.sipOrderBookFilter = result
    },
    handleOrderTradeBookAccordionState: (state, action) => {
      const { openOrderTradeBookAccordion } = state
      state.openOrderTradeBookAccordion = !openOrderTradeBookAccordion
      state.openOrderTrailAccordion = false
    },
    handleOrderTrailAccordionState: (state, action) => {
      const { openOrderTrailAccordion } = state
      state.openOrderTrailAccordion = !openOrderTrailAccordion
      state.openOrderTradeBookAccordion = false
    },
    handleResetingOrderDetailsModalStates: (state, action) => {
      state.openOrderTradeBookAccordion = true
      state.openOrderTrailAccordion = false
      state.orderTrailData = null
      state.tradeBookData = null
      state.orderRejectionReason = ''
    },
    handleClosePostLoginError: (state, action) => {
      state.showPostLoginError = false
    },
    handleOpenPostLoginError: (state, action) => {
      state.showPostLoginError = true
    },
    updateSectionType: (state, action) => {
      state.orderFormData.sectionType = action?.payload
    },
    changeOrderbookTab: (state, action) => {
      state.orderBookTab = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getOrderBookActions.success, (state, { payload }) => {
      state.orderBook = payload
      state.orderStatusCount = handleStatusCount(payload)
      const uniqueProductValues = new Set(
        payload?.map((obj: any) => obj.product)
      )
      const uniqueSegmentValues = new Set(
        payload?.map((obj: any) => obj.instrument)
      )
      const uniqueExchangeValues = new Set(
        payload?.map((obj: any) => obj.exchange.split('_')[0])
      )
      const uniqueStockValues = new Set(payload?.map((obj: any) => obj.symbol))
      state.filterLabels = {
        productFilterLabel: Array.from(uniqueProductValues),
        exchangeFilterLabel: Array.from(uniqueExchangeValues),
        segmentFilterLabel: Array.from(uniqueSegmentValues),
        stockFilterLabel: Array.from(uniqueStockValues)
      }
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    })
    builder.addCase(getRequiredMarginActions.success, (state, { payload }) => {
      state.requiredMargin = payload
    })
    builder.addCase(placeOrderActions.success, (state, { payload }) => {
      state.placeOrderDetails = payload
    })
    builder.addCase(modifyOrderActions.success, (state, { payload }) => {
      state.modifyOrderDetails = payload
    })
    builder.addCase(cancelOrderActions.success, (state, { payload }) => {
      state.cancelOrderDetails = payload
    })
    builder.addCase(getByOmsOrderIDActions.success, (state, { payload }) => {
      const { tradeBook, orderTrail = [] } = payload
      const orderTrailArray = []
      state.tradeBookData = tradeBook || []
      var totalTradedQty = 0
      var totalTradedPrice = 0
      for (const key in tradeBook) {
        const { tradePrice = 0, tradeQty = 0 } = tradeBook[key]
        totalTradedPrice = totalTradedPrice + tradePrice * tradeQty
        totalTradedQty = totalTradedQty + tradeQty
      }
      state.avgTradedPrice = totalTradedPrice / totalTradedQty || 0
      for (let i = orderTrail?.length - 1; i >= 0; i--) {
        const { orderStatus, rejectionReason } = orderTrail[i]
        if (orderStatus === 'REJECTED') {
          state.orderRejectionReason = rejectionReason
        }
        const orderTrailObject = orderTrail[i]
        orderTrailArray.push(orderTrailObject)
      }
      state.orderTrailData = orderTrailArray
    })
    builder.addCase(getTaxAndChargesActions.success, (state, { payload }) => {
      state.orderTaxAndCharges = payload
    })
    builder.addCase(ssoAuthenticateActions.success, (state, { payload }) => {
      state.ssoAuthentication = payload
    })
    builder.addCase(getSipOrderBookActions.success, (state, { payload }) => {
      const result = _groupBy(payload, 'sipOrderStatus')
      state.sipOrderBook = result
      state.sipOrderBookFilter = result

      const uniqueFreqValues = new Set(
        payload?.map((obj: any) => obj.sipFrequency)
      )
      const uniqueTypeValues = new Set(payload?.map((obj: any) => obj.sipType))

      state.SIPFilterLabels = {
        freqFilterLabel: Array.from(uniqueFreqValues),
        typeFilterLabel: Array.from(uniqueTypeValues)
      }
    })

    builder.addCase(getSipOrderDetailsActions.success, (state, { payload }) => {
      const updatedPayload = payload.map((item: { scriptId: any }) => {
        const { scriptId } = item
        const { coname } =
          (window as any).secMaster.getByScriptId(scriptId) || {}
        return { ...item, coname }
      })

      state.sipOrderDetails = updatedPayload
    })
  }
}

const handleStatusCount = (payload: any) => {
  const count = { Pending: 0, Executed: 0, Cancelled: 0, Rejected: 0 }
  payload?.forEach((object: any) => {
    const statusState = STATUS_MAPPER[object.orderStatus]
    if (statusState === 'REJECTED') {
      count.Rejected++
    }
    if (statusState === 'CANCELLED') {
      count.Cancelled++
    }
    if (statusState === 'OPEN' || statusState === 'TRANSIT') {
      count.Pending++
    }
    if (statusState === 'EXECUTED') {
      count.Executed++
    }
  })
  return count
}

function checkIfDefaultFiltersAreSet(state: any) {
  const { orderbookFilter } = state
  const { stocksFilter, exchangeFilter, segmentFilter, productsFilter } =
    orderbookFilter
  const noFilters =
    stocksFilter.length +
      exchangeFilter.length +
      segmentFilter.length +
      productsFilter.length ===
    0
      ? true
      : false
  if (orderbookFilter.sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const orderSlice = createSlice(sliceOptions)

export const {
  updateSipInfo,
  replaceSipInfo,
  updateOrderPageModalIndicator,
  updateSipSelectedScript,
  updateSelectedScriptsData,
  updateAmtQtySelectedScriptsData,
  updateOrgSipSelectedScript,
  clearSipInfo,
  updateDataOnExchangeChange,
  switchBuySellButton,
  setCommonIsinCodeScriptArray,
  setSelectedExchange,
  updateExchangeSelectorIndicator,
  resetOrderData,
  changeOrderFormData,
  modifyOrderFormData,
  storeCurrentModifyOrderData,
  orderbookFilter,
  setOrderbook,
  setSipOrderbook,
  handleOrderTradeBookAccordionState,
  handleOrderTrailAccordionState,
  handleResetingOrderDetailsModalStates,
  handleClosePostLoginError,
  handleOpenPostLoginError,
  setSIPFilter,
  updateSectionType,
  setSipOrderDetails,
  changeOrderbookTab
} = orderSlice.actions

export const ordersPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: ['ssoAuthentication']
}

export default orderSlice.reducer
