import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsTypography } from '@am92/react-design-system'
import { changeOrderFormData } from '~/src/Redux/Orders/reducer'
import { FormikErrors } from 'formik'
import { FormValues } from './OrderFormSection'

type ActionTypes = {
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
}

export interface IOrderTypeSectionProps extends IWithRouterProps {
  actions: ActionTypes
  orderType: string
  orderBuyOrSell: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
  isModifyOrder: boolean
  isEquitySegment: boolean
}

class OrderTypeSection extends Component<IOrderTypeSectionProps> {
  constructor(props: IOrderTypeSectionProps) {
    super(props)
  }

  handleChangeOrderType = (orderType: string) => {
    const { actions } = this.props
    actions.changeOrderFormData({ key: 'orderType', data: orderType })
  }

  render() {
    const {
      orderType,
      orderBuyOrSell,
      setFieldValue,
      isModifyOrder,
      isEquitySegment
    } = this.props
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'
    const isMTFType = orderType === 'MTF'
    const isIntraDayType = orderType === 'INTRADAY'
    const isDeliveryType = orderType === 'DELIVERY' || isMTFType
    const showDeliveryTab = isModifyOrder && (isDeliveryType || isMTFType)
    const showIntraDayTab = isModifyOrder && isIntraDayType
    const showBothTabs = !showDeliveryTab && !showIntraDayTab

    return (
      <DsStack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-mild)'
          },
          paddingRight: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-mild)'
          }
        }}
      >
        <DsTypography variant='bodyRegularMedium'>Order Type</DsTypography>
        <DsStack
          sx={{
            flexDirection: 'row',
            gap: 'var(--ds-spacing-glacial)'
          }}
        >
          {showBothTabs ? (
            <>
              <DsStack
                sx={{
                  p: 'var(--ds-spacing-frostbite) var(--ds-spacing-glacial)',
                  backgroundColor: `var(--ds-colour-${
                    isDeliveryType ? orderColor : 'surfaceBackground'
                  })`,
                  border: `${
                    isDeliveryType
                      ? 'unset'
                      : '1px solid var(--ds-colour-strokeDefault)'
                  }`,
                  borderRadius: 'var(--ds-radius-quickFreeze)',
                  height: 'var(--ds-spacing-warm)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'var(--ds-spacing-superheated)',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setFieldValue('orderType', 'DELIVERY')
                }}
              >
                <DsTypography
                  variant={
                    isDeliveryType ? 'bodyBoldSmall' : 'bodyRegularSmall'
                  }
                  sx={{
                    color: `var(--ds-colour-${
                      isDeliveryType ? 'typoOnSurface' : 'typoSecondary'
                    })`
                  }}
                >
                  {isEquitySegment ? 'Delivery' : 'Overnight'}
                </DsTypography>
              </DsStack>
              <DsStack
                sx={{
                  p: 'var(--ds-spacing-frostbite) var(--ds-spacing-glacial)',
                  backgroundColor: `var(--ds-colour-${
                    isIntraDayType ? orderColor : 'surfaceBackground'
                  })`,
                  border: `${
                    isIntraDayType
                      ? 'unset'
                      : '1px solid var(--ds-colour-strokeDefault)'
                  }`,
                  borderRadius: 'var(--ds-radius-quickFreeze)',
                  height: 'var(--ds-spacing-warm)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'var(--ds-spacing-superheated)',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setFieldValue('orderType', 'INTRADAY')
                }}
              >
                <DsTypography
                  variant={
                    isIntraDayType ? 'bodyBoldSmall' : 'bodyRegularSmall'
                  }
                  sx={{
                    color: `var(--ds-colour-${
                      isIntraDayType ? 'typoOnSurface' : 'typoSecondary'
                    })`
                  }}
                >
                  Intraday
                </DsTypography>
              </DsStack>
            </>
          ) : showDeliveryTab ? (
            <DsStack
              sx={{
                p: 'var(--ds-spacing-frostbite) var(--ds-spacing-glacial)',
                backgroundColor: `var(--ds-colour-${
                  isDeliveryType ? orderColor : 'surfaceBackground'
                })`,
                border: `${
                  isDeliveryType
                    ? 'unset'
                    : '1px solid var(--ds-colour-strokeDefault)'
                }`,
                borderRadius: 'var(--ds-radius-quickFreeze)',
                height: 'var(--ds-spacing-warm)',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'var(--ds-spacing-superheated)',
                cursor: 'pointer'
              }}
              onClick={() => {
                setFieldValue('orderType', 'DELIVERY')
              }}
            >
              <DsTypography
                variant={isDeliveryType ? 'bodyBoldSmall' : 'bodyRegularSmall'}
                sx={{
                  color: `var(--ds-colour-${
                    isDeliveryType ? 'typoOnSurface' : 'typoSecondary'
                  })`
                }}
              >
                {isEquitySegment ? 'Delivery' : 'Overnight'}
              </DsTypography>
            </DsStack>
          ) : (
            <DsStack
              sx={{
                p: 'var(--ds-spacing-frostbite) var(--ds-spacing-glacial)',
                backgroundColor: `var(--ds-colour-${
                  isIntraDayType ? orderColor : 'surfaceBackground'
                })`,
                border: `${
                  isIntraDayType
                    ? 'unset'
                    : '1px solid var(--ds-colour-strokeDefault)'
                }`,
                borderRadius: 'var(--ds-radius-quickFreeze)',
                height: 'var(--ds-spacing-warm)',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'var(--ds-spacing-superheated)',
                cursor: 'pointer'
              }}
              onClick={() => {
                // this.handleChangeOrderType('INTRADAY')
                setFieldValue('orderType', 'INTRADAY')
              }}
            >
              <DsTypography
                variant={isIntraDayType ? 'bodyBoldSmall' : 'bodyRegularSmall'}
                sx={{
                  color: `var(--ds-colour-${
                    isIntraDayType ? 'typoOnSurface' : 'typoSecondary'
                  })`
                }}
              >
                Intraday
              </DsTypography>
            </DsStack>
          )}
        </DsStack>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(OrderTypeSection)
