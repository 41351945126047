import React, { Component } from 'react'
import { DsSkeleton, DsTypography } from '@am92/react-design-system'

import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getCurrencyFormat } from '~/src/Utils/global'

interface ILTPTicker {
  scriptId: string
  tickerData: ITicker
  ltpVariant?: string
  withCurrencySymbol?: boolean
}

class LTPTicker extends Component<ILTPTicker> {
  state = {}

  render() {
    const { tickerData, ltpVariant, withCurrencySymbol = true } = this.props
    const { LTP = 0, CLOSE_PRICE = 0 } = tickerData || {}
    const renderValue = LTP || CLOSE_PRICE
    const options = {
      minimumFractionDigits: 2
    }
    return !LTP && !CLOSE_PRICE ? (
      <DsSkeleton
        width={'2rem'}
        variant='text'
        sx={{
          fontSize: ltpVariant ? ltpVariant : 'bodyRegularMedium',
          ml: 'var(--ds-spacing-glacial)'
        }}
      />
    ) : withCurrencySymbol ? (
      <DsTypography variant={ltpVariant ? ltpVariant : 'bodyRegularMedium'}>
        {getCurrencyFormat(renderValue, options)}
      </DsTypography>
    ) : (
      <DsTypography variant={ltpVariant ? ltpVariant : 'bodyRegularMedium'}>
        {getCurrencyFormat(renderValue, { ...options, style: 'decimal' })}
      </DsTypography>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const tickerData = getTickerData(scriptId)(state)
  return {
    tickerData
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(LTPTicker)
