import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'dpHoldTicker'

const selectTicker = (state: any) => state[SLICE_NAME]

export const getDpTickerData = (scriptId: string) =>
  createSelector(selectTicker, ticker => ticker?.scriptsInDpHold?.[scriptId])

export const getTotalDematValue = createSelector(
  selectTicker,
  ticker => ticker?.dematValue
)
