export const HEADER_ASSETS = [
  {
    src: new URL('~/public/assets/images/Header.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const LOGO_ASSETS = [
  {
    src: new URL('~/public/assets/images/HeaderMb.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ADD_WATCHLIST = [
  {
    src: new URL('~/public/assets/images/AddWatchList.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ADDED_WATCHLIST = [
  {
    src: new URL('~/public/assets/images/AddedWatchList.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ARROW_UP = [
  {
    src: new URL('~/public/assets/images/arrow-up.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ARROW_DOWN = [
  {
    src: new URL('~/public/assets/images/arrow-down.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const OPTION_CHAIN = [
  {
    src: new URL('~/public/assets/images/optionChain.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const RIGHT_ARROW = [
  {
    src: new URL('~/public/assets/images/RightArrowBlack.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const VECTOR_CHART = [
  {
    src: new URL('~/public/assets/images/VectorchartLogo.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const MOBILE_CHART = [
  {
    src: new URL('~/public/assets/images/mobileChart.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CLOSE = [
  {
    src: new URL('~/public/assets/images/cross.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const LeftArrow = [
  {
    src: new URL('~/public/assets/images/LeftArrowBlack.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const RightArrow = [
  {
    src: new URL('~/public/assets/images/RightArrowBlack.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ORDER_INFO = [
  {
    src: new URL('~/public/assets/images/orderInfo.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const TICK_ASSET = [
  {
    src: new URL('~/public/assets/images/tickGreen.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CROSS_ASSET = [
  {
    src: new URL('~/public/assets/images/crosserror.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SEARCH = [
  {
    src: new URL('~/public/assets/images/Search.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SORT = [
  {
    src: new URL('~/public/assets/images/sort.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const PLUS = [
  {
    src: new URL('~/public/assets/images/Plus.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SETTINGS = [
  {
    src: new URL('~/public/assets/images/Settings.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CHART = [
  {
    src: new URL('~/public/assets/images/Chart.png', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/png'
  }
]

export const GRAPH = [
  {
    src: new URL('~/public/assets/images/graph.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SIGNAL = [
  {
    src: new URL('~/public/assets/images/signal.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const INVEST = [
  {
    src: new URL('~/public/assets/images/invest.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const PAGE = [
  {
    src: new URL('~/public/assets/images/page.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const TICK = [
  {
    src: new URL('~/public/assets/images/tick.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CLOCK = [
  {
    src: new URL('~/public/assets/images/Clock.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SSO_ERROR_ASSEST = [
  {
    src: new URL('~/public/assets/images/SsoError.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const MARKET_DEPTH = [
  {
    src: new URL('~/public/assets/images/marketDepth.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const OPTION_CHAIN_ICON = [
  {
    src: new URL('~/public/assets/images/optionChainIcon.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const MORE_OPTIONS = [
  {
    src: new URL('~/public/assets/images/more.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const LIST_DRAGGER = [
  {
    src: new URL('~/public/assets/images/ListDragger.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const DELETE = [
  {
    src: new URL('~/public/assets/images/deleteButton.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const BACK_ARROW = [
  {
    src: new URL('~/public/assets/images/BackArrow.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const THREE_DOT = [
  {
    src: new URL('~/public/assets/images/ThreeDot.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ACTIVATE_COMMODITIES = [
  {
    src: new URL(
      '~/public/assets/images/activateCommodities.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const BULB = [
  {
    src: new URL('~/public/assets/images/Bulb.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const BNPL = [
  {
    src: new URL('~/public/assets/images/bnpl.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]
export const INSUFFICIENT_FUND = [
  {
    src: new URL(
      '~/public/assets/images/insufficientFunds.svg',
      import.meta.url
    ).href,
    alt: 'insufficientFunds Image',
    as: 'image/svg+xml'
  }
]

export const DOWNLOAD = [
  {
    src: new URL('~/public/assets/images/Download.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SEARCH_ZERO_SCREEN = [
  {
    src: new URL('~/public/assets/images/searchZeroScreen.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const NETBANKING = [
  {
    src: new URL('~/public/assets/images/netBanking.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const GAINER = [
  {
    src: new URL('~/public/assets/images/Gainer.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const LOSER = [
  {
    src: new URL('~/public/assets/images/Loser.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ACTIVE_BY_VOLUME = [
  {
    src: new URL('~/public/assets/images/ActVolume.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const UPPER_CIRCUIT = [
  {
    src: new URL('~/public/assets/images/uppercircuit.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const LOWER_CIRCUIT = [
  {
    src: new URL('~/public/assets/images/lowercircuit.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const OPEN_INTEREST = [
  {
    src: new URL('~/public/assets/images/OI.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SCREENERS_RIGHT_ARROW = [
  {
    src: new URL(
      '~/public/assets/images/screenersRightArrow.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const SORT_SCRIPT = [
  {
    src: new URL('~/public/assets/images/sortScript.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const ADD_WATCHLISTM = [
  {
    src: new URL('~/public/assets/images/AddWatchlistM.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const EDIT_WATCHLISTM = [
  {
    src: new URL(
      '~/public/assets/images/mobileWatchlistEdit.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const RENAME_WATCHLISTM = [
  {
    src: new URL(
      '~/public/assets/images/mobileWatchlistRename.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const DELETE_WATCHLISTM = [
  {
    src: new URL('~/public/assets/images/deleteWatchlistM.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const DELETE_SCRIPT = [
  {
    src: new URL('~/public/assets/images/deleteBlack.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const AVATAR = [
  {
    src: new URL('~/public/assets/images/avatar.png', import.meta.url).href,
    alt: 'AVATAR',
    as: 'image/png'
  }
]

export const PHONE = [
  {
    src: new URL('~/public/assets/images/phone.svg', import.meta.url).href,
    alt: 'PHONE',
    as: 'image/svg+xml'
  }
]

export const OPTIONS = [
  {
    src: new URL('~/public/assets/images/options.svg', import.meta.url).href,
    alt: 'OPTIONS',
    as: 'image/svg+xml'
  }
]

export const UPI_LOGO = [
  {
    src: new URL('~/public/assets/images/upilogo.svg', import.meta.url).href,
    alt: 'UPI Logo',
    as: 'image/svg+xml'
  }
]

export const DOWN_ARROW = [
  {
    src: new URL('~/public/assets/images/downArrow.svg', import.meta.url).href,
    alt: 'DOWN ARROW',
    as: 'image/svg+xml'
  }
]

export const UP_ARROW = [
  {
    src: new URL('~/public/assets/images/uparrow.svg', import.meta.url).href,
    alt: 'UP ARROW',
    as: 'image/svg+xml'
  }
]

export const NO_MESSAGES = [
  {
    src: new URL('~/public/assets/images/nomessages.svg', import.meta.url).href,
    alt: 'NO MESSAGES',
    as: 'image/svg+xml'
  }
]

export const NSE = [
  {
    src: new URL('~/public/assets/images/nse.svg', import.meta.url).href,
    alt: 'NSE',
    as: 'image/svg+xml'
  }
]

export const BSE = [
  {
    src: new URL('~/public/assets/images/bse.svg', import.meta.url).href,
    alt: 'BSE',
    as: 'image/svg+xml'
  }
]

export const MCX = [
  {
    src: new URL('~/public/assets/images/mcx.svg', import.meta.url).href,
    alt: 'MCX',
    as: 'image/svg+xml'
  }
]

export const NCDEX = [
  {
    src: new URL('~/public/assets/images/NCDEX.svg', import.meta.url).href,
    alt: 'NCDEX',
    as: 'image/svg+xml'
  }
]

export const DDPI = [
  {
    src: new URL('~/public/assets/images/DDPI.svg', import.meta.url).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CUSTOMERSUPPORT = [
  {
    src: new URL('~/public/assets/images/customerSupport.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const OVERALL_PNL_HOLDINGS = [
  {
    src: new URL(
      '~/public/assets/images/overallPNLHoldings.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const TODAYS_PNL = [
  {
    src: new URL(
      '~/public/assets/images/todaysPNLHoldings.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const INVESTED_HOLDINGS = [
  {
    src: new URL('~/public/assets/images/investedHoldings.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const TOTAL_PNL_POSITIONS = [
  {
    src: new URL(
      '~/public/assets/images/totalPNLPositions.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const UNREALISED_PNL_POSITIONS = [
  {
    src: new URL(
      '~/public/assets/images/unrealisedPNLPositions.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const REALISED_PNL_POSITIONS = [
  {
    src: new URL(
      '~/public/assets/images/realisedPNLPositions.svg',
      import.meta.url
    ).href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const PENDING_ORDERS = [
  {
    src: new URL('~/public/assets/images/pendingOrders.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const REJECTED_ORDERS = [
  {
    src: new URL('~/public/assets/images/rejectedOrders.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const CANCELLED_ORDERS = [
  {
    src: new URL('~/public/assets/images/cancelledOrders.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const EXECUTED_ORDERS = [
  {
    src: new URL('~/public/assets/images/executedOrders.svg', import.meta.url)
      .href,
    alt: 'Home Image',
    as: 'image/svg+xml'
  }
]

export const NOTRADES = [
  {
    src: new URL('~/public/assets/images/notrades.svg', import.meta.url).href,
    alt: 'No Trades',
    as: 'image/svg+xml'
  }
]

export const NO_SEARCH_RESULT = [
  {
    src: new URL('~/public/assets/images/noSearchResult.svg', import.meta.url)
      .href,
    alt: 'No Search Result',
    as: 'image/svg+xml'
  }
]

export const EQUALIZER_LINE = [
  {
    src: new URL('~/public/assets/images/equalizer-line.svg', import.meta.url)
      .href,
    alt: 'Equalizer Line',
    as: 'image/svg+xml'
  }
]

export const ROTATE_LOCK = [
  {
    src: new URL('~/public/assets/images/rotate-lock-fill.svg', import.meta.url)
      .href,
    alt: 'ROTATE LOCK',
    as: 'image/svg+xml'
  }
]

export const DISABLED_ARROW = [
  {
    src: new URL('~/public/assets/images/greyArrowUp.svg', import.meta.url)
      .href,
    alt: 'DISABLED ARROW',
    as: 'image/svg+xml'
  }
]

export const NO_HOLDINGS = [
  {
    src: new URL('~/public/assets/images/noHoldings.svg', import.meta.url).href,
    alt: 'NO HOLDINGS',
    as: 'image/svg+xml'
  }
]

export const ADD_SCRIPT_ILLUSTRATION = [
  {
    src: new URL('~/public/assets/images/AddScript.svg', import.meta.url).href,
    alt: 'ADD SCRIPT ILLUSTRATION',
    as: 'image/svg+xml'
  }
]

export const ZERO_ORDERS = [
  {
    src: new URL('~/public/assets/images/zeroOrders.svg', import.meta.url).href,
    alt: 'NO ORDERS',
    as: 'image/svg+xml'
  }
]

export const WARNINGS = [
  {
    src: new URL(
      '~/public/assets/images/erroricons/warning.svg',
      import.meta.url
    ).href,
    alt: 'WARNINGS',
    as: 'image/svg+xml'
  }
]

export const COMMODITIES_NOT_ACTIVATED = [
  {
    src: new URL('~/public/assets/images/noCommodities.svg', import.meta.url)
      .href,
    alt: 'COMMODITIES_NOT_ACTIVATED',
    as: 'image/svg+xml'
  }
]

export const EDIS_AUTH = [
  {
    src: new URL('~/public/assets/images/edis_auth.svg', import.meta.url).href,
    alt: 'EDIS_AUTH',
    as: 'image/svg+xml'
  }
]

export const PLEDGE_BANNER = [
  {
    src: new URL('~/public/assets/images/Pledge_Banner.svg', import.meta.url)
      .href,
    alt: 'PLEDGE_BANNER',
    as: 'image/svg'
  }
]
export const PLEDGE_FAILED = [
  {
    src: new URL('~/public/assets/images/pledge_Failed.png', import.meta.url)
      .href,
    alt: 'PLEDGE_FAILED',
    as: 'image/png'
  }
]

export const PLEDGE_SUCCESSFUL = [
  {
    src: new URL(
      '~/public/assets/images/Pledge_Successful.png',
      import.meta.url
    ).href,
    alt: 'PLEDGE_SUCCESSFUL',
    as: 'image/png'
  }
]

export const ORDER_FAILED = [
  {
    src: new URL('~/public/assets/images/orderFailed.svg', import.meta.url)
      .href,
    alt: 'ORDER_FAILED',
    as: 'image/svg'
  }
]

export const ASL_LOGO = [
  {
    src: new URL('~/public/assets/images/aslLogo.svg', import.meta.url).href,
    alt: 'ASL_LOGO',
    as: 'image/svg'
  }
]

export const BUY_RECOMMENDATION = [
  {
    src: new URL(
      '~/public/assets/images/buyRecommendation.svg',
      import.meta.url
    ).href,
    alt: 'BUY_RECOMMENDATION',
    as: 'image/svg'
  }
]
export const SELL_RECOMMENDATION = [
  {
    src: new URL(
      '~/public/assets/images/sellRecommendation.svg',
      import.meta.url
    ).href,
    alt: 'SELL_RECOMMENDATION',
    as: 'image/svg'
  }
]
export const HOLD_RECOMMENDATION = [
  {
    src: new URL(
      '~/public/assets/images/holdRecommendation.svg',
      import.meta.url
    ).href,
    alt: 'HOLD_RECOMMENDATION',
    as: 'image/svg'
  }
]
export const BUY_RECOMMENDATION_DESKTOP = [
  {
    src: new URL('~/public/assets/images/buyRec.svg', import.meta.url).href,
    alt: 'BUY_RECOMMENDATION_DESKTOP',
    as: 'image/svg'
  }
]
export const SELL_RECOMMENDATION_DESKTOP = [
  {
    src: new URL('~/public/assets/images/sellRec.svg', import.meta.url).href,
    alt: 'SELL_RECOMMENDATION_DESKTOP',
    as: 'image/svg'
  }
]
export const RECOMMENDATION_DESKTOP = [
  {
    src: new URL('~/public/assets/images/rec.svg', import.meta.url).href,
    alt: 'RECOMMENDATION_DESKTOP',
    as: 'image/svg'
  }
]

export const NRI = [
  {
    src: new URL('~/public/assets/images/NRI.svg', import.meta.url).href,
    alt: 'NRI',
    as: 'image/svg'
  }
]

export const ORDER_SUCCESS = [
  {
    src: new URL('~/public/assets/images/orderSuccess.png', import.meta.url)
      .href,
    alt: 'ORDER_SUCCESS',
    as: 'image/png'
  }
]

export const RISK_DISCLOSURE = [
  {
    src: new URL('~/public/assets/images/riskDisclosure.svg', import.meta.url)
      .href,
    alt: 'RISK_DISCLOSURE',
    as: 'image/png'
  }
]
export const BUY_HAND = [
  {
    src: new URL('~/public/assets/images/BuyHand.svg', import.meta.url).href,
    alt: 'BUY_HAND',
    as: 'image/png'
  }
]
export const SELL_HAND = [
  {
    src: new URL('~/public/assets/images/SellHand.svg', import.meta.url).href,
    alt: 'SELL_HAND',
    as: 'image/png'
  }
]
export const HOLD_HAND = [
  {
    src: new URL('~/public/assets/images/HoldHand.svg', import.meta.url).href,
    alt: 'HOLD_HAND',
    as: 'image/png'
  }
]
export const BUY_HAND_WEB = [
  {
    src: new URL('~/public/assets/images/BuyHandWeb.svg', import.meta.url).href,
    alt: 'BUY_HAND_WEB',
    as: 'image/png'
  }
]
export const SELL_HAND_WEB = [
  {
    src: new URL('~/public/assets/images/SellHandWeb.svg', import.meta.url)
      .href,
    alt: 'SELL_HAND_WEB',
    as: 'image/png'
  }
]
export const HOLD_HAND_WEB = [
  {
    src: new URL('~/public/assets/images/HoldHandWeb.svg', import.meta.url)
      .href,
    alt: 'HOLD_HAND_WEB',
    as: 'image/png'
  }
]
export const NOTIFICATIONS_ZERO_SCREEN = [
  {
    src: new URL(
      '~/public/assets/images/NotificationsZeroScreen.svg',
      import.meta.url
    ).href,
    alt: 'NOTIFICATIONS_ZERO_SCREEN',
    as: 'image/png'
  }
]
export const RUPEE_SYMBOL = [
  {
    src: new URL('~/public/assets/images/rupeeSymbol.svg', import.meta.url)
      .href,
    alt: 'RUPEE_SYMBOL'
  }
]

export const SIP_BOTTOM = [
  {
    src: new URL('~/public/assets/images/sipBottom.svg', import.meta.url).href,
    alt: 'ORDER_SUCCESS',
    as: 'image/png'
  }
]

export const SIP_HEADER = [
  {
    src: new URL('~/public/assets/images/sipFormFlower.svg', import.meta.url)
      .href,
    alt: 'ORDER_SUCCESS',
    as: 'image/png'
  }
]

export const SIP_JOURNEY = [
  {
    src: new URL('~/public/assets/images/SipJourney.svg', import.meta.url).href,
    alt: 'ORDER_SUCCESS',
    as: 'image/png'
  }
]

export const SIP_DETAILS_TOP = [
  {
    src: new URL('~/public/assets/images/SipDetailsTop.svg', import.meta.url)
      .href,
    alt: 'ORDER_SUCCESS',
    as: 'image/png'
  }
]
export const ZERO_SIP = [
  {
    src: new URL('~/public/assets/images/ZeroSip.svg', import.meta.url).href,
    alt: 'ZERO_SIP'
  }
]
export const SOMETHING_WENT_WRONG = [
  {
    src: new URL(
      '~/public/assets/images/somethingWentWrongPortfolio.svg',
      import.meta.url
    ).href,
    alt: 'SOMETHING_WENT_WRONG'
  }
]
export const MUTUAL_FUND_DISCLAIMER = [
  {
    src: new URL(
      '~/public/assets/images/MutualFundDisclaimer.svg',
      import.meta.url
    ).href,
    alt: 'MUTUAL_FUND_DISCLAIMER'
  }
]

export const PORTFOLIO_HEADING = [
  {
    src: new URL(
      '~/public/assets/images/portfolioHeadingImage.png',
      import.meta.url
    ).href,
    alt: 'TRACK PORTFOLIO',
    as: 'image/png'
  }
]

export const MULTI_PORTFOLIO_BACKGROUND_GREEN = [
  {
    src: new URL(
      '~/public/assets/images/multiPortfolioBackgroundGreen.svg',
      import.meta.url
    ).href,
    alt: 'Multi Portfolio Background Image',
    as: 'image/svg+xml'
  }
]

export const MULTI_PORTFOLIO_BACKGROUND_RED = [
  {
    src: new URL(
      '~/public/assets/images/multiPortfolioBackgroundRed.svg',
      import.meta.url
    ).href,
    alt: 'Multi Portfolio Background Image',
    as: 'image/svg+xml'
  }
]

export const RECOMMENDATION_SIDE = [
  {
    src: new URL(
      '~/public/assets/images/RecommendationSide.svg',
      import.meta.url
    ).href,
    alt: 'HOLD_HAND_WEB',
    as: 'image/png'
  }
]
export const STOP_LOSS = [
  {
    src: new URL('~/public/assets/images/StopLoss.svg', import.meta.url).href,
    alt: 'STOP_LOSS',
    as: 'image/png'
  }
]
export const ENTRY = [
  {
    src: new URL('~/public/assets/images/Entry.svg', import.meta.url).href,
    alt: 'ENTRY',
    as: 'image/png'
  }
]
export const TARGET = [
  {
    src: new URL('~/public/assets/images/Target.svg', import.meta.url).href,
    alt: 'TARGET',
    as: 'image/png'
  }
]
export const ARROW = [
  {
    src: new URL('~/public/assets/images/Arrow.svg', import.meta.url).href,
    alt: 'ARROW',
    as: 'image/png'
  }
]
export const AXIS_BANK_LOGO = [
  {
    src: new URL('~/public/assets/images/AxisBankLogo.svg', import.meta.url)
      .href,
    alt: 'AXIS_BANK_LOGO',
    as: 'image/png'
  }
]
export const ZERO_SIP_ONE = [
  {
    src: new URL('~/public/assets/images/ZeroSip1.svg', import.meta.url).href,
    alt: 'ZERO_SIP_ONE',
    as: 'image/png'
  }
]
export const ZERO_SIP_TWO = [
  {
    src: new URL('~/public/assets/images/ZeroSip2.svg', import.meta.url).href,
    alt: 'ZERO_SIP_TWO',
    as: 'image/png'
  }
]
export const ZERO_SIP_THREE = [
  {
    src: new URL('~/public/assets/images/ZeroSip3.svg', import.meta.url).href,
    alt: 'ZERO_SIP_THREE',
    as: 'image/png'
  }
]
export const ZERO_SIP_FOUR = [
  {
    src: new URL('~/public/assets/images/ZeroSip4.svg', import.meta.url).href,
    alt: 'ZERO_SIP_FOUR',
    as: 'image/png'
  }
]

export const NRI_MOBILE_IMAGE = [
  {
    src: new URL('~/public/assets/images/NriMobileImage.svg', import.meta.url)
      .href,
    alt: 'NRI_MOBILE_IMAGE',
    as: 'image/png'
  }
]

export const NRI_WEB_IMAGE = [
  {
    src: new URL('~/public/assets/images/NriWebImage.svg', import.meta.url)
      .href,
    alt: 'NRI_WEB_IMAGE',
    as: 'image/png'
  }
]

export const MUTUAL_FUND_PORTFOLIO = [
  {
    src: new URL(
      '~/public/assets/images/mutualFundPortfolio.svg',
      import.meta.url
    ).href,
    alt: 'MUTUAL_FUND_PORTFOLIO',
    as: 'image/svg+xml'
  }
]

export const SMARTEDGE_PORTFOLIO = [
  {
    src: new URL(
      '~/public/assets/images/smartEdgePortfolio.svg',
      import.meta.url
    ).href,
    alt: 'SMARTEDGE_PORTFOLIO',
    as: 'image/svg+xml'
  }
]

export const STOCK_PORTFOLIO = [
  {
    src: new URL('~/public/assets/images/stockPortfolio.svg', import.meta.url)
      .href,
    alt: 'STOCK_PORTFOLIO',
    as: 'image/svg+xml'
  }
]

export const CAT3_PORTFOLIO = [
  {
    src: new URL('~/public/assets/images/cat3Portfolio.svg', import.meta.url)
      .href,
    alt: 'CAT3_PORTFOLIO',
    as: 'image/svg+xml'
  }
]

export const FEEDBACK_BANNER = [
  {
    src: new URL(
      '~/public/assets/images/feedbackBannerMobile.svg',
      import.meta.url
    ).href,
    alt: 'FEEDBACK_BANNER',
    as: 'image/svg+xml',
    media: '(max-width:768px)'
  },
  {
    src: new URL('~/public/assets/images/feedbackBanner.svg', import.meta.url)
      .href,
    alt: 'FEEDBACK_BANNER',
    as: 'image/svg+xml',
    media: '(min-width:769px)'
  }
]
