import { jsx as _jsx } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Alert from '@mui/material/Alert';
import { DsRemixIcon } from '../DsRemixIcon';
const CloseIcon = (props) => {
    const { ownerState, ...restProps } = props;
    return (_jsx(DsRemixIcon, { fontSize: "mild", className: "ri-close-line", ...restProps }));
};
export class DsToast extends PureComponent {
    render() {
        const { forwardedRef, ...restProps } = this.props;
        return (_jsx(Alert, { ref: forwardedRef, ...restProps, slots: { closeIcon: CloseIcon, ...restProps?.slots } }));
    }
}
