// TODO - CLEANUP: @pratik
// 1. define types for scriptId in subscribeWatchListStocks and unSubscribeWatchListStocks

import React from 'react'
import Marquee from 'react-fast-marquee'
import { DsRemixIcon, DsStack } from '@am92/react-design-system'

import StockDataOnHeader from './StockDataOnHeader'

import { getScrollingScripts } from '~/src/Redux/WatchList/Selectors'

import { appContext, TAppStore } from '~/src/Configurations/AppStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

interface IStockScrollingHeaderProps {
  scrollingScripts: { scriptId: string }[]
  subscribeLtpData: (scriptArray: any[]) => void
  unSubscribeLtpData: (scriptArray: any[]) => void
}

interface IStockScrollingHeaderState {}

class StockScrollingHeader extends React.Component<
  IStockScrollingHeaderProps,
  IStockScrollingHeaderState
> {
  componentDidMount(): void {
    const { scrollingScripts, subscribeLtpData } = this.props
    subscribeLtpData(scrollingScripts)
  }

  componentWillUnmount(): void {
    const { scrollingScripts, unSubscribeLtpData } = this.props
    unSubscribeLtpData(scrollingScripts)
  }

  render() {
    const { scrollingScripts } = this.props
    return (
      <DsStack
        sx={{
          flexDirection: 'row',
          position: 'absolute',
          right: 'var(--ds-spacing-tropical)',
          width: '79%'
        }}
      >
        <DsRemixIcon
          className='ri-arrow-left-s-line'
          sx={{
            color: 'var(--ds-colour-surfaceBackground)'
          }}
        />
        <Marquee pauseOnHover>
          <DsStack
            sx={{
              flexDirection: 'row',
              gap: 'var(--ds-spacing-mild)'
            }}
          >
            {scrollingScripts.map((scrollingScript: { scriptId: string }) => {
              const { scriptId } = scrollingScript
              return (
                <DsStack
                  key={scriptId}
                  sx={{
                    flexDirection: 'row'
                  }}
                >
                  <StockDataOnHeader scriptId={scriptId} />
                </DsStack>
              )
            })}
          </DsStack>
        </Marquee>
        <DsRemixIcon
          className='ri-arrow-right-s-line'
          sx={{
            color: 'var(--ds-colour-surfaceBackground)'
          }}
        />
      </DsStack>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const scrollingScripts = getScrollingScripts(state)
  return {
    scrollingScripts
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
    context: appContext
  })(StockScrollingHeader)
)
