import React, { Component } from 'react'
import {
  DsBox,
  DsButton,
  DsChip,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import { SIP_HEADER, SIP_JOURNEY } from '~/src/Constants/ASSET_MAP'

export class SipScriptHeader extends Component<any> {
  handleEditDetails = () => {
    const { setShowSelecetStock, updateIsEditingSip } = this.props
    updateIsEditingSip(true)
    setShowSelecetStock(false)
  }

  render() {
    const { sipInfo, openModify } = this.props
    const {
      basedOn = '',
      sipName = '',
      startDate,
      frequency,
      duration
    } = sipInfo

    const formattedBased =
      basedOn.toLowerCase() === 'amount' ? 'Amount Based' : 'Quantity Based'

    return (
      <>
        <DsStack
          sx={{
            borderRadius:
              'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-glacial) var(--ds-spacing-glacial)',
            background: 'var(--ds-colour-supportTypical)',
            padding: 'var(--ds-spacing-bitterCold)',
            position: 'relative'
          }}
        >
          <DsBox
            sx={{
              borderRadius:
                'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-glacial) var(--ds-spacing-glacial)',
              background: 'var(--ds-colour-supportTypical)',
              display: 'grid',
              gridTemplateColumns: '70% 30%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',

              color: 'var(--ds-colour-surfaceBackground)'
            }}
          >
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 'var(--ds-spacing-frostbite)'
              }}
            >
              <DsBox sx={{ width: 'fit-content', height: 'fit-content' }}>
                <DsImage height={'fit-content'} srcSet={SIP_HEADER} />
              </DsBox>
              <DsTypography variant='bodyBoldLarge'>{sipName} SIP</DsTypography>
            </DsBox>
            <DsChip
              sx={{ width: 'fit-content' }}
              variant='outlined'
              type='nudge'
              label={formattedBased}
            />
          </DsBox>
          <DsStack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              background: 'var(--ds-colour-surfaceBackground)',
              padding: 'var(--ds-spacing-glacial)',
              borderRadius: 'var(--ds-spacing-glacial)',
              gap: 'var(--ds-spacing-glacial)'
            }}
          >
            <DsBox>
              <DsImage height={'fit-content'} srcSet={SIP_JOURNEY} />
            </DsBox>
            <DsBox
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <DsStack justifyContent={'center'} alignItems={'center'}>
                <DsTypography variant='supportRegularInfo'>
                  Start Date
                </DsTypography>
                <DsTypography variant='supportRegularInfo'>
                  {startDate}
                </DsTypography>
              </DsStack>
              <DsStack justifyContent={'center'} alignItems={'center'}>
                <DsTypography variant='supportRegularInfo'>
                  Frequency
                </DsTypography>
                <DsTypography variant='supportRegularInfo'>
                  {frequency}
                </DsTypography>
              </DsStack>
              <DsStack justifyContent={'center'} alignItems={'center'}>
                <DsTypography variant='supportRegularInfo'>
                  Duration
                </DsTypography>
                <DsTypography variant='supportRegularInfo'>
                  {duration}
                </DsTypography>
              </DsStack>
            </DsBox>
            {!openModify && (
              <DsBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <DsButton
                  color='secondary'
                  variant='text'
                  onClick={this.handleEditDetails}
                >
                  EDit Details
                </DsButton>
              </DsBox>
            )}
          </DsStack>
        </DsStack>
      </>
    )
  }
}
export default SipScriptHeader
