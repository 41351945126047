import { MUTUAL_FUND_FILTER_BY_MAPPER } from '~/src/Pages/Portfolio/Components/MutualFund/MutualFund.constants'
import {
  TIndividualDpHoldData,
  TSecurityLimitFormattedData
} from '../DPHoldTicker/Reducer'

export interface I_PORTFOLIO_REDUCER {
  multiplePortfolioData: IMultiplePortfolioData
  mutualFundPortfolioData: IMutualFundPortfolioData
  multiplePortFolioSections: TMultiplePortFolioSection[] | null
  selectedPortfolio: TSinglePortfolioData | null
  showMutualFundDisclaimer: boolean
  showMutualFundSortAndFilter: boolean
  mutualFundSortAndFilter: TMutualFundSortAndFilter
  selectedSortAndFilterOption: string
  mutualFundSortedAndFilteredData: ISingleMutualFundPortfolioData[] | null
  isDefaultFiltersEnabled: boolean
  mutualFundQuotesDetails: IMutualFundQuotesDetails[]
  formattedDematList: TIndividualDpHoldData[]
  filteredDematList: TIndividualDpHoldData[]
  selectedDematScript: TIndividualDpHoldData
  securityLimitList: TSecurityLimitFormattedData[]
  selectedSecurityLimitScript: TSecurityLimitFormattedData
  dematListFilter: string
  totalCollateralAmount: number
}
export const INITIAL_STATE: I_PORTFOLIO_REDUCER = {
  multiplePortfolioData: {
    portfolioSummary: null,
    portfolio: null
  },
  mutualFundPortfolioData: {
    portfolioSummary: null,
    portfolio: null
  },
  multiplePortFolioSections: null,
  selectedPortfolio: null,
  showMutualFundDisclaimer: false,
  showMutualFundSortAndFilter: false,
  mutualFundSortAndFilter: {
    mutualFundFilter: [],
    sortBy: 'INVESTED_HIGH_TO_LOW'
  },
  selectedSortAndFilterOption: MUTUAL_FUND_FILTER_BY_MAPPER.SORT_BY.label,
  mutualFundSortedAndFilteredData: null,
  isDefaultFiltersEnabled: true,
  mutualFundQuotesDetails: [],
  formattedDematList: [],
  filteredDematList: [],
  selectedDematScript: {
    scriptId: '',
    holdingQty: 0,
    closeprice: 0,
    dematValue: 0,
    assetClass: '',
    onHoldQuantity: 0
  },
  securityLimitList: [],
  totalCollateralAmount: 0,
  selectedSecurityLimitScript: {
    instrumentId: '',
    totalQuantity: '',
    collateralQuantity: '',
    collateralAmount: '',
    interSettlementQuantity: '',
    scriptId: '',
    closeprice: '',
    segment: ''
  },
  dematListFilter: 'All'
}

export interface IMultiplePortfolioData {
  portfolioSummary: TMultiplePortfolioSummary | null
  portfolio: TSinglePortfolioData[] | null
}

export interface IMutualFundPortfolioData {
  portfolioSummary: IMutualFundPortfolioSummary | null
  portfolio: ISingleMutualFundPortfolioData[] | null
}

export type TMultiplePortFolioSection = {
  title: string
  filter: string
  filterType: boolean
  gridTemplateColumns: string
}

export type TMutualFundSortAndFilter = {
  mutualFundFilter: ISingleMutualFundPortfolioData[] | []
  sortBy: string
}

export type IMutualFundQuotesDetails = {
  schemeName: string
  holdingDate: string
  stockValue: string
  percentageShare: string
}
export interface IDematList {
  onHoldQuantity: number
  freeQuantity: number
  instrumentIdType: number
  instrumentId: string
  instrumentSegment: number
}

export interface IDematData {
  scriptId: number | string
  closeprice: number
  holdingQty: number
  assetClass: string
  onHoldQuantity: number
}
export type TMultiplePortfolioSummary = {
  totalInvestedAmount: number
  totalMarketValue: number
  totalUnrealizedGain: number
  totalUnrealizedGainPerc: number
  totalDayGain: number
  totalDayGainPerc: number
}

export type TSinglePortfolioData = {
  clientCode: string
  subAccountName: string
  holdingType: string
  investment: number
  marketValue: number
  unrealizedGain: number
  unrealizedGainPerc: number
  dayGain: number
  dayGainPerc: number
  prevCloseValue: number
  asOnDate: string
}

export interface IMutualFundPortfolioSummary
  extends TMultiplePortfolioSummary {}

export interface ISingleMutualFundPortfolioData
  extends Omit<TSinglePortfolioData, 'clientCode' | 'subAccountName'> {
  mutualFundName: string
  holdingQuantity: string
  averagePrice: string
  livePrice: string
}

export interface IMultiplePortfolioData {
  portfolioSummary: TMultiplePortfolioSummary | null
  portfolio: TSinglePortfolioData[] | null
}
