import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsStack,
  DsTable,
  DsTableBody,
  DsTableCell,
  DsTableHead,
  DsTableRow,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'

import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

import { QUOTES_LABEL } from '~/src/Constants/LABEL'
import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import MarketDepthSkeleton from './MarketDepthSkeleton'

export interface IMarketdepthProps extends IWithRouterProps {
  tickerData: ITicker
  scriptId: string
  handleRowClick: (bidOrAsk: string, limitPrice: string | number) => void
  subscribeMarketDepthData: (scriptArray: any[]) => void
  subscribeTotalBidTotalAskData: (scriptArray: any[]) => void
  unSubscribeMarketDepthData: (scriptArray: any[]) => void
  unSubscribeTotalBidTotalAskData: (scriptArray: any[]) => void
}

const getFormattedPrice = (price: number) => {
  if (price) {
    return Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(price)
  }
  return ''
}

class Marketdepth extends Component<IMarketdepthProps> {
  componentDidMount(): void {
    const {
      scriptId,
      subscribeMarketDepthData,
      subscribeTotalBidTotalAskData
    } = this.props
    subscribeMarketDepthData([{ scriptId }])
    subscribeTotalBidTotalAskData([{ scriptId }])
  }

  componentWillUnmount(): void {
    const {
      scriptId,
      unSubscribeMarketDepthData,
      unSubscribeTotalBidTotalAskData
    } = this.props
    unSubscribeMarketDepthData([{ scriptId }])
    unSubscribeTotalBidTotalAskData([{ scriptId }])
  }

  componentDidUpdate(prevProps: Readonly<IMarketdepthProps>): void {
    const {
      scriptId,
      subscribeMarketDepthData,
      subscribeTotalBidTotalAskData
    } = this.props
    const { scriptId: prevScriptId } = prevProps
    if (prevScriptId !== scriptId) {
      subscribeMarketDepthData([{ scriptId }])
      subscribeTotalBidTotalAskData([{ scriptId }])
    }
  }

  renderMdSkeleton = () => {
    return <MarketDepthSkeleton />
  }

  render() {
    const { tickerData, handleRowClick } = this.props
    const {
      B5BUY = null,
      B5SELL = null,
      TOTAL_BUY_QTY,
      TOTAL_SELL_QTY
    } = tickerData || {}

    return (
      <DsStack>
        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DsTypography variant='headingBoldExtraSmall'>
            {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.HEADING}
          </DsTypography>
          <DsBox sx={{ display: 'flex' }}>
            <DsBox
              sx={{
                width: 'var(--ds-spacing-bitterCold)',
                height: 'var(--ds-spacing-bitterCold)',
                backgroundColor: 'var(--ds-colour-neutral3)',
                borderRadius: 'var(--ds-radius-deepFreeze)',
                mr: 'var(--ds-spacing-quickFreeze)'
              }}
            />
            <DsTypography variant='bodyRegularSmall'>
              {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.MAXIMUM}
            </DsTypography>
          </DsBox>
        </DsBox>
        <DsTypography
          variant='bodyRegularSmall'
          color={'var(--ds-colour-typoSecondary)'}
        >
          {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.PRICE_PICK}
        </DsTypography>
        <DsGrid container columnSpacing={{ xs: 3, md: 4 }}>
          <DsGrid item md={6} xs={6}>
            <DsTable
              sx={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing:
                  'var(--ds-spacing-zero) var(--ds-spacing-glacial)',
                tableLayout: 'fixed',
                display: 'table'
              }}
            >
              <DsTableHead>
                <DsTableRow>
                  <DsTableCell
                    sx={{
                      pb: '12px',
                      px: '8px',
                      width: { xs: 'auto', lg: '33%' }
                    }}
                    align='left'
                  >
                    {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.QUANTITY}
                  </DsTableCell>
                  <DsTableCell
                    sx={{
                      pb: '12px',
                      px: '8px',
                      width: { xs: 'auto', lg: '23%' },
                      whiteSpace: 'nowrap'
                    }}
                    align='center'
                  >
                    {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ORDERS}
                  </DsTableCell>
                  <DsTableCell
                    align='right'
                    sx={{
                      width: '44%',
                      color: 'var(--ds-colour-iconPositive)',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      pb: '12px',
                      px: '8px'
                    }}
                  >
                    {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.BID}
                  </DsTableCell>
                </DsTableRow>
              </DsTableHead>
              <DsTableBody>
                {(B5BUY &&
                  B5BUY.map((item, index) => (
                    <DsTableRow
                      onClick={() =>
                        handleRowClick(
                          QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.BID,
                          item.price
                        )
                      }
                      key={index}
                      sx={{
                        backgroundColor: `${
                          Number(item.quantity) ===
                          Math.max(
                            ...B5BUY.map((item: any) => Number(item.quantity))
                          )
                            ? 'var(--ds-colour-neutral3)'
                            : index % 2 === 0 &&
                              'var(--ds-colour-surfaceSecondary)'
                        }`,
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: 'var(--ds-colour-neutral3)'
                        }
                      }}
                    >
                      <DsTableCell
                        sx={{
                          borderTopLeftRadius: 'var(--ds-spacing-quickFreeze)',
                          borderBottomLeftRadius:
                            'var(--ds-spacing-quickFreeze)',
                          pt: 'var(--ds-spacing-frostbite)',
                          pb: 'var(--ds-spacing-frostbite)',
                          pr: 0,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        align='left'
                      >
                        {item.quantity}
                      </DsTableCell>
                      <DsTableCell
                        align='center'
                        sx={{
                          pt: 'var(--ds-spacing-frostbite)',
                          pb: 'var(--ds-spacing-frostbite)',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          pr: 0,
                          pl: 0
                        }}
                      >
                        {item.order}
                      </DsTableCell>
                      <DsTableCell
                        sx={{
                          borderTopRightRadius: 'var(--ds-spacing-quickFreeze)',
                          borderBottomRightRadius:
                            'var(--ds-spacing-quickFreeze)',
                          pt: 'var(--ds-spacing-frostbite)',
                          pb: 'var(--ds-spacing-frostbite)',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          pl: 0
                        }}
                        align='right'
                      >
                        {getFormattedPrice(item.price)}
                      </DsTableCell>
                    </DsTableRow>
                  ))) ||
                  this.renderMdSkeleton()}
              </DsTableBody>
            </DsTable>
          </DsGrid>
          <DsGrid item md={6} xs={6}>
            <DsBox>
              {' '}
              <DsTable
                sx={{
                  borderCollapse: 'separate',
                  borderSpacing:
                    'var(--ds-spacing-zero) var(--ds-spacing-glacial)',
                  tableLayout: 'fixed'
                }}
              >
                <DsTableHead>
                  <DsTableRow>
                    <DsTableCell
                      sx={{
                        width: '44%',
                        color: 'var(--ds-colour-iconNegative)',
                        pb: '12px',
                        px: '8px'
                      }}
                      align='left'
                    >
                      {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ASK}
                    </DsTableCell>
                    <DsTableCell
                      sx={{
                        pb: '12px',
                        px: '8px',
                        width: { xs: 'auto', lg: '23%' },
                        whiteSpace: 'nowrap'
                      }}
                      align='center'
                    >
                      {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ORDERS}
                    </DsTableCell>
                    <DsTableCell
                      sx={{
                        pb: '12px',
                        px: '8px',
                        width: { xs: 'auto', lg: '33%' }
                      }}
                      align='right'
                    >
                      {QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.QUANTITY}
                    </DsTableCell>
                  </DsTableRow>
                </DsTableHead>
                <DsTableBody>
                  {(B5SELL &&
                    B5SELL.map((item: any, index: any) => (
                      <DsTableRow
                        onClick={() =>
                          handleRowClick(
                            QUOTES_LABEL.OVERVIEW.MARKET_DEPTH.ASK,
                            item.price
                          )
                        }
                        key={index}
                        sx={{
                          backgroundColor: `${
                            Number(item.quantity) ===
                            Math.max(
                              ...B5SELL.map((item: any) =>
                                Number(item.quantity)
                              )
                            )
                              ? 'var(--ds-colour-neutral3)'
                              : index % 2 === 0 &&
                                'var(--ds-colour-surfaceSecondary)'
                          }`,

                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: 'var(--ds-colour-neutral3)'
                          }
                        }}
                      >
                        <DsTableCell
                          sx={{
                            borderTopLeftRadius:
                              'var(--ds-spacing-quickFreeze)',
                            borderBottomLeftRadius:
                              'var(--ds-spacing-quickFreeze)',
                            pt: 'var(--ds-spacing-frostbite)',
                            pb: 'var(--ds-spacing-frostbite)',
                            pr: 0
                          }}
                          align='left'
                        >
                          {getFormattedPrice(item.price)}
                        </DsTableCell>
                        <DsTableCell
                          sx={{
                            pt: 'var(--ds-spacing-frostbite)',
                            pb: 'var(--ds-spacing-frostbite)',
                            pr: 0,
                            pl: 0
                          }}
                          align='center'
                        >
                          {item.order}
                        </DsTableCell>
                        <DsTableCell
                          sx={{
                            borderTopRightRadius:
                              'var(--ds-spacing-quickFreeze)',
                            borderBottomRightRadius:
                              'var(--ds-spacing-quickFreeze)',
                            pt: 'var(--ds-spacing-frostbite)',
                            pb: 'var(--ds-spacing-frostbite)',
                            pl: 0
                          }}
                          align='right'
                        >
                          {item.quantity}
                        </DsTableCell>
                      </DsTableRow>
                    ))) ||
                    this.renderMdSkeleton()}
                </DsTableBody>
              </DsTable>
            </DsBox>
          </DsGrid>
        </DsGrid>
        <DsDivider
          sx={{
            mt: 'var(--ds-spacing-bitterCold)',
            mb: 'var(--ds-spacing-bitterCold)'
          }}
        />
        <DsGrid container spacing={4}>
          <DsGrid item md={3} xs={3}>
            <DsTypography variant='bodyRegularSmall'>
              {Intl.NumberFormat('en-IN').format(TOTAL_BUY_QTY)}
            </DsTypography>
          </DsGrid>
          <DsGrid item md={3} xs={3} textAlign={'right'}>
            <DsTypography
              variant='bodyRegularSmall'
              color={'var(--ds-colour-typoSecondary)'}
            >
              Total Bid
            </DsTypography>
          </DsGrid>
          <DsGrid item md={3} xs={3}>
            <DsTypography
              variant='bodyRegularSmall'
              color={'var(--ds-colour-typoSecondary)'}
            >
              Total Ask
            </DsTypography>
          </DsGrid>
          <DsGrid item md={3} xs={3} textAlign={'right'}>
            <DsTypography variant='bodyRegularSmall'>
              {Intl.NumberFormat('en-IN').format(TOTAL_SELL_QTY)}
            </DsTypography>
          </DsGrid>
        </DsGrid>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const tickerData = getTickerData(scriptId)(state)
  return {
    tickerData
  }
}

const mapDispatchToProps = () => ({})

export default withStockSubUnSub(
  withBreakpoints(
    withRouter(
      connect(mapStateToProps, mapDispatchToProps, null, {
        context: tickerContext
      })(Marketdepth)
    )
  )
)
