import { createSelector } from '@reduxjs/toolkit'
import { THoldingsDocument } from '~/src/Pages/Dashboard/Components/HoldingSummaryCard/HoldingSummaryCard'
import { _groupBy, _filter } from '~/src/Utils/lodash'

export const SLICE_NAME = 'dashboard'

const select = (state: any) => state[SLICE_NAME]

export const getHoldingsFilter = createSelector(
  select,
  dashboard => dashboard?.holdingsFilter
)

export const getPledgeFilter = createSelector(
  select,
  dashboard => dashboard?.pledgeFilter
)

export const getHoldingsDetailsSelector = createSelector(
  select,
  dashboard => dashboard.holdingDetails
)
export const getPledgeDetails = createSelector(
  select,
  dashboard => dashboard.pledgeDetails
)

export const getPledgeSumData = createSelector(
  select,
  dashboard => dashboard?.pledgeData
)

export const getTradingHistorySelector = createSelector(select, dashboard =>
  _filter(
    dashboard.tradingHistory,
    item => item.transactionType == 'BUY' || item.transactionType == 'SELL'
  )
)

export const isDefaultOrdersFiltersEnabled = createSelector(
  select,
  dashboard => dashboard.isDefaultFiltersEnabled
)

export const getRecommendationCalls = createSelector(
  select,
  dashboard => dashboard.recommendationDataByScripId
)

export const isPledgeDefaultFiltersEnabled = createSelector(
  select,
  dashboard => dashboard.isPledgeDefaultFiltersEnabled
)

export const getHoldingAndAveragePrice = (
  scriptId: string | number,
  stockDetails?: any
) =>
  createSelector(select, dashboard => {
    const scriptsInHolding = dashboard.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING
    const holdingScript = scriptsInHolding.find(
      (item: any) => item?.isinCode == stockDetails?.isinCode
    )
    const script = holdingScript ? holdingScript?.scriptId : scriptId
    return dashboard?.HOLDING_SUMMARY_HOLDINGS_OBJECT?.[script]
  })

export const getEntireHoldingObjectData = createSelector(
  select,
  dashboard => dashboard.HOLDING_SUMMARY_HOLDINGS_OBJECT
)

export const getAllScripsInHoldingAndTotalInvestedValueFromHoldingSummary =
  createSelector(select, dashboard => {
    const {
      HOLDING_SUMMARY_SCRIPTS_IN_HOLDING,
      HOLDING_SUMMARY_SUMMED_INVESTED_VALUE
    } = dashboard
    return {
      scripsInHolding: HOLDING_SUMMARY_SCRIPTS_IN_HOLDING,
      totalInvestedAmount: HOLDING_SUMMARY_SUMMED_INVESTED_VALUE
    }
  })

export const checkIfHoldingsAvailable = createSelector(select, dashboard => {
  const { HOLDING_SUMMARY_SCRIPTS_IN_HOLDING } = dashboard
  return Boolean(HOLDING_SUMMARY_SCRIPTS_IN_HOLDING.length)
})

export const getAllScripsInHoldingAndTotalInvestedValue = createSelector(
  select,
  dashboard => {
    const { SCRIPTS_IN_HOLDING, SUMMED_INVESTED_VALUE, holdingDetails } =
      dashboard
    return {
      scripsInHolding: SCRIPTS_IN_HOLDING,
      totalInvestedAmount: SUMMED_INVESTED_VALUE,
      holdingDetails: holdingDetails
    }
  }
)

export const getScriptsInHoldingWithoutZeroDmatQty = createSelector(
  select,
  dashboard => {
    const { SCRIPTS_IN_HOLDING } = dashboard
    const scriptsInHoldingsWithoutZeroDmatQty =
      SCRIPTS_IN_HOLDING?.filter((holding: THoldingsDocument) => {
        const { dmatfreeQty } = holding
        return dmatfreeQty !== '' && dmatfreeQty !== '0'
      }) || []

    return scriptsInHoldingsWithoutZeroDmatQty
  }
)

export const getSelectedFilterTabInHolding = createSelector(
  select,
  dashboard => dashboard.selectedFilterTabInHolding
)

export const getScriptIdArray = createSelector(
  select,
  dashboard => dashboard.scriptsArray
)

export const holdingDetailsScriptIndexSelector = (scriptId: string) =>
  createSelector(select, dashboard => {
    return dashboard?.holdingDetailsScriptIndex?.[scriptId] || {}
  })
