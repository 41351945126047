import React, { Component } from 'react'
import { DsBox, DsTypography } from '@am92/react-design-system'

import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

import { tickerContext, TTickerStore } from '~/src/Configurations/TickerStore'

import withErrorConnect from '~/src/Lib/withErrorConnect'

interface ITickerPriceInTaxCharges {
  scriptId: string
  stockPriceDetails: ITicker
  openViewTaxChargesModal: boolean
  calculateOrderValue: (orderPrice: number) => void
  quantity: number
  marketDepth: string
}

interface ITickerPriceInTaxChargesState {
  showLTP: boolean
  tickerPriceInTaxCharges: number | string
}

class TickerPriceInTaxCharges extends Component<
  ITickerPriceInTaxCharges,
  ITickerPriceInTaxChargesState
> {
  constructor(props: ITickerPriceInTaxCharges) {
    super(props)
    const { stockPriceDetails } = this.props
    const { LTP } = (stockPriceDetails as ITicker) || {}
    this.state = {
      showLTP: false,
      tickerPriceInTaxCharges: LTP
    }
  }

  componentDidMount(): void {
    const { stockPriceDetails, calculateOrderValue } = this.props
    const { LTP } = stockPriceDetails || {}
    this.setState({ tickerPriceInTaxCharges: LTP })
    calculateOrderValue(LTP)
  }

  componentDidUpdate(prevProps: any): void {
    const {
      stockPriceDetails,
      openViewTaxChargesModal,
      calculateOrderValue,
      quantity,
      marketDepth
    } = this.props
    const { LTP } = stockPriceDetails || {}
    const { showLTP } = this.state

    if (LTP?.toString() && !showLTP) {
      this.setState({ tickerPriceInTaxCharges: LTP, showLTP: true })
    }

    if (openViewTaxChargesModal !== prevProps.openViewTaxChargesModal) {
      this.setState({ tickerPriceInTaxCharges: LTP })
      calculateOrderValue(LTP)
    }

    if (prevProps.quantity !== quantity && quantity !== undefined) {
      if (marketDepth === 'market') calculateOrderValue(LTP)
    }
  }

  render() {
    const { tickerPriceInTaxCharges } = this.state
    return (
      <>
        <DsBox>
          <DsTypography variant='bodyRegularSmall'>
            ₹{tickerPriceInTaxCharges}
          </DsTypography>
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: TTickerStore, ownProps: any) => {
  const { scriptId } = ownProps
  const stockPriceDetails = getTickerData(scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = () => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(TickerPriceInTaxCharges)
