import React, { ChangeEvent, Component } from 'react'
import {
  DsBox,
  DsFormControl,
  DsFormControlLabel,
  DsPopup,
  DsRadio,
  DsRadioGroup,
  DsStack,
  DsTextField,
  DsTypography
} from '@am92/react-design-system'

import { DURATION } from './SipFormSection'

export default class CustomDuration extends Component<any> {
  state = {
    error: false,
    value: 12
  }

  handleChange = async (e: any) => {
    const { isDesktop } = this.props
    const { value } = e.target
    const formattedValue = Number(value)
    const defaultValue = ''
    if (isNaN(formattedValue) || formattedValue <= 0 || formattedValue > 60) {
      await this.setState({ error: true, value: defaultValue })
      return
    }

    await this.setState({ value: formattedValue, error: false })
    if (!isDesktop) {
      this.handleConfirm()
    }
  }

  handleConfirm = () => {
    const { updateSipInfo, onClose } = this.props
    const { value } = this.state
    const formattedValue = this.convertToMonthsOrYears(value)
    updateSipInfo({
      name: 'duration',
      value: formattedValue
    })

    onClose()
  }

  convertToMonthsOrYears = (number: any) => {
    if (number < 12) {
      return `${number} Months`
    } else if (number % 12 === 0) {
      const years = number / 12
      return `${years} Year`
    } else {
      return `${number} Months`
    }
  }

  render() {
    const {
      open,
      onClose,
      secondaryClick,
      isDesktop,
      showDuration,
      onCloseDuration,
      handleChangeDuration,
      duration
    } = this.props
    const { value, error } = this.state

    return (
      <>
        {isDesktop ? (
          <DsPopup
            DsDialogProps={{
              open: open,
              onClose: onClose
            }}
            primaryButtonText='Confirm'
            secondaryButtonText='Cancel'
            showClose={false}
            primaryButtonProps={{
              onClick: this.handleConfirm,
              disabled: error
            }}
            secondaryButtonProps={{
              onClick: secondaryClick
            }}
          >
            <DsBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <DsTypography textAlign={'center'} variant='headingBoldMedium'>
                Custom Duration
              </DsTypography>

              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '4px',
                  mt: '3rem'
                }}
              >
                <DsTypography variant='bodyRegularMedium'>
                  Enter Custom Duration
                </DsTypography>
                <DsTypography variant='bodyRegularSmall'>
                  Min. 1 months
                </DsTypography>
                <DsTextField
                  placeholder='Enter'
                  autoFocus
                  fullWidth
                  type='text'
                  value={value}
                  error={error}
                  onChange={this.handleChange}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 2
                  }}
                  helperText={
                    error
                      ? 'Please enter a number of months that is 60 or less'
                      : ''
                  }
                />
              </DsBox>
            </DsBox>
          </DsPopup>
        ) : (
          <DsPopup
            open={showDuration}
            onClose={onCloseDuration}
            showClose={true}
            title={'Select Frequency'}
          >
            <DsFormControl
              sx={{
                px: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsRadioGroup
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeDuration(e, undefined, value)
                }
              >
                {DURATION.map((item, index) => (
                  <DsFormControlLabel
                    key={index}
                    name='duration'
                    control={<DsRadio />}
                    label={
                      <>
                        <DsTypography variant={'bodyRegularMedium'}>
                          {item.label}
                        </DsTypography>
                        {item.label === 'Custom Duration' && (
                          <DsBox
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '45% 45%',
                              justifyContent: 'space-between',
                              width: '290px'
                            }}
                          >
                            <DsStack>
                              <DsTypography variant='bodyRegularMedium'>
                                Duration (months)
                              </DsTypography>
                              <DsTypography variant='bodyRegularSmall'>
                                Min. 1 month
                              </DsTypography>
                            </DsStack>
                            <DsTextField
                              placeholder='Enter'
                              autoFocus
                              fullWidth
                              type='text'
                              value={value}
                              name='customBottomsheet'
                              error={error}
                              onChange={this.handleChange}
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                maxLength: 2
                              }}
                            />
                          </DsBox>
                        )}
                      </>
                    }
                    value={item.value}
                    sx={{
                      '.MuiFormControlLabel-root': {
                        marginRight: 'var(--ds-spacing-zero) !important'
                      }
                    }}
                  />
                ))}
              </DsRadioGroup>
            </DsFormControl>
          </DsPopup>
        )}
      </>
    )
  }
}
