import { createSelector } from '@reduxjs/toolkit'
import { TAppStore } from '~/src/Configurations/AppStore'
import { T_ORDER_BOOK_OBJECT } from './TYPES'
export const SLICE_NAME = 'orders'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getOrderBookData = createSelector(
  select,
  orders => orders.orderBook
)

export const getOrderBookWithoutGtdBlockedData = createSelector(
  select,
  orders => {
    const { orderBook = [] } = orders

    const orderBookWithoutGtdBlocked =
      orderBook?.filter(({ orderStatus }: T_ORDER_BOOK_OBJECT) => {
        return orderStatus !== 'GTDTBLOCKED'
      }) || []
    return orderBookWithoutGtdBlocked
  }
)

export const getSipOrderBookData = createSelector(
  select,
  orders => orders.sipOrderBook || []
)
export const getSipOrderBookFilterData = createSelector(
  select,
  orders => orders.sipOrderBookFilter || []
)

export const getRequiredMarginData = createSelector(
  select,
  orders => orders.requiredMargin
)

export const placeOrderSelector = createSelector(
  select,
  orders => orders.placeOrderDetails
)

export const getAvgTradedPrice = createSelector(
  select,
  orders => orders.avgTradedPrice
)

export const isModifyOrderRequest = createSelector(
  select,
  orders => orders.isModifyOrderRequest
)

export const isRepeatOrderRequest = createSelector(
  select,
  orders => orders.isRepeatOrderRequest
)

export const modifyOrderSelector = createSelector(
  select,
  orders => orders.modifyOrderDetails
)

export const cancelOrderSelector = createSelector(
  select,
  orders => orders.cancelOrderDetails
)

export const getOrderStatusCout = createSelector(
  select,
  orders => orders.orderStatusCount
)

export const getOrderPageModalIndicator = createSelector(
  select,
  orders => orders.orderPageModalIndicator
)

export const getExchangeSelectorIndicator = createSelector(
  select,
  orders => orders.exchangeSelectorIndicator
)

export const getOrderBuyOrSell = createSelector(
  select,
  orders => orders.orderBuyOrSell
)

export const getOrderScriptId = createSelector(
  select,
  orders => orders.orderScriptId
)

export const getTradebookSelector = createSelector(
  select,
  orders => orders.tradeBookData
)

export const getOrdertrailSelector = createSelector(
  select,
  orders => orders.orderTrailData
)

export const getCommonIsinCodeScriptIdArray = createSelector(
  select,
  orders => orders.commonIsinCodeScriptArray
)

export const getSelectedExchange = createSelector(
  select,
  orders => orders.selectedExchange
)

export const getOrderFormData = createSelector(
  select,
  orders => orders.orderFormData
)

export const getCurrentModifyOrderResponse = createSelector(
  select,
  orders => orders.currentModifyOrderData
)

export const getOrderbookFilter = createSelector(
  select,
  orders => orders.orderbookFilter
)

export const getSIPFilter = createSelector(select, orders => orders.SIPFilter)

export const getOrderbookFilterLabel = createSelector(
  select,
  orders => orders.filterLabels
)

export const getSIPFilterLabel = createSelector(
  select,
  orders => orders.SIPFilterLabels
)

export const getTaxAndChargesDetails = createSelector(
  select,
  orders => orders.orderTaxAndCharges
)

export const isOrderTradeBookAccordionOpen = createSelector(
  select,
  orders => orders.openOrderTradeBookAccordion
)

export const isOrderTrailAccordionOpen = createSelector(
  select,
  orders => orders.openOrderTrailAccordion
)

export const isDefaultOrdersFiltersEnabled = createSelector(
  select,
  orders => orders.isDefaultFiltersEnabled
)

export const getOrderRejectionReason = createSelector(
  select,
  orders => orders.orderRejectionReason
)

export const getTemporaryOrderFormData = createSelector(
  select,
  orders => orders.temporaryOrderData
)

export const getShowPostLoginError = createSelector(
  select,
  orders => orders.showPostLoginError
)
export const getSipInfo = createSelector(select, orders => orders.sipInfo)

export const sipOrderDetails = createSelector(
  select,
  orders => orders.sipOrderDetails || []
)

export const getSelectedScripts = createSelector(
  select,
  orders => orders.sipInfo.selectedScripts
)

export const getPostLoginErrorCode = createSelector(select, orders => {
  const { ssoAuthentication } = orders
  const { metadata } = ssoAuthentication
  const { errorCode = '' } = metadata || {}
  return errorCode
})

export const getScehduledGtdCount = createSelector(select, orders => {
  const { orderBook } = orders
  const totalScheduledGtdCount =
    (orderBook?.length &&
      orderBook.reduce((acc: number, singleOrder: T_ORDER_BOOK_OBJECT) => {
        const { orderStatus } = singleOrder
        if (orderStatus === 'GTDTBLOCKED') {
          return (acc += 1)
        }
        return (acc += 0)
      }, 0)) ||
    0

  return totalScheduledGtdCount
})

export const getEstimatedGtdAmount = createSelector(select, orders => {
  const { orderBook } = orders
  const totalEstimatedGtdAmount =
    (orderBook?.length &&
      orderBook.reduce((acc: number, singleOrder: T_ORDER_BOOK_OBJECT) => {
        const { orderStatus, orderPrice } = singleOrder
        if (orderStatus === 'GTDTBLOCKED') {
          return (acc += orderPrice)
        }
        return (acc += 0)
      }, 0)) ||
    0

  return totalEstimatedGtdAmount
})

export const getGtdOrderBookData = createSelector(select, orders => {
  const { orderBook = [] } = orders
  const gtdOrderBook = orderBook?.filter(
    ({ orderStatus }: T_ORDER_BOOK_OBJECT) => {
      return orderStatus === 'GTDTBLOCKED'
    }
  )
  return gtdOrderBook
})

export const getOrderBookTab = createSelector(
  select,
  orders => orders.orderBookTab
)
