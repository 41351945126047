// DONE - CLEANUP: @vasi
// 1. define types
import React, { Component } from 'react'

import Notifications from './Notifications'

interface INotificationPopoverProps {
  toggle: boolean
  setShowNotification: (data: boolean) => void
}
export class NotificationPopover extends Component<INotificationPopoverProps> {
  render() {
    const { toggle, setShowNotification } = this.props

    return (
      <>
        {toggle && (
          <Notifications
            toggle={toggle}
            setShowNotification={setShowNotification}
          />
        )}
      </>
    )
  }
}

export default NotificationPopover
