import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { DsRemixIcon } from '../DsRemixIcon';
import { DsTypography } from '../DsTypography';
import { DsIconButton } from '../DsIconButton';
import { DsDrawer } from '../DsDrawer';
import { DsBottomSheetDefaultProps } from './DsBottomSheet.Types';
import { DsDialogTitle } from '../DsDialogTitle';
import { DsDialogContent } from '../DsDialogContent';
import { DsButton } from '../DsButton';
import { DsDialogActions } from '../DsDialogActions';
import { DsPaper } from '../DsPaper';
export class DsBottomSheet extends PureComponent {
    static defaultProps = DsBottomSheetDefaultProps;
    handleDrawerClose = (event, reason) => {
        const { onClose } = this.props;
        if (typeof onClose === 'function') {
            onClose(event, reason);
        }
    };
    handleCloseClick = (event) => {
        const { onClose } = this.props;
        if (typeof onClose === 'function') {
            onClose(event, 'backdropClick');
        }
    };
    render() {
        const { kicker, title, showClose, primaryButtonText, primaryButtonProps, secondaryButtonText, secondaryButtonProps, PaperProps, ContainerProps, KickerProps, TitleProps, CloseIconButtonProps, CloseIconProps, ContentProps, ActionsProps, children, onClose, ...DrawerProps } = this.props;
        const actionsAvailable = !!(primaryButtonText || secondaryButtonText);
        const isFlushed = primaryButtonText &&
            !secondaryButtonText &&
            primaryButtonProps &&
            primaryButtonProps?.variant === 'flushed';
        const accessibilityProps = {};
        if (title) {
            accessibilityProps['aria-labelledby'] = title;
        }
        if (kicker) {
            accessibilityProps['aria-describedby'] = kicker;
        }
        return (_jsxs(DsDrawer, { ...accessibilityProps, ...DrawerProps, anchor: "bottom", PaperProps: {
                ...PaperProps,
                sx: {
                    background: 'transparent',
                    maxHeight: 'var(--ds-rules-bottomSheetWorkingAreaHeight)',
                    ...PaperProps?.sx
                }
            }, onClose: this.handleDrawerClose, children: [showClose && (_jsx(DsIconButton, { onClick: this.handleCloseClick, ...CloseIconButtonProps, sx: {
                        flexGrow: 0,
                        alignSelf: 'center',
                        backgroundColor: 'var(--ds-colour-iconDefault)',
                        color: 'var(--ds-colour-iconDisabled)',
                        borderRadius: '50%',
                        p: 'var(--ds-spacing-glacial)',
                        mb: 'var(--ds-spacing-bitterCold)',
                        ...CloseIconButtonProps?.sx
                    }, children: _jsx(DsRemixIcon, { className: "ri-close-line", ...CloseIconProps }) })), _jsxs(DsPaper, { ...ContainerProps, sx: {
                        minHeight: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        pt: 'var(--ds-spacing-mild)',
                        borderTopLeftRadius: 'var(--ds-radius-bitterCold)',
                        borderTopRightRadius: 'var(--ds-radius-bitterCold)',
                        pb: isFlushed ? undefined : 'var(--ds-spacing-bitterCold)',
                        ...ContainerProps?.sx
                    }, children: [kicker && (_jsx(DsTypography, { variant: "subheadingSemiboldDefault", color: "text.tertiary", ...KickerProps, sx: {
                                px: 'var(--ds-spacing-bitterCold)',
                                mb: 'var(--ds-spacing-quickFreeze)',
                                textTransform: 'uppercase',
                                ...KickerProps?.sx
                            }, children: kicker })), title && (_jsx(DsDialogTitle, { ...TitleProps, sx: {
                                mb: 'var(--ds-spacing-zero)',
                                px: 'var(--ds-spacing-bitterCold)',
                                ...TitleProps?.sx
                            }, children: title })), children && (_jsx(DsDialogContent, { ...ContentProps, sx: {
                                px: 'var(--ds-spacing-bitterCold)',
                                marginTop: 'var(--ds-spacing-mild)',
                                ...ContentProps?.sx
                            }, children: children })), actionsAvailable && (_jsxs(DsDialogActions, { ...ActionsProps, sx: {
                                px: isFlushed ? undefined : 'var(--ds-spacing-bitterCold)',
                                ...ActionsProps?.sx
                            }, children: [(secondaryButtonText || secondaryButtonProps?.children) && (_jsx(DsButton, { color: "secondary", size: "large", fullWidth: true, ...secondaryButtonProps, children: secondaryButtonText || secondaryButtonProps?.children })), (primaryButtonText || primaryButtonProps?.children) && (_jsx(DsButton, { size: "large", fullWidth: true, ...primaryButtonProps, children: primaryButtonText || primaryButtonProps?.children }))] }))] })] }));
    }
}
