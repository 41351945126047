import { getResearchConfigActions } from '../Actions'

import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { asHttp } from '~/src/Configurations/WebHttp'

async function getResearchConfig() {
  const options = {
    url: `markettracking/research-and-recommendations/config`,
    method: 'GET',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body

  return data
}

const getResearchConfigAction = serviceActionCreator(
  getResearchConfigActions,
  getResearchConfig
)

export default getResearchConfigAction
