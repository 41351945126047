import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { updateStockMasterAction } from './Actions'
import { T_STOCKDATA } from '~/src/Workers/Odin/ODIN_WORKER_CONSTANT'
import TickerStore from '~/src/Configurations/TickerStore'

export type T_SCRIPTDATA = {
  label?: string
  coname: string
  exchange: string
  odinTokenId: number
  segment: string
  exchangeSymbol: string
  closeprice: number
  maxSingleOrderQty: number
  ticksize: number
  lotsize: number
  isinCode: string
  instrumentType: string
  isSurveillanceAlertNeeded?: boolean
  asm_flag?: string | null
  aslAllowed?: string
  optionType?: string | null
  expiryDate?: string | null
  assetClass?: string | null
  yesterdayOpenInt?: Number | null
  ODIN_LLFC_SEGMENTID?: number
  underlying: string | number
  CMOTS_COCODE?: number | string
  DPRLOW: number
  DPRHIGH: number
  FIFTY_TWO_WEEK_LOW: number
  FIFTY_TWO_WEEK_HIGH: number
}

export interface StockMasterStore {
  [key: number | string]: T_SCRIPTDATA
}

const INITIAL_STATE: StockMasterStore = {}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    //updateStockTicker
    builder.addCase(updateStockMasterAction, (state, { payload }) => {
      ;[...payload].map((script: T_STOCKDATA) => {
        const {
          coname,
          exchange,
          odinTokenId,
          segment,
          scriptId,
          exchangeSymbol,
          closeprice,
          maxSingleOrderQty,
          ticksize,
          lotsize,
          isinCode,
          instrumentType,
          asm_flag,
          aslAllowed,
          optionType,
          expiryDate,
          assetClass,
          yesterdayOpenInt,
          ODIN_LLFC_SEGMENTID,
          underlying,
          CMOTS_COCODE,
          DPRLOW,
          DPRHIGH,
          FIFTY_TWO_WEEK_LOW,
          FIFTY_TWO_WEEK_HIGH
        } = script

        if (!state[scriptId]) {
          state[scriptId] = {
            label: instrumentType === 'EQUITY' ? exchangeSymbol : coname,
            coname,
            exchange,
            odinTokenId,
            segment,
            exchangeSymbol,
            closeprice,
            maxSingleOrderQty,
            ticksize,
            lotsize,
            isinCode,
            instrumentType,
            asm_flag,
            aslAllowed,
            optionType,
            expiryDate,
            assetClass,
            yesterdayOpenInt,
            ODIN_LLFC_SEGMENTID,
            underlying,
            CMOTS_COCODE,
            DPRLOW,
            DPRHIGH,
            FIFTY_TWO_WEEK_LOW,
            FIFTY_TWO_WEEK_HIGH
          }
          return
        }
      })
    })
  }
}

const ticketSlice = createSlice(sliceOptions)
export default ticketSlice.reducer
