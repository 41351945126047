import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsRadioGroup, DsStack, DsTypography } from '@am92/react-design-system'
import {
  getCommonIsinCodeScriptIdArray,
  getOrderScriptId,
  getSelectedExchange,
  isModifyOrderRequest
} from '~/src/Redux/Orders/selectors'
import ExchangeSelection from './ExchangeSelection'
import { updateDataOnExchangeChange } from '~/src/Redux/Orders/reducer'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import StockTicker from '../../Sidebar/Components/StockTicker'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import withStockSubUnSub from '~/src/Lib/withStockSubUnSub'

type ActionTypes = {
  updateDataOnExchangeChange: (reqData: any) => Promise<any>
}

export interface IOrderHeaderBottomProps extends IWithRouterProps {
  actions: ActionTypes
  orderScriptId: string
  commonIsinCodeScriptArray: string[]
  selectedDefaultExchange: string
  isModifyOrder: boolean
  stockDetails: T_SCRIPTDATA
  subscribeLtpData: (scriptIdArray: any[]) => void
  unSubscribeLtpData: (scriptIdArray: any[]) => void
}

type IOrderHeaderBottomState = {
  selectedExchange: string
}

class OrderHeaderBottom extends Component<
  IOrderHeaderBottomProps,
  IOrderHeaderBottomState
> {
  constructor(props: IOrderHeaderBottomProps) {
    super(props)
    const { selectedDefaultExchange } = this.props
    this.state = {
      selectedExchange: selectedDefaultExchange
    }
  }

  componentDidMount(): void {}

  componentDidUpdate(prevProps: Readonly<IOrderHeaderBottomProps>): void {
    const {
      commonIsinCodeScriptArray,
      orderScriptId,
      selectedDefaultExchange,
      subscribeLtpData
    } = this.props
    const {
      commonIsinCodeScriptArray: PREV_COMMON_ISIN_SCRIPT_ID_ARRAY,
      orderScriptId: PREV_ORDERSCRIPTID
    } = prevProps

    if (commonIsinCodeScriptArray !== PREV_COMMON_ISIN_SCRIPT_ID_ARRAY) {
      const scriptArrayToSubscribe = []
      for (let i = 0; i < commonIsinCodeScriptArray.length; i++) {
        const scriptId = commonIsinCodeScriptArray[i]
        if (scriptId.toString() !== orderScriptId) {
          scriptArrayToSubscribe.push({ scriptId })
        }
      }
      subscribeLtpData(scriptArrayToSubscribe)
    }

    if (PREV_ORDERSCRIPTID !== orderScriptId) {
      this.setState({
        selectedExchange: selectedDefaultExchange
      })
    }
  }

  handleExchangeSelection = (exchange: string, scriptId: string) => {
    const { actions } = this.props
    this.setState({ selectedExchange: exchange })
    actions.updateDataOnExchangeChange({
      orderScriptId: scriptId,
      selectedExchange: exchange
    })
  }

  render() {
    const {
      commonIsinCodeScriptArray,
      isModifyOrder,
      orderScriptId,
      stockDetails = {}
    } = this.props
    const { selectedExchange } = this.state
    const {
      closeprice = 0,
      exchange = '',
      segment
    } = (stockDetails as T_SCRIPTDATA) || {}

    return (
      <>
        <DsRadioGroup value={selectedExchange}>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            {(!isModifyOrder &&
              commonIsinCodeScriptArray.length > 1 &&
              commonIsinCodeScriptArray?.map((scriptId: number | string) => {
                return (
                  <ExchangeSelection
                    scriptId={scriptId}
                    handleExchangeSelection={this.handleExchangeSelection}
                    key={scriptId}
                  />
                )
              })) || (
              <>
                <DsStack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 'var(--ds-spacing-glacial)'
                  }}
                >
                  <DsTypography>{exchange}</DsTypography>
                  <StockTicker
                    scriptId={orderScriptId}
                    closeprice={closeprice}
                    component='orders'
                    ltpTypoVariant='bodyBoldSmall'
                    segment={segment}
                  />
                </DsStack>
              </>
            )}
          </DsStack>
        </DsRadioGroup>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderScriptId = getOrderScriptId(state)
  const commonIsinCodeScriptArray = getCommonIsinCodeScriptIdArray(state)
  const selectedDefaultExchange = getSelectedExchange(state)
  const isModifyOrder = isModifyOrderRequest(state)
  const stockDetails = getStockMasterData(orderScriptId)(state)

  return {
    orderScriptId,
    commonIsinCodeScriptArray,
    selectedDefaultExchange,
    isModifyOrder,
    stockDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateDataOnExchangeChange: (requestData: any) =>
      dispatch(updateDataOnExchangeChange(requestData))
  }
})

export default withStockSubUnSub(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(OrderHeaderBottom)
)
