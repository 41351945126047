import {
  DsBox,
  DsRemixIcon,
  DsStack,
  DsTypography,
  DsButton,
  DsImage,
  DsChip,
  DsButtonBase
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { TAppDispatch } from '~/src/Configurations/AppStore'
import { INITIATE_REDIRECTION_LINK } from '~/src/Configurations/env'
import { BULB, DDPI } from '~/src/Constants/ASSET_MAP'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { ssoInitiateServiceName } from '~/src/Redux/SSO/Actions'
import { IAuthenticationResponse } from '~/src/Redux/SSO/Reducer'
import { getSSOAuthenticateData } from '~/src/Redux/SSO/Selectors'
import ssoInitiateAction, {
  ssoInitiatePayload
} from '~/src/Redux/SSO/Services/loginInitiate.Service'
import { getServiceSelector } from '~/src/Redux/ServiceTracker/Selectors'

interface ActionType {
  SSOInitiate: (data: ssoInitiatePayload) => Promise<any>
}
export interface ILinkDDPIWrapperProps {
  onClose: (data: boolean) => void
  openServiceModal: (data: boolean) => void
  handleError: (response: any) => void
  actions: ActionType
  authenticateSSOSelector: IAuthenticationResponse
}

class LinkDDPIWrapper extends Component<ILinkDDPIWrapperProps> {
  handleInitiateDDPIRedirection = async () => {
    const {
      onClose,
      openServiceModal,
      handleError,
      actions,
      authenticateSSOSelector
    } = this.props

    openServiceModal(false)
    onClose(false)

    const { refreshToken } = authenticateSSOSelector
    const { token } = refreshToken

    const requestPayload = {
      type: 'ADD_DDPI',
      refreshToken: token,
      redirectURL: INITIATE_REDIRECTION_LINK
    }

    const response = await actions.SSOInitiate(requestPayload)

    if (response._isCustomError) {
      console.log('failed API - handleInitiateDDPIRedirection')
      return handleError(response)
    }

    const { redirectURL } = response
    await window.open(redirectURL, '_self')
  }

  render() {
    const { onClose } = this.props
    return (
      <DsBox
        sx={{
          height: '100%'
        }}
      >
        <DsStack
          spacing='var(--ds-spacing-bitterCold)'
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            height: '100%'
          }}
        >
          <DsBox
            sx={{
              gap: '1rem',
              alignItems: 'center',
              flexGrow: '0 !important',
              justifyContent: { md: 'center', xs: 'flex-start' },
              width: '100%'
            }}
          >
            <DsRemixIcon
              onClick={() => onClose(false)}
              className='ri-close-fill'
              fontSize='mild'
              sx={{ display: { xs: 'flex', md: 'none' } }}
            />
            <DsTypography variant='headingBoldLarge'>
              Link DDPI to pledge holdings
            </DsTypography>
          </DsBox>

          <DsStack
            spacing='var(--ds-spacing-bitterCold)'
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: 'center',
              height: '100%'
            }}
          >
            <DsStack
              sx={{
                display: { xs: 'flex', md: 'none' },
                alignItems: 'flex-start',
                gap: '12px',
                flexGrow: '0 !important'
              }}
            >
              <DsBox
                sx={{
                  width: '100%',
                  display: { xs: 'flex', md: 'none' }
                }}
              >
                <DsImage srcSet={DDPI} />
              </DsBox>
              <DsTypography variant='headingBoldExtraSmall'>
                Why is DDPI required for pledging?
              </DsTypography>
              <DsTypography
                variant='bodyRegularMedium'
                sx={{ textAlign: 'start' }}
              >
                DDPI (Demat debit and pledge instruction) linking provides an
                extra layer of authentication to ensure that only genuine
                account holders can pledge their holdings. This reduces the risk
                of unauthorised pledging and enhances overall security.
              </DsTypography>
            </DsStack>

            <DsTypography
              variant='bodyRegularMedium'
              sx={{ display: { xs: 'none', md: 'contents' } }}
            >
              As part of the process, the shares bought under BMPL need to be
              mandatorily pledged. Please follow below simple steps to complete
              the further process-
            </DsTypography>

            <DsBox
              sx={{
                borderRadius: '8px',
                border:
                  '1px solid var(--light-stroke-default-sz-colour-stroke-default, #E2E2E2)',
                background: 'var(--ds-colour-surfaceBackground)',
                display: 'flex',
                padding: 'var(--ds-spacing-bitterCold)',
                gap: 'var(--ds-spacing-bitterCold)',
                width: '100%'
              }}
            >
              <DsBox>
                <DsImage srcSet={BULB} />
              </DsBox>
              <DsTypography
                variant='bodyRegularSmall'
                sx={{ marginTop: '4px' }}
              >
                It’s a one time process, won’t take much time
              </DsTypography>
            </DsBox>

            <DsStack
              spacing='11px'
              sx={{ alignItems: 'flex-start', flex: 'initial !important' }}
            >
              <DsChip
                sx={{
                  width: ' fit-content ',
                  height: '19px',
                  color: 'var(--ds-colour-supportTypical)',
                  background: 'var(--ds-colour-supportTypicalNeutral)',
                  textTransform: 'lowercase'
                }}
                label={'What is pledge?'}
              />
              <DsTypography
                variant='bodyRegularMedium'
                sx={{ textAlign: 'start' }}
              >
                Pledge is a new process of creating trading limit against equity
                shares held in your demat account by pledging the approved
                securities.
              </DsTypography>
            </DsStack>

            <DsBox
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                mt: 'var(--ds-spacing-cool)',
                border: '1px solid var(--ds-colour-strokeDefault)',
                backgrounColor: 'var(--ds-colour-surfacePrimary)',
                borderRadius: 'var(--ds-radius-glacial)',
                padding: 'var(--ds-spacing-frostbite)',
                alignItems: { xs: 'flex-start', md: 'center' },
                width: '100%',
                gap: 'var(--ds-spacing-bitterCold)',
                flexGrow: '0 !important'
              }}
            >
              <DsTypography
                variant='bodyBoldMedium'
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                Benefits of Pledge
              </DsTypography>
              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'column' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 'var(--ds-spacing-frostbite)'
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'var(--ds-spacing-warm)',
                    height: 'var(--ds-spacing-warm)',
                    background: 'var(--ds-colour-neutral1)',
                    borderRadius: 'var(--ds-radius-quickFreeze)'
                  }}
                >
                  <DsRemixIcon className='ri-link' fontSize='cold' />
                </DsBox>
                <DsBox
                  sx={{
                    display: 'flex',
                    flexDirection: ' column',
                    alignItems: { xs: 'flex-start', md: 'center' }
                  }}
                >
                  <DsTypography variant='bodyBoldMedium'>
                    Get Margin Instantly
                  </DsTypography>
                  <DsTypography
                    variant='supportRegularFootnote'
                    sx={{ textAlign: { xs: 'start', md: 'center' } }}
                  >
                    Pledge allows you to access funds instantly without selling
                    them
                  </DsTypography>
                </DsBox>
              </DsBox>

              <DsBox
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'column' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 'var(--ds-spacing-frostbite)',
                  flexGrow: 'initial'
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'var(--ds-spacing-warm)',
                    height: 'var(--ds-spacing-warm)',
                    background: 'var(--ds-colour-neutral1)',
                    borderRadius: 'var(--ds-radius-quickFreeze)'
                  }}
                >
                  <DsRemixIcon
                    className='ri-lock-password-line'
                    fontSize='cold'
                  />
                </DsBox>
                <DsBox
                  sx={{
                    display: 'flex',
                    flexDirection: ' column',
                    alignItems: { xs: 'flex-start', md: 'center' }
                  }}
                >
                  <DsTypography
                    variant='bodyBoldMedium'
                    sx={{ textAlign: 'center' }}
                  >
                    Securities as Margin Pledge
                  </DsTypography>
                  <DsTypography
                    variant='supportRegularFootnote'
                    sx={{ textAlign: { xs: 'start', md: 'center' } }}
                  >
                    The securities will remain in your demat account but will be
                    marked as margin pledge
                  </DsTypography>
                </DsBox>
              </DsBox>
            </DsBox>
          </DsStack>

          <DsButtonBase
            sx={{
              display: 'flex',
              gap: '8px',
              width: '100%'
            }}
          >
            <DsButton
              onClick={() => onClose(false)}
              color='secondary'
              size='medium'
              fullWidth={true}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              Cancel
            </DsButton>
            <DsButton
              fullWidth={true}
              size='medium'
              color='primary'
              onClick={this.handleInitiateDDPIRedirection}
            >
              Link DDPI
            </DsButton>
          </DsButtonBase>
        </DsStack>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isLoadingInitiateSSO =
    getServiceSelector(state, ssoInitiateServiceName) === 'LOADING'
  const authenticateSSOSelector = getSSOAuthenticateData(state)

  return {
    authenticateSSOSelector
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    SSOInitiate: (data: ssoInitiatePayload) => dispatch(ssoInitiateAction(data))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(LinkDDPIWrapper)
